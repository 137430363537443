// import React from 'react'
// import { Link } from "react-router-dom";
// import Navbar from "../../components/Navbar/Navbar";
// const EmployeeLogin = () => { 
//   return (
//     <> 
//     <div className='bg-white'>
//     <Navbar />
//     </div>
//    <div>
//    <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-center bg-gray-50 ">
//        <div className='mt-8'>
//            <a href="/">
//                <h3 className="text-4xl font-bold text-purple-600">
//                    Employee Sign In
//                </h3>
//            </a>
//        </div>
//        <div className="w-full px-6 py-4 mt-2 overflow-hidden bg-white   sm:max-w-lg sm:rounded-lg">
//            <form>
            
//                <div className="mt-4">
//                    <label
//                        htmlFor="email"
//                        className="block text-sm font-medium text-gray-700 undefined"
//                    >
//                        Email
//                    </label>
//                    <div className="flex flex-col items-start">
//                        <input
//                            type="email"
//                            name="email"
//                            className="block h-12 w-full p-2 mt-1 border border-gray-300 rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                        />
//                    </div>
//                </div>
//                <div className="mt-4">
//                    <label
//                        htmlFor="password"
//                        className="block text-sm font-medium text-gray-700 undefined"
//                    >
//                        Password
//                    </label>
//                    <div className="flex flex-col items-start">
//                        <input
//                            type="password"
//                            name="password"
//                            className="block h-12 p-2 w-full mt-1 border border-gray-300 rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                        />
//                    </div>
//                </div>
//                {/* <div className="mt-4">
//                    <label
//                        htmlFor="password_confirmation"
//                        className="block h-12 text-sm font-medium text-gray-700 undefined"
//                    >
//                        Confirm Password
//                    </label>
//                    <div className="flex flex-col items-start">
//                        <input
//                            type="password"
//                            name="password_confirmation"
//                            className="block h-12 w-full p-2 mt-1 border border-gray-300 rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                        />
//                    </div>
//                </div> */}
//                <a
//                    href="#"
//                    className="text-xs text-purple-600 hover:underline"
//                >
//                    Forget Password?
//                </a>
//                <div className="flex items-center mt-4">
//                    <button className="w-full h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
//                       Log In
//                    </button>
//                </div>
//            </form>
//            <div className="mt-4 text-grey-600">
//                Already have an account?
//                <span>
//                 <Link
//                   to="/employee-signup"
//                   className="text-purple-600 hover:underline"
//                 >
//                   <b> Sign Up</b>
//                 </Link>
//               </span>
//            </div>
//        </div>
//    </div>
// </div>

// </>

//   )
// }

// export default EmployeeLogin
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { adminLogin, customerLogin, getAllModule } from "../../services/admin/Index";
import { NotificationManager } from "react-notifications";
import { auth } from "../../firebase/firebase";
import history from "../../history";

const EmployeeLogin = () => {
  window.onbeforeunload = function () {
    localStorage.clear();
  };
  const [state, setState] = useState({
    email: localStorage.getItem("email") || "",
    password: "",
    user_type: "employ",
  });

  console.log("state", state);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const handleInvalidSubmit = (event, errors, values) => {
    setEmail(values.email);
    setError(true);
  };

  const handleValidSubmit = async (event) => {
    event.preventDefault();
    console.log("state", state);
    console.log("event", event.target);

    localStorage.removeItem("userData");
    localStorage.removeItem("dashboardModules");
    try {
      const requestResult = await customerLogin(state);
      localStorage.setItem("userData", JSON.stringify(requestResult));
      const token = requestResult.token;
      const dashboard = await getAllModule(token);
      console.log("dashboard", dashboard);
      console.log("token", token);
      localStorage.setItem("dashboardModules", JSON.stringify(dashboard));
      // await auth.signInWithEmailAndPassword(state.email, state.password);
      (requestResult.data &&
        requestResult.data.user_type === "employ" &&
        history.push("/employ")) ||
        (requestResult.message &&
          /**alert(requestResult.message)**/ NotificationManager.error(
            requestResult.message
          ));
    } catch (error) {
      NotificationManager.error("Invalid login credentials");
    }
  };
  const defaultValue = localStorage.getItem("email") || "";
  console.log("defaultValue", defaultValue);

  return (
    <>
      <div className="bg-white">
        <Navbar />
      </div>
      <div>
        <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-center bg-gray-50 ">
          <div className="mt-8">
            <a href="/">
              <h3 className="text-4xl font-bold text-purple-600">
                Employee Sign In
              </h3>
            </a>
          </div>
          <div className="w-full px-6 py-4 mt-2 overflow-hidden bg-white   sm:max-w-lg sm:rounded-lg">
            <form
              onSubmit={handleValidSubmit}
              onInvalidSubmit={handleInvalidSubmit}
            >
              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 undefined"
                >
                  Email
                </label>
                <div className="flex flex-col items-start">
                  <input
                    type="email"
                    name="email"
                    required
                    value={state.email}
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    className="block h-12 w-full p-2 mt-1 border border-gray-300 rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 undefined"
                >
                  Password
                </label>
                <div className="flex flex-col items-start">
                  <input
                    type="password"
                    name="password"
                    required
                    value={state.password}
                    onChange={handleChange}
                    className="block h-12 p-2 w-full mt-1 border border-gray-300 rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <a href="#" className="text-xs text-purple-600 hover:underline">
                Forget Password?
              </a>
              <div className="flex items-center mt-4">
                <button
                  type="submit"
                  className="w-full h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                >
                  Log In
                </button>
              </div>
            </form>
            <div className="mt-4 text-grey-600">
              Already have an account?
              <span>
                <Link
                  to="/user-signup"
                  className="text-purple-600 hover:underline"
                >
                  <b> Sign Up</b>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeLogin;
