import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from 'reactstrap';
import { getAllJobsByAdmin } from '../../services/admin/Index';
import _ from "lodash";
import moment from "moment";
import scroll from './scroll.css'
import ReactTooltip from "react-tooltip";
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Button, Spinner,Pagination,PaginationItem,PaginationLink } from 'reactstrap';



class AllJobs extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      allUserJobs: '',
      pagesCount: 0,
      currentPage: 0,
      tooltipOpen: false,
      isOpen: false,
      showProgress: true,
      token: '',
      hidden: false,
    };
    this.showModel = this.showModel.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    const allUserJobs = await getAllJobsByAdmin(this.state.currentPage,token);
    if(allUserJobs.data.length!=0){
      this.setState({ allUserJobs: allUserJobs,
        pagesCount: Math.ceil(allUserJobs.total/allUserJobs.data.length),
        showProgress: false,
        token : token, hidden: true 
      })
    }else{
      this.setState({ allUserJobs: allUserJobs,
        showProgress: false,
        token : token, hidden: true 
      })
    }
  }

  async handleClick(e, index) {
    e.preventDefault();
    const allUserJobs = await getAllJobsByAdmin(index+1,this.state.token);
    this.setState({
        currentPage : index,
        allUserJobs : allUserJobs
      })
    
  }

  showModel(user) {
    this.setState({ isOpen: true, user });

  }
  closeModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleClick(user) {
    console.log("clicekd", user)
  }

  renderRows(allUserJobs) {

    return allUserJobs && this.state.allUserJobs.data.map((user, key) => {
      {
        return <tr key={key}>
          <td scope="row">{user.id}  </td>
          <td scope="row">{user.user_name}  </td>
          <td scope="row">{user.address}  </td>
          <td scope="row">{user.person}  </td>
          <td scope="row">{user.status}  </td>
          {/* <td scope="row">{user.bid_id}  </td> */}
          {/* <td scope="row">{user.start_time}  </td>
          <td scope="row">{user.end_time}  </td> */}
          <td scope="row">{moment(user.created_at).format("MMM Do YY")}  </td>
          <td><span>
            {/* <span data-tip="Approve Request" onClick={this.handleClick.bind(this, user)}> <i className="fa fa-check-square mr-2 text-success" color="secondary" id={'approve-' + key} /></span>
            <ReactTooltip />
            <span data-tip="Reject Request"> <i className="fa fa-times-circle mr-2 text-danger" color="secondary" id={'del-' + key} /></span>
            <ReactTooltip />
            <span data-tip="Block Request"> <i className="fa fa-trash mr-2 text-warning" color="secondary" id={'block-' + key} /></span>
            <ReactTooltip /> */}
            <div className="col-md-6 col-md-offset-6">
            <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showModel.bind(this, user)}> <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
            <ReactTooltip />
          </span></td>
        </tr>
      }

    })
  }

  render() {
    const { allUserJobs, isOpen, user, hidden } = this.state;

    return (
      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card style={{"min-width":"270px"}}>
            <CardHeader color="primary">
              <Row className="ml-2">
              <h4>Jobs</h4></Row>
              <Row className="ml-2">
              <p>
                All Jobs record
                </p></Row>
            </CardHeader>

            {!hidden &&
              <CardBody>
                <div style={{
                  position: 'absolute', left: '50%', top: '10%',
                }} xs={12}></div>
              </CardBody>
            }

            {hidden && <CardBody>
              <Table bordered responsive class={scroll}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Person</th>
                    <th>Job Status</th>
                    {/* <th>BID ID</th> */}
                    {/* <th>Start Time</th>
                    <th>End Time</th> */}
                    <th>Created At</th>
                    <th>Details</th>

                  </tr>
                </thead>
                <tbody>

                  {
                    allUserJobs && this.renderRows(allUserJobs)
                  }
                  {allUserJobs && _.size(allUserJobs.data) === 0 && <td scope="row">{"No Records Found"}  </td>

                  }

                </tbody>
              </Table>
              <nav>
                <Pagination className="pull-right mt-3" size="md">
                
                <PaginationItem disabled={this.state.currentPage <= 0}>
                  
                  <PaginationLink
                    onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                    previous
                    href="#"/>
                  
                </PaginationItem>

                {[...Array(this.state.pagesCount)].map((page, i) => 
                  <PaginationItem active={i === this.state.currentPage} key={i}>
                    <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>
                  
                  <PaginationLink
                    onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                    next
                    href="#"
                    />
                  
                </PaginationItem>
                
              </Pagination>
            </nav>

              <Modal isOpen={isOpen} toggle={this.closeModal}>
                <ModalHeader >Job Details</ModalHeader>

                <ModalBody>
                  <Col lg='12' md='12' sm='12' xs='12'>
                    <div className='d-flex bg-light mb-2'>
                      <Col lg='12' md='8' sm='12' xs='12'>
                        <span><strong className="text-dark">Name :</strong> {user && user.user_name}</span>
                      </Col>
                    </div>
                    <div className='d-flex bg-light mb-2'>
                      <Col lg='12' md='8' sm='12' xs='12'>
                        <span><strong className="text-dark">Description :</strong> {user && user.job_desc}</span>
                      </Col>
                    </div>
                    <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> Start Time :</strong>{user && user.start_time}</span>
                                            </Col>
                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> End Time :</strong>{user && user.end_time}</span>
                                            </Col>
                                        </div>
                  </Col>


                </ModalBody>

                <ModalFooter>
                  <Button color="secondary" onClick={this.closeModal}>Close</Button>
                </ModalFooter>
              </Modal>


            </CardBody>
            }
          </Card>
        </GridItem>

      </GridContainer>
    );
  }

}
export default AllJobs;