import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { getSettings, updateSettings } from '../../services/admin/Index';
import CardBody from "components/Card/CardBody.js";
import { Table, Row, Col, CustomInput, Button,CardImg,CardText,Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import history from '../../history';
import ImageUploader from 'react-images-upload';
import _ from 'lodash';
import profile from "../../assets/img/profile.png";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';

class Setting extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      //settingsData: '',
      token: '',
      site_name : '',
      site_logo_pic : '',
      site_logo : '',
      //website_icon : '',
      //website_icon_pic : '',
      email : '',
      copy_right : '',
      playstoreLink : '',
      appstoreLink : '',
      number : '',
      hidden:false
    };
    this.onChange = this.onChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDropIcon = this.onDropIcon.bind(this);
    this.editSettings = this.editSettings.bind(this);
    this.cancel = this.cancel.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
  }

  async componentWillMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    const allSettings = await getSettings(token);
    const setting = allSettings.data[0]
    console.log(setting)
    await this.setState({
      //settingsData: allSettings.data[0],
      site_name : setting.site_name,
      site_logo : setting.site_logo,
      //website_icon : setting.website_icon,
      email : setting.email,
      copy_right : setting.copy_right,
      playstoreLink : setting.playstore_link,
      appstoreLink : setting.appstore_link,
      number : setting.number,
      token: token,
      hidden:false
    })
  }

  onDrop(picture) {
    this.setState({
      site_logo_pic: picture[0],
    });
  }

  onDropIcon(picture) {
    this.setState({
      website_icon_pic: picture,
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  editSettings() {
    this.setState({
      count: 1,
    });
  }

  cancel() {
    this.setState({
      count: 0,
    });
  }

  async updateSettings() {
    try {
      this.setState({ hidden:true })
      let site_logo = ''
      if (this.state.site_logo_pic != ''){
        site_logo = this.state.site_logo_pic
      }else{
        site_logo = this.state.site_logo
      }
      // const body = {
      //   site_name : this.state.siteName,
      //   site_logo : site_logo,
      //   //website_icon : this.state.website_icon_pic,
      //   email : this.state.email,
      //   copy_right : this.state.copy_right,
      //   playstore_link : this.state.playstoreLink,
      //   appstore_link : this.state.appstoreLink,
      //   number : this.state.number,
      //   token: this.state.token
      // }
      // console.log(body)
      const data = new FormData();
      data.append('site_name', this.state.site_name);
      data.append('site_logo', site_logo);
      // data.append('email', this.state.email);
      data.append('copy_right', this.state.copy_right);
      // data.append('playstore_link', this.state.playstoreLink);
      // data.append('appstore_link', this.state.appstoreLink);
      // data.append('number', this.state.number);
      let accessToken = this.state.token;
      axios
        .post('http://localhost:4500/setting/updateSettings', data,
        { headers:
        { 'Content-Type': 'multipart/form-data', Authorization: accessToken }
        })
        .then(res => {
          console.log('res', res);
          if (res.status == 200) {
            NotificationManager.success('Updated Succesfuly');
            this.setState({ count: 0,hidden:true, site_logo_pic:'' })
            this.componentWillMount()
          } else {
            NotificationManager.error('Something went wrong');
            this.setState({ count: 0,hidden:true, site_logo_pic:'' })
            this.componentWillMount()
          }
      });
      // const response = await updateSettings(body);
      // console.log(response)
      // if (response.status == 200) {
      //   NotificationManager.success('Updated Succesfuly');
      //   this.setState({ count: 0,hidden:true, site_logo_pic:'' })
      //   this.componentWillMount()
      // } else {
      //   NotificationManager.error('Something went wrong');
      //   this.setState({ count: 0,hidden:true, site_logo_pic:'' })
      //   this.componentWillMount()
      // }
    } catch (e) {
      throw (e);
    }
  }

  render() {
    const { site_logo } = this.state;
    var site_LOGO = "";
    if (site_logo!=="" && site_logo!==null){
      site_LOGO = "http://localhost:4500/uploads/"+site_logo
    }
    return (
      <GridContainer >
        <NotificationContainer />
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{"min-width":"270px"}}>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4 >Website Settings</h4>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="mt-2" style={{width:"1%"}}>
                  <Row className="ml-2">
                    <div>
                      <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Site Name:</h5></strong>
                    </div>
                  </Row>
                </Col>
                <Col className="mt-2" xs={8}>
                  {this.state.count==0 &&
                    <CardText >{this.state.site_name}</CardText>
                  }
                  {this.state.count==1 &&
                    <AvForm>
                      <AvField name="site_name" placeholder="Website Name" type="input" value={this.state.site_name} onChange={this.onChange} className='form-input' />
                    </AvForm>
                  }
                </Col>
              </Row>
              <Row >
                <Col className="mt-2" style={{width:"1%"}}>
                  <Row className="ml-2">
                    <div>
                      <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1">Website Logo:</h5></strong>
                    </div>
                  </Row>
                </Col>
                <Col className="mt-2" xs={8}>
                  {this.state.count==0 &&
                    <div className="card-profile-image m-2 ml-4">
                      <a>
                        <img
                          src={site_LOGO!=="" ? site_LOGO : profile}
                          style={{ borderRadius: 60, "min-width": "100px", "min-height":"100px", "max-width": "100px", "max-height":"100px"}}
                        />
                      </a>
                    </div>
                  }
                  {this.state.count==1 && 
                    <ImageUploader
                      withIcon={true}
                      buttonText='Choose images'
                      onChange={this.onDrop}
                      imgExtension={['.jpg', '.gif', '.png', '.gif']}
                      maxFileSize={5242880}
                      withPreview={true}
                      singleImage={true}
                    />
                  }
                </Col>
              </Row>
              {/* <Row >
                <Col className="mt-2" xs={3}>
                  <div>
                    <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1">Website Icon:</h5></strong>
                  </div>
                </Col>
                <Col className="mt-2" xs={6}>
                    {this.state.count==0 && 
                      <div className="card-profile-image m-2 ml-4">
                      <a>
                        <img
                          alt={profile}
                          className="rounded-circle"
                          src={"http://localhost:4500/uploads/"+this.state.website_icon}
                          style={{width: 200, height: 200}}
                        />
                      </a>
                      </div>
                    }
                    {this.state.count==1 && 
                      <ImageUploader
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={this.onDropIcon}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                        withPreview={true}
                        singleImage={true}
                      />
                    }
                </Col>
              </Row> */}
              <Row >
                <Col className="mt-2" style={{width:"1%"}}>
                  <Row className="ml-2">
                    <div>
                      <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Copyright Content:</h5></strong>
                    </div>
                  </Row>
                </Col>
                <Col className="mt-2" xs={8}>
                {this.state.count==0 &&
                    <CardText className="ml-2" className=" mt-1">{this.state.copy_right}</CardText>
                  }
                  {this.state.count==1 &&
                    <AvForm>
                      <AvField name="copyright" placeholder="Content" value={this.state.copy_right} type="input" className='form-input' />
                    </AvForm>
                  }
                  
                </Col>
              </Row>
              <Row >
                <Col className="mt-2" style={{width:"1%"}}>
                  <Row className="ml-2">
                    <div>
                      <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Playstore Link:</h5></strong>
                    </div>
                  </Row>
                </Col>
                <Col className="mt-2" xs={8}>
                  {this.state.count==0 &&
                    <CardText >{this.state.playstoreLink}</CardText>
                  }
                  {this.state.count==1 &&
                    <AvForm>
                      <AvField name="playstoreLink" placeholder="Playstore link" value={this.state.playstoreLink} type="input" onChange={this.onChange} className='form-input' />
                    </AvForm>
                  }
                  
                </Col>
              </Row>

              <Row >
                <Col className="mt-2" style={{width:"1%"}}>
                  <Row className="ml-2">
                    <div>
                      <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Appstore Link:</h5></strong>
                    </div>
                  </Row>
                </Col>
                <Col className="mt-2" xs={8}>
                  {this.state.count==0 &&
                    <CardText >{this.state.appstoreLink}</CardText>
                  }
                  {this.state.count==1 &&
                    <AvForm>
                      <AvField name="appstoreLink" placeholder="Appstore link" value={this.state.appstoreLink} type="input" onChange={this.onChange} className='form-input' />
                    </AvForm>
                  }
                  
                </Col>
              </Row>

              <Row >
                <Col className="mt-2" style={{width:"1%"}}>
                  <Row className="ml-2">
                    <div>
                      <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Contact No :</h5></strong>
                    </div>
                  </Row>
                </Col>
                <Col className="mt-2" xs={8}>
                  {this.state.count==0 &&
                    <CardText >{this.state.number}</CardText>
                  }
                  {this.state.count==1 &&
                    <AvForm>
                      <AvField name="number" placeholder="Contact number" value={this.state.number} type="number" onChange={this.onChange} className='form-input' />
                    </AvForm>
                  }
                  
                </Col>
              </Row>

              <Row >
                <Col className="mt-2" style={{width:"1%"}}>
                  <Row className="ml-2">
                    <div>
                      <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Contact Email :</h5></strong>
                    </div>
                  </Row>
                </Col>
                <Col className="mt-2" xs={8}>
                  {this.state.count==0 &&
                    <CardText >{this.state.email}</CardText>
                  }
                  {this.state.count==1 &&
                    <AvForm>
                      <AvField name="email" placeholder="Contact email" value={this.state.email} type="email" onChange={this.onChange} className='form-input' />
                    </AvForm>
                  }
                  
                </Col>
              </Row>
              {this.state.count==0 &&
                <div class="container">
                  <div class="row">
                    <div class="col text-center">
                      <Button className="fa fa-pencil-square-o mr-2" color="primary" onClick={this.editSettings}> Edit Settings</Button>
                    </div>
                  </div>
                </div>
              }
              {this.state.count==1 &&
                <div class="container">
                  <div class="row">
                    <div class="col text-center">
                      <div className="mt-3">
                        <a className="btn btn-light mr-1" onClick={this.cancel}>
                            <i className="fa fa-times mr-1 text-info"></i> Cancel</a>
                        <a className="btn btn-light mr-1" onClick={this.updateSettings}>
                            <i className="fa fa-pencil-square-o mr-1 text-info"></i>Update Setting{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                        {/* {<Button className="fa fa-times mr-2" color="secondary" onClick={this.cancel}>Cancel</Button>
                        <Button className="fa fa-pencil-square-o mr-2" color="danger" onClick={this.cancel}>Update  Setting</Button>} */}
                      </div>
                    </div>
                  </div>
                </div>
                
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

}
export default Setting;