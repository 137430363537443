import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Faqs from "../components/faqs/SingleCol";
import Footer from "../components/footers/FiveColumnWithBackground.js";

const Faq = () => {
  return (
    <>
      <div className="bg-white">
        <Navbar/>
      </div>
      <Faqs />
      <Footer />
    </>
  );
};

export default Faq;
