import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { updateEmploy } from "../../services/admin/Index";

import CardHeader from "components/Card/CardHeader.js";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Table,
  Col,
  Row,
} from "reactstrap";

import history from "../../history";

const fadeInAnimation = keyframes`${fadeIn}`;

const AnimatedCard = styled(Card)`
  animation: 1s ${fadeInAnimation};
`;
class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.addQualification = this.addQualification.bind(this);
    this.addExperience = this.addExperience.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.addNewQualification = this.addNewQualification.bind(this);
    this.addNewExperience = this.addNewExperience.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.updateEmployeeProfile = this.updateEmployeeProfile.bind(this);
    this.state = {
      count: 0,
      email: "",
      password: "",
      img_url: "",
      first_name: "",
      last_name: "",
      address: "",
      city: "",
      phone: "",
      device_type: "",
      user_name: "",
      qualification: [],
      experience: [],
      qualificationTitle: "",
      qualificationInstitute: "",
      qualificationLocation: "",
      qualificationSubject: "",
      qualificationCompletionYear: "",
    };
    console.log("this.state", this.state);
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event, values) => {
    event.preventDefault();
    console.log(this.state);
    // Add logic here to save the data to the database
  };

  componentWillMount() {
    const user = localStorage.getItem("userData");
  }

  goBack() {
    this.setState({
      count: 0,
    });
  }

  addQualification() {
    this.setState({
      count: 1,
    });
  }

  addExperience() {
    this.setState({
      count: 2,
    });
  }

  editProfile() {
    this.setState({
      count: 3,
    });
  }

  addNewQualification(event, model) {
    const body = {
      title: model.qualificationTitle,
      institute: model.qualificationInstitute,
      location: model.qualificationLocation,
      subject: model.qualificationSubject,
      completion_year: model.qualificationCompletionYear,
    };
    this.setState({ qualification: this.state.qualification.concat(body) });
  }

  addNewExperience(event, model) {
    const body = {
      title: model.experienceTitle,
      company: model.experienceCompany,
      industry: model.experienceIndustry,
      salary: model.experienceSalary,
      start_date: model.experienceStartDate,
      end_date: model.experienceEndDate,
      description: model.experienceDescription,
    };
    this.setState({ experience: this.state.experience.concat(body) });
  }

  async updateEmployeeProfile() {
    const body = {
      email: this.state.email,
      password: this.state.password,
      img_url: this.state.img_url,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone,
      device_type: this.state.device_type,
      user_name: this.state.user_name,
      qualification: this.state.qualification,
      experience: this.state.experience,
    };
    const response = await updateEmploy(body);
    console.log("response", response);
    if (response.status >= 400) {
      NotificationManager.error("Something went wrong");
    } else {
      NotificationManager.success("Signup Succesfuly");
      history.push("/login");
    }
  }

  handleValidSubmit(event, values) {
    event.preventDefault();
    this.updateEmployeeProfile();
  }

  handleInvalidSubmit(event, errors, values) {
    event.preventDefault();
  }

  render() {
    if (this.state.count == 1) {
      return (
        <GridContainer className="p-5">
          <NotificationContainer />

          <GridItem xs={12} sm={12} md={3}>
            <Card>
              {/* <CardHeader color="primary">
                <h4 >User Profile</h4>
              </CardHeader> */}
              <CardBody>
                <div>
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle>
                        <strong>
                          {" "}
                          <h4 className="font-weight-bold">Shakeel Gujjar</h4>
                        </strong>{" "}
                      </CardTitle>
                      <CardSubtitle>
                        <strong className="font-weight-bold">Email :</strong>{" "}
                        <strong>shakeel@user.com</strong>
                      </CardSubtitle>
                      <CardText>
                        <strong className="font-weight-bold">About :</strong>
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={9}>
            <Card className="mt-16">
              <CardHeader color="warning">
                <h4>Add Qualification</h4>
              </CardHeader>
              <CardBody>
                <div>
                  <div class="container">
                    <div>
                      <Button onClick={this.goBack}>Go Back</Button>
                    </div>
                    <div class="row justify-center">
                      <div className="col-md-8 m-auto">
                        <AvForm
                          onValidSubmit={this.handleSubmit}
                          className="group"
                        >
                          <AvField
                            name="qualificationTitle"
                            label="Title"
                            type="email"
                            required
                            className="form-input"
                            onChange={this.handleInputChange}
                            value={this.state.qualificationTitle}
                          />
                          <AvField
                            name="qualificationInstitute"
                            label="Institute"
                            type="institute"
                            required
                            className="form-input"
                            onChange={this.handleInputChange}
                            value={this.state.qualificationInstitute}
                          />
                          <AvField
                            name="qualificationLocation"
                            label="Location"
                            type="location"
                            required
                            className="form-input"
                            onChange={this.handleInputChange}
                            value={this.state.qualificationLocation}
                          />
                          <AvField
                            name="qualificationSubject"
                            label="Subject"
                            type="subject"
                            required
                            className="form-input"
                            onChange={this.handleInputChange}
                            value={this.state.qualificationSubject}
                          />
                          <AvField
                            name="qualificationCompletionYear"
                            label="Completion Year"
                            type="year"
                            required
                            className="form-input"
                            onChange={this.handleInputChange}
                            value={this.state.qualificationCompletionYear}
                          />
                          <Button type="submit" color="warning w-100">
                            Add
                          </Button>
                        </AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    } else if (this.state.count == 2) {
      return (
        <GridContainer className="p-5">
          <NotificationContainer />

          <GridItem xs={12} sm={12} md={3}>
            <Card>
              {/* <CardHeader color="primary">
                <h4 >User Profile</h4>
              </CardHeader> */}
              <CardBody>
                <div>
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle>
                        <strong>
                          {" "}
                          <h4 className="font-weight-bold">Shakeel Gujjar</h4>
                        </strong>{" "}
                      </CardTitle>
                      <CardSubtitle>
                        <strong className="font-weight-bold">Email :</strong>{" "}
                        <strong>shakeel@user.com</strong>
                      </CardSubtitle>
                      <CardText>
                        <strong className="font-weight-bold">About :</strong>
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={9}>
            <Card className="mt-16">
              <CardHeader color="danger">
                <h4>Add Experience</h4>
              </CardHeader>
              <CardBody>
                <div>
                  <div class="container">
                    <div>
                      <Button onClick={this.goBack}>Go Back</Button>
                    </div>
                    <div class="row justify-center">
                      <div class="col-md-8 m-auto">
                        <AvForm
                          onValidSubmit={this.handleValidSubmit}
                          onInvalidSubmit={this.handleInvalidSubmit}
                          className="group"
                        >
                          <AvField
                            name="experienceTitle"
                            label="Title"
                            type="email"
                            required
                            className="form-input"
                          />
                          <AvField
                            name="experienceCompany"
                            label="Company"
                            type="company"
                            required
                            className="form-input"
                          />
                          <AvField
                            name="experienceIndustry"
                            label="Industry"
                            type="industry"
                            required
                            className="form-input"
                          />
                          <AvField
                            name="experienceSalary"
                            label="Salary"
                            type="salary"
                            required
                            className="form-input"
                          />
                          <AvField
                            name="experienceStartDate"
                            label="StartDate"
                            type="date"
                            required
                            className="form-input"
                          />
                          <AvField
                            name="experienceEndDate"
                            label="EndDate"
                            type="date"
                            required
                            className="form-input"
                          />
                          <AvField
                            name="experienceDescription"
                            label="Description"
                            type="description"
                            required
                            className="form-input"
                          />

                          <Button type="submit" color="danger w-100">
                            Add{" "}
                          </Button>
                        </AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    } else if (this.state.count == 3) {
      return (
        <GridContainer className="p-5">
          <NotificationContainer />

          <GridItem xs={12} sm={12} md={3}>
            <Card>
              {/* <CardHeader color="primary">
                <h4 >User Profile</h4>
              </CardHeader> */}
              <CardBody>
                <div>
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle>
                        <strong>
                          {" "}
                          <h4 className="font-weight-bold">Shakeel Gujjar</h4>
                        </strong>{" "}
                      </CardTitle>
                      <CardSubtitle>
                        <strong className="font-weight-bold">Email :</strong>{" "}
                        <strong>shakeel@user.com</strong>
                      </CardSubtitle>
                      <CardText>
                        <strong className="font-weight-bold">About :</strong>
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={9}>
            <Card className="mt-16">
              <CardHeader color="success">
                <h4>Edit Profile</h4>
              </CardHeader>
              <CardBody>
                <div>
                  <div class="container">
                    <div>
                      <Button onClick={this.goBack}>Go Back</Button>
                    </div>
                    <div class="row mt-6 justify-center">
                      <div class="col-md-6">
                        <AvForm
                          onValidSubmit={this.handleValidSubmit}
                          onInvalidSubmit={this.handleInvalidSubmit}
                          className="group"
                        >
                          <AvField
                            name="user_name"
                            label="User Name"
                            type="user_name"
                            value={this.state.user_name}
                            required
                            className="form-input"
                          />
                          <AvField
                            name="first_name"
                            label="First Name"
                            type="first_name"
                            value={this.state.first_name}
                            required
                            className="form-input"
                          />
                          <AvField
                            name="last_name"
                            label="Last Name"
                            type="last_name"
                            value={this.state.last_name}
                            required
                            className="form-input"
                          />
                          <AvField
                            name="email"
                            label="Email"
                            type="email"
                            value={this.state.email}
                            required
                            className="form-input"
                          />
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            className="form-input"
                          />
                        </AvForm>
                      </div>
                      <div class="col-md-6">
                        <AvForm
                          onValidSubmit={this.handleValidSubmit}
                          onInvalidSubmit={this.handleInvalidSubmit}
                          className="group"
                        >
                          <AvField
                            name="confirm_password"
                            label="Confirm Password"
                            type="password"
                            required
                            className="form-input"
                          />
                          <AvField
                            name="address"
                            label="Address"
                            type="address"
                            value={this.state.address}
                            required
                            className="form-input"
                          />
                          <AvField
                            name="phone"
                            label="Phone"
                            type="phone"
                            value={this.state.phone}
                            required
                            className="form-input"
                          />
                          <AvField
                            name="city"
                            label="City"
                            type="city"
                            value={this.state.city}
                            required
                            className="form-input"
                          />
                          <AvField
                            type="select"
                            name="device_type"
                            label="Device Type"
                            value={this.state.device_type}
                          >
                            <option>Please Select</option>
                            <option>Android</option>
                            <option>Iphone</option>
                            <option>Web</option>
                          </AvField>
                        </AvForm>
                      </div>
                      <Button type="submit" color="success w-50">
                        Update{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    } else {
      return (
        <GridContainer className="p-5">
          <NotificationContainer />
          <GridItem xs={12} sm={12} md={3}>
            <AnimatedCard>
              <CardImg
                top
                width="100%"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                alt="Card image cap"
              />
              <CardBody>
                <CardTitle>
                  <h4 className="font-weight-bold mb-0">Shakeel Gujjar</h4>
                </CardTitle>
                <CardSubtitle className="font-weight-bold">
                  Email: shakeel@user.com
                </CardSubtitle>
                <CardText className="mt-3">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
              </CardBody>
            </AnimatedCard>
          </GridItem>

          <GridItem xs={12} sm={12} md={9}>
            <Card className="mt-16">
              <CardHeader color="primary">
                <h4>Profile</h4>
              </CardHeader>
              <CardBody>
                <div>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div
                          class="btn-group mb-3 mt-12 d-flex gap-2"
                          role="group"
                        >
                          <div>
                            <Button onClick={this.editProfile}>
                              Edit Profile
                            </Button>
                          </div>
                          <div>
                            <Button onClick={this.addQualification}>
                              Add Qualification
                            </Button>
                          </div>

                          <div>
                            <Button onClick={this.addExperience}>
                              Add Experience
                            </Button>
                          </div>
                        </div>

                        <div>
                          <h4 class="mb-2 mt-12">
                            {" "}
                            <b>Qualification</b>
                          </h4>
                          <table class="table table-bordered table-hover">
                            <thead className="thead-dark shadow">
                              <tr>
                                <th>Institute</th>
                                <th>Title</th>
                                <th>Years</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Comsats</td>
                                <td>BSCS</td>
                                <td>02-02-2016 - 15-01-2020</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div>
                          <h4 class="mb-2">
                            <b>Experience</b>
                          </h4>
                          <table class="table table-bordered table-hover">
                            <thead className="thead-dark shadow">
                              <tr>
                                <th>Company</th>
                                <th>Title</th>
                                <th>Salary</th>
                                <th>Years</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Tech Guy Web Solutions</td>
                                <td>Senior Developer</td>
                                <td>50,000</td>
                                <td>02-03-2009 - 01-02-2014</td>
                              </tr>
                              <tr>
                                <td>Traversy Media</td>
                                <td>Instructor & Developer</td>
                                <td>60,000</td>
                                <td>02-03-2015 - Now</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <div>
                          <h4 class="mb-2">Delete Account</h4>
                          <button class="btn btn-danger">
                            Delete My Account
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }
  }
}
export default Messages;
