import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { getAllComplaints } from '../../services/admin/Index';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table,Col, Row, Button, Spinner, Alert,Pagination,PaginationItem,PaginationLink } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import _ from "lodash";
import moment from "moment";



class Complaints extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      allComplaints: '',
      pagesCount: 0,
      currentPage: 0,
      tooltipOpen: false,
      isOpen: false,
      showProgress: true,
      token: '',
    };
  }

  async handleClick(e, index) {
    e.preventDefault();
    const allComplaints = await getAllComplaints(index+1,this.state.token);
    this.setState({
        currentPage : index,
        allComplaints : allComplaints
      })
    
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    const allComplaints = await getAllComplaints(this.state.currentPage+1,token);
    this.setState({ allComplaints : allComplaints,
      pagesCount: Math.ceil(allComplaints.total/allComplaints.data.length),
      showProgress: false,
      token : token
    })
  }

  renderResultRows(allComplaints) {
    return allComplaints && this.state.allComplaints.data.map((complaint, key) => {
      {
        return <tr key={key} >
          <td scope="row">{complaint.id}  </td>
          <td scope="row">{complaint.user_id}  </td>
          <td scope="row">{complaint.title}  </td>
          <td scope="row">{'0092-1234-234'}  </td>
          <td scope="row">{'dummy@email.com'}  </td>
          <td scope="row">{complaint.status}  </td>
          <td scope="row" >{moment(complaint.created_at).format("MMM Do YY")}  </td>
          {/* <td scope="row">{moment(company.created_at).format("MMM Do YY")}  </td> */}

        </tr>
      }
    })
  }


  render() {
    const { allComplaints, showProgress } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{"min-width":"270px"}}>
            <CardHeader color="primary">
              <Row className="ml-2">
              <h4 >Complaints</h4></Row>
            </CardHeader>
            {showProgress &&
              <CardBody>
                <div style={{
                  position: 'absolute', left: '50%', height: '70%', width: '100%', top: '10%',
                }} xs={12}></div>
              </CardBody>
            }
            <CardBody>
              <div>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Complaint By</th>
                      <th>Title</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      allComplaints && this.renderResultRows(allComplaints)
                    }
                    {allComplaints && _.size(allComplaints.data) === 0 && <td scope="row">{"No Commplaints Found"}  </td>

                    }
                  </tbody>
                </Table>
                <nav>
                <Pagination className="pull-right mt-3" size="md">
                
                <PaginationItem disabled={this.state.currentPage <= 0}>
                  
                  <PaginationLink
                    onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                    previous
                    href="#"/>
                  
                </PaginationItem>

                {[...Array(this.state.pagesCount)].map((page, i) => 
                  <PaginationItem active={i === this.state.currentPage} key={i}>
                    <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>
                  
                  <PaginationLink
                    onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                    next
                    href="#"
                    />
                  
                </PaginationItem>
                
              </Pagination>
            </nav>
              </div>
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    );
  }

}
export default Complaints;