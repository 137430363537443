import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from "reactstrap";
import { getAllUsers } from "../../services/admin/Index";
import _ from "lodash";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Button,
  Spinner,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ReactTooltip from "react-tooltip";
import { CardImg } from "reactstrap";
import { TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import profile from "../../assets/img/profile.png";
import { Grid, Cell } from "react-mdl";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.showModel = this.showModel.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      allUsers: "",
      pagesCount: 0,
      currentPage: 0,
      tooltipOpen: false,
      isOpen: false,
      showProgress: true,
      token: "",
    };
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const token = userInfo.token;
    const allUsers = await getAllUsers(this.state.currentPage + 1, token);
    if (allUsers.data.length != 0) {
      this.setState({
        allUsers: allUsers,
        pagesCount: Math.ceil(allUsers.total / allUsers.data.length),
        showProgress: false,
        token: token,
      });
    } else {
      this.setState({
        allUsers: allUsers,
        showProgress: false,
        token: token,
      });
    }
  }

  async handleClick(e, index) {
    e.preventDefault();
    const allUsers = await getAllUsers(index + 1, this.state.token);
    this.setState({
      currentPage: index,
      allUsers: allUsers,
    });
  }

  showModel(user) {
    this.setState({ isOpen: true, user });
  }
  closeModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  renderResultRows(allUsers) {
    return (
      allUsers &&
      this.state.allUsers.data.map((user, key) => {
        {
          return (
            <tr key={key}>
              <td scope="row">{user.id} </td>
              <td scope="row">{user.first_name + " " + user.last_name} </td>
              <td scope="row">{user.email} </td>
              {/* <td scope="row">{user.gender}  </td> */}
              <td scope="row">{user.phone} </td>
              <td scope="row">{user.address} </td>
              {/* <td scope="row">{user.city}  </td>
          <td scope="row">{user.state}  </td>
          <td scope="row">{user.country}  </td> */}
              {/* <td>
                <span>
                  <div className="col-md-6 col-md-offset-6">
                    <span
                      style={{ cursor: "pointer" }}
                      data-tip="Details"
                      onClick={this.showModel.bind(this, user)}
                    >
                      <i
                        className="fa fa-table mr-2 text-info"
                        color="secondary"
                        id={"details-" + key}
                      />
                    </span>
                  </div>
                  <ReactTooltip />
                </span>
              </td> */}
              {/* <td><span  style={{ cursor: "pointer" }} data-tip="Block Request">
         
            <div className="col-md-6 col-md-offset-6">
            <span style={{ cursor: "pointer" }} data-tip="Block Request"> 
              <i className="fa fa-trash mr-2 text-danger" color="secondary" id={'block-' + key} /></span></div>
            <ReactTooltip />
            
          </span></td> */}
           <td>
                <span  style={{ cursor: "pointer" }}
                      data-tip="Details"
                      onClick={this.showModel.bind(this, user)}>
                  <button
                    type="button"
                    className="btn btn-success fa fa-eye mr-2 text-white"
                    color="secondary "
                    id={"details-" + key}
                  >
                    <ReactTooltip /> Details
                  </button>
                </span>
              </td>

              <td>
                <span style={{ cursor: "pointer" }} data-tip="Block Request">
                  <button
                    type="button"
                    className="btn btn-danger fa fa-trash mr-2 text-white"
                    color="secondary "
                    id={"block-" + key}
                  >
                    <ReactTooltip /> Delete
                  </button>
                </span>
              </td>
            </tr>
          );
        }
      })
    );
  }

  render() {
    const { allUsers, tooltipOpen, isOpen, user, showProgress } = this.state;
    return (
      <Card>
        <CardHeader color="primary">
          <h4>Users</h4>
          <p>All Users records</p>
        </CardHeader>
        <NotificationContainer />
        {showProgress && (
          <CardBody>
            <div
              style={{
                position: "absolute",
                left: "50%",
                height: "70%",
                width: "100%",
                top: "10%",
              }}
              xs={12}
            >
            </div>
          </CardBody>
        )}
        <CardBody>
          <Table
            bordered
            responsive
            className="table table-bordered table-hover"
          >
            <thead className="thead-dark shadow">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                {/* <th>Gender</th> */}
                <th>Phone Number</th>
                <th>Address</th>
                {/* <th>City</th>
                  <th>State</th>
                  <th>Country</th> */}
                <th>Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#f2f2f2" }}>
              {allUsers && this.renderResultRows(allUsers)}
              {allUsers && _.size(allUsers.data) === 0 && (
                <td scope="row">{"No Records Found"} </td>
              )}
            </tbody>
          </Table>

          <nav>
            <Pagination className="pull-right mt-3" size="md">
              <PaginationItem disabled={this.state.currentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handleClick(e, this.state.currentPage - 1)
                  }
                  previous
                  href="#"
                ></PaginationLink>
              </PaginationItem>

              {[...Array(this.state.pagesCount)].map((page, i) => (
                <PaginationItem active={i === this.state.currentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => this.handleClick(e, i)}
                    href="#"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem
                disabled={this.state.currentPage >= this.state.pagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handleClick(e, this.state.currentPage + 1)
                  }
                  next
                  href="#"
                ></PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
          <Modal isOpen={isOpen} toggle={this.closeModal}>
            <ModalHeader>
              <strong>User Detail</strong>
            </ModalHeader>
            <ModalBody>
              <Col lg="12" md="12" sm="12" xs="12">
                <Grid>
                  <Cell>
                    <Row>
                      <Col lg="3" sm="3"></Col>
                      <Col lg="6" sm="3">
                        <img
                          className="rounded-circle"
                          src={
                            user && user.img_url !== "" && user.img_url !== null
                              ? "http://localhost:4500/uploads/" +
                                user.img_url
                              : profile
                          }
                          style={{
                            width: 160,
                            height: 160,
                            "margin-left": "auto",
                            "margin-right": "auto",
                          }}
                        />
                      </Col>
                      <Col lg="3" sm="3"></Col>
                    </Row>
                    <Row>
                      <br></br>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">First Name :</strong>{" "}
                            {user && user.first_name}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Last Name :</strong>{" "}
                            {user && user.last_name}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark text-justify">
                              {" "}
                              Email :
                            </strong>{" "}
                            {user && user.email}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">User Name :</strong>{" "}
                            {user && user.user_name}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Gender :</strong>
                            {user && user.gender}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Device Type :</strong>
                            {user && user.device_type}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark"> City :</strong>{" "}
                            {user && user.city}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Country :</strong>
                            {user && user.country}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Phone :</strong>{" "}
                            {user && user.phone}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Status :</strong>{" "}
                            {user && user.status}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">State :</strong>{" "}
                            {user && user.state}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Zip Code :</strong>{" "}
                            {user && user.zip_code}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Address :</strong>{" "}
                            {user && user.address}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Cell>
                </Grid>
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button color="secondary" onClick={this.closeModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </CardBody>
      </Card>
    );
  }
}

export default Users;
