import React from "react";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from "reactstrap";
import { getAllCompanies, approveCompany } from "../../services/admin/Index";
import _ from "lodash";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Button,
  Spinner,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ReactTooltip from "react-tooltip";
import profile from "../../assets/img/profile.png";
import { Grid, Cell } from "react-mdl";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.showModel = this.showModel.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      allCompanies: "",
      pagesCount: 0,
      currentPage: 0,
      tooltipOpen: false,
      isOpen: false,
      showProgress: true,
      token: "",
    };
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const token = userInfo.token;
    const allCompanies = await getAllCompanies(
      this.state.currentPage + 1,
      token
    );
    if (allCompanies.data.length != 0) {
      this.setState({
        allCompanies: allCompanies,
        pagesCount: Math.ceil(allCompanies.total / allCompanies.data.length),
        showProgress: false,
        token: token,
      });
    } else {
      this.setState({
        allCompanies: allCompanies,
        showProgress: false,
        token: token,
      });
    }
  }

  async handleClick(e, index) {
    e.preventDefault();
    const allCompanies = await getAllCompanies(index + 1, this.state.token);
    this.setState({
      currentPage: index,
      allCompanies: allCompanies,
    });
  }

  showModel(company) {
    this.setState({ isOpen: true, company });
  }
  closeModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async changeStatus(company, status) {
    const { token } = this.state;

    const body = {
      status: status,
      id: company.id,
      type: "company",
      token: token,
    };
    const response = await approveCompany(body);
    if (response.status >= 400) {
      NotificationManager.error("Something went wrong");
    } else {
      this.setState({ showProgress: true });
      NotificationManager.success("Status Changed Succesfully");
      const allCompanies = await getAllCompanies(
        this.state.currentPage + 1,
        token
      );
      this.setState({ allCompanies, showProgress: false, token });
    }
  }

  renderResultRows(allCompanies) {
    return (
      allCompanies &&
      this.state.allCompanies.data.map((company, key) => {
        {
          return (
            <tr key={key}>
              <td scope="row">{company.id} </td>
              <td scope="row">{company.name} </td>
              <td scope="row">{company.email} </td>
              <td scope="row">{company.city} </td>
              {/* <td scope="row">{company.state}  </td> */}
              <td scope="row">{company.country} </td>
              {/* <td scope="row">{company.issue_date}  </td>
          <td scope="row" >{company.expiry_date}  </td>
          <td scope="row">{moment(company.created_at).format("MMM Do YY")}  </td> */}
              <td>
                <span
                  style={{ cursor: "pointer" }}
                  data-tip="Details"
                  onClick={this.showModel.bind(this, company)}
                >
                  <button
                    type="button"
                    className="btn btn-success fa fa-eye mr-2 text-white"
                    color="secondary "
                    id={"details-" + key}
                  >
                    <ReactTooltip /> Details
                  </button>
                </span>
              </td>

              <td>
                <span>
                  <div className="w-100 d-flex gap-1">
                  <div
                    // className="col-md-12 col-md-offset-6"
                    // style={{ width: 120, height: 5 }}
                  >
                    {company.status === 0 && (
                  
                      <span
                      style={{ cursor: "pointer" }}
                      data-tip="Approve Request"
                      onClick={this.changeStatus.bind(this, company, "1")}
                    >
                      <i
                        type="button"
                        className="btn btn-success fa fa-check-square mr-2 text-white"
                        color="secondary "
                        id={"approve-" + key}
                      >
                        <ReactTooltip />
                      </i>
                    </span>
                      
                    )}
                    {company.status === 1 && (
                   
                      <span
                      style={{ cursor: "pointer" }}
                      data-tip="Reject Request"
                      onClick={this.changeStatus.bind(this, company, "0")}
                    >
                      <i
                        type="button"
                        className="btn btn-danger fa fa-times-circle mr-2 text-white"
                        color="secondary "
                        id={"del-" + key}
                      >
                        <ReactTooltip />
                      </i>
                    </span>
                    )}
                  </div>
                  <div> <span style={{ cursor: "pointer" }} data-tip="Block Request">
                  <button
                    type="button"
                    className="btn btn-danger fa fa-trash mr-2 text-white"
                    color="secondary "
                    id={"block-" + key}
                  >
                    <ReactTooltip /> Delete
                  </button>
                </span>
             </div> 
                  </div>
               
                  
                  {/* <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showModel.bind(this, company)}> <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span>
            <ReactTooltip /> */}
                </span>
              </td>
            </tr>
          );
        }
      })
    );
  }

  render() {
    const {
      allCompanies,
      tooltipOpen,
      isOpen,
      company,
      showProgress,
    } = this.state;
    return (
      <Card style={{ "min-width": "270px" }}>
        <CardHeader color="primary">
          <Row className="ml-2">
            <h4>Companies</h4>
          </Row>
          <Row className="ml-2">
            <p>All companies registerd with our system</p>
          </Row>
        </CardHeader>
        <NotificationContainer />
        {showProgress && (
          <CardBody>
            <div
              style={{
                position: "absolute",
                left: "50%",
                height: "70%",
                width: "100%",
                top: "10%",
              }}
              xs={12}
            >
            </div>
          </CardBody>
        )}
        <CardBody>
          <Table
            bordered
            responsive
            className="table table-bordered table-hover"
          >
            <thead className="thead-dark shadow">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>City</th>
                {/* <th>State</th> */}
                <th>Country</th>
                {/* <th>Issue Date</th>
                <th>Expiry Date</th>
                <th>Created At</th> */}
                <th>Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#f2f2f2" }}>
              {allCompanies && this.renderResultRows(allCompanies)}
              {allCompanies && _.size(allCompanies.data) === 0 && (
                <td scope="row">{"No Records Found"} </td>
              )}
            </tbody>
          </Table>
          <nav>
            <Pagination className="pull-right mt-3" size="md">
              <PaginationItem disabled={this.state.currentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handleClick(e, this.state.currentPage - 1)
                  }
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(this.state.pagesCount)].map((page, i) => (
                <PaginationItem active={i === this.state.currentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => this.handleClick(e, i)}
                    href="#"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem
                disabled={this.state.currentPage >= this.state.pagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handleClick(e, this.state.currentPage + 1)
                  }
                  next
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          </nav>
          <Modal isOpen={isOpen} toggle={this.closeModal}>
            <ModalHeader>
              <strong>Company Details</strong>
            </ModalHeader>

            <ModalBody>
              <Col lg="12" md="12" sm="12" xs="12">
                <Grid>
                  <Cell>
                    <Row>
                      <Col lg="3" sm="3"></Col>
                      <Col lg="6" sm="3">
                        <img
                          className="rounded-circle"
                          src={
                            company &&
                            company.img_url !== "" &&
                            company.img_url !== null
                              ? "http://localhost:4500/uploads/" +
                                company.img_url
                              : profile
                          }
                          style={{ width: 160, height: 160 }}
                        />
                      </Col>
                      <Col lg="3" sm="3"></Col>
                    </Row>
                    <Row>
                      <br></br>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Name :</strong>{" "}
                            {company && company.name}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark text-justify">
                              {" "}
                              Email :
                            </strong>{" "}
                            {company && company.email}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark"> City :</strong>{" "}
                            {company && company.city}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Country :</strong>{" "}
                            {company && company.country}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Phone :</strong>{" "}
                            {company && company.phone}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Employees :</strong>{" "}
                            {company && company.no_of_employ}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Zip Code :</strong>{" "}
                            {company && company.zip_code}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">State :</strong>{" "}
                            {company && company.state}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Issue Date :</strong>{" "}
                            {company && company.issue_date}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Expiry Date :</strong>{" "}
                            {company && company.expiry_date}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Agency code :</strong>{" "}
                            {company && company.agency_code}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Street :</strong>{" "}
                            {company && company.street}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex bg-light mb-2">
                          <span>
                            <strong className="text-dark">Address :</strong>{" "}
                            {company && company.address1}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Cell>
                </Grid>
              </Col>
            </ModalBody>

            <ModalFooter>
              <Button color="secondary" onClick={this.closeModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </CardBody>
      </Card>
    );
  }
}
export default Companies;
