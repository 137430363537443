import React from "react";
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { sendPayment, createCustId } from '../../services/admin/Index';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import _ from "lodash";
import history from '../../history';
import { Spinner,Row } from 'reactstrap';


class StripePayment extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.state = {
      name: '',
      amount: '',
      error: false,
      hidden: false
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  async handleValidSubmit() {
    try {
      this.setState({ hidden: true });
      const userInfo = JSON.parse(localStorage.getItem('userData'));
      const user_token = userInfo.token;
      let stripeToken = await this.props.stripe.createToken({ name: this.state.token });
      const token_body = {
        stripe_token: stripeToken && stripeToken.token.id || '',
        token: user_token
      }
      const token_response = await createCustId(token_body);
      const body = {
        amount: this.state.amount,
        token: user_token
      }
      const response = await sendPayment(body);
      if (response.resStatus === 200 && token_response.resStatus === 200) {
        NotificationManager.success('Transaction Successful');
        setTimeout(() => history.goBack(), 3000);
      } else {
        NotificationManager.error('Something went wrong');
      }
      this.setState({ hidden: false });
    } catch (e) {
      throw e;
    }
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{"min-width":"270px"}}>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4 >Enter payment details below</h4>
              </Row>
            </CardHeader>
            <CardBody>
              <NotificationContainer />
              <div>
                <div class="container">
                  <div class="row">
                    <div class="col-md-8 m-auto">

                      <AvForm className='group'>
                        <AvField name="name" label="Name" type="name" onChange={this.onChange} required className='form-input' />
                        <AvField name="amount" label="Amount" type="amount" onChange={this.onChange} required className='form-input' />
                        <label>CC Number -- Exp. Date -- CVC</label>
                        <CardElement className="p-2 border border-dark" />

                        <label></label>
                        <div class="container">
                          <div class="row">
                            <div class="col text-center">
                              <a className="btn btn-light mr-2" on onClick={this.handleValidSubmit}>
                                <i className="fa fa-money mr-2 text-info"></i> Pay {this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                            </div>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    );
  }

}

export default injectStripe(StripePayment);