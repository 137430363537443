import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/footers/FiveColumnWithBackground.js";
import Blogs from "../components/Blogs/Blog";
import tw from "twin.macro";
import MainFeature1 from "../components/features/TwoCol";

const Blog = () => {
  const Subheading = tw.span`uppercase tracking-wider text-sm`;

  return (
    <>
      <div className="bg-white">
        <Navbar />
      </div>
      <MainFeature1
        subheading={<Subheading>Our Blog</Subheading>}
        heading="Complete Guide Of Gps Recruiter in Blog"
        buttonRounded={false}
        primaryButtonText="See Blog"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <Blogs />

      <Footer />
    </>
  );
};

export default Blog;
