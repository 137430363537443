import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from 'reactstrap';
import { getAllCompletedJobsByUser,addRatingForCompany,addNewComplaint } from '../../services/admin/Index';
import _ from "lodash";
import scroll from './scroll.css'
import ReactTooltip from "react-tooltip";
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Button, Spinner, Pagination, PaginationItem, PaginationLink, Input } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Grid, Cell } from 'react-mdl';


class CustomercompletedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      allUserJobs: '',
      pagesCount: 0,
      currentPage: 0,
      tooltipOpen: false,
      isOpen: false,
      isOpenDetails: false,
      showProgress: true,
      token: '',
      hidden: false,
      hidden_send: false,
      rating: 0,
      feedback: '',
      company : '',
      hidden_complaint : false,
      complaint : 0,
      title : '',
      description : ''
    };
    this.onChange = this.onChange.bind(this);
    this.showModel = this.showModel.bind(this);
    this.showModelDetails = this.showModelDetails.bind(this);
    this.showModelComplaint = this.showModelComplaint.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalDetails = this.closeModalDetails.bind(this);
    this.closeModalComplaint = this.closeModalComplaint.bind(this);
    this.submitfeedback = this.submitfeedback.bind(this);
    this.addNewComplaint = this.addNewComplaint.bind(this);
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    const allUserJobs = await getAllCompletedJobsByUser(this.state.currentPage + 1, token);
    //console.log("Users ",allUserJobs)
    if (allUserJobs.data.length != 0) {
      await this.setState({
        allUserJobs: allUserJobs,
        pagesCount: Math.ceil(allUserJobs.total / allUserJobs.data.length),
        showProgress: false,
        company : userInfo,
        token: token, 
        hidden: true
      })
    } else {
      await this.setState({
        allUserJobs: allUserJobs,
        showProgress: false,
        company : userInfo,
        token: token, 
        hidden: true
      })
    }
  }

  async handleClick(e, index) {
    e.preventDefault();
    const allUserJobs = await getAllCompletedJobsByUser(index + 1, this.state.token);
    this.setState({
      currentPage: index,
      allUserJobs: allUserJobs
    })

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  showModel(job) {
    this.setState({ isOpen: true, job });
  }

  closeModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  showModelComplaint(job) {
    this.setState({ isOpenComplaint: true, job });
  }

  closeModalComplaint() {
    this.setState({ isOpenComplaint: false });
  }

  showModelDetails(job) {
    this.setState({ isOpenDetails: true, job });
  }

  closeModalDetails() {
    this.setState({ isOpenDetails: false });
  }

  async submitfeedback() {
    try {
      this.setState({ hidden: true })
      const body = {
        job_id : this.state.job.id,
        company_id : this.state.company.data.id,
        rating : this.state.rating,
        review : this.state.feedback,
        token : this.state.token
      }
      const response = await addRatingForCompany(body)
      //console.log(response)
      if (response.status == 200) {
        NotificationManager.success('Feedback Submitted Succesfuly');
        this.setState({ hidden : false, isOpen: !this.state.isOpen });
        this.componentDidMount()
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden : false, isOpen: !this.state.isOpen });
      }
    } catch (e) {
      throw (e);
    }
  }

  async addNewComplaint(){
    try{
        this.setState({hidden_complaint:true})
        const body = {
            title : this.state.title,
            description : this.state.description,
            job_id : this.state.job.id,
            token : this.state.token
        }
        const response = await addNewComplaint(body);
        if (response.status == 200) {
            NotificationManager.success('Complaint Submitted Successfully');
            setTimeout(() => this.setState({ isOpenComplaint: false , hidden_complaint: false }), 1000);
        } else {
            NotificationManager.error('Something went wrong');
            setTimeout(() => this.setState({ isOpenComplaint: false , hidden_complaint: false }), 1000);
        }
    }catch(e){
        throw e;
    }
  }

  renderRows(allUserJobs) {
    return allUserJobs && this.state.allUserJobs.data.map((user, key) => {
      {
        return <tr key={key}>
          <td scope="row">{user.id}  </td>
          <td scope="row">{user.user_name}  </td>
          <td scope="row">{user.address}  </td>
          <td scope="row">{user.person}  </td>
          <td scope="row">{user.status}  </td>
          <td><span>
            <div className="col-md-6 col-md-offset-6">
              <span style={{ cursor: "pointer" }} data-tip="Complaint" onClick={this.showModelComplaint.bind(this, user)}>
                <i className="fa fa-comment-o mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
            <ReactTooltip />
          </span></td>
          <td><span>
            <div className="col-md-6 col-md-offset-6">
              <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showModelDetails.bind(this, user)}> <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span>
              <ReactTooltip />
            </div>
          </span>
          </td>
        </tr>
      }

    })
  }

  render() {
    const { allUserJobs, isOpen, isOpenDetails, isOpenComplaint ,hidden } = this.state;
    return (
      <GridContainer>
        <NotificationContainer />
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{"min-width":"270px"}}>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4>Completed Jobs</h4></Row>
              <Row className="ml-2">
                <p>
                  All Completed Jobs record
                </p></Row>
            </CardHeader>
            {!hidden &&
              <CardBody>
                <div style={{
                  position: 'absolute', left: '50%', top: '10%',
                }} xs={12}><Spinner style={{ background: "text-success", width: '3rem', height: '3rem' }} value={false} /></div>
              </CardBody>
            }
            {hidden && 
            <CardBody>
              <Table bordered responsive class={scroll}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Person</th>
                    <th>Job Status</th>
                    <th>Complaint</th>
                    {/* <th>Feedback</th> */}
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allUserJobs && this.renderRows(allUserJobs)
                  }
                  {
                    allUserJobs && _.size(allUserJobs.data) === 0 && <td scope="row">{"No Records Found"}  </td>
                  }
                </tbody>
              </Table>
              <nav>
                <Pagination className="pull-right mt-3" size="md">
                  <PaginationItem disabled={this.state.currentPage <= 0}>
                    <PaginationLink
                      onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                      previous
                      href="#" />
                  </PaginationItem>
                  {[...Array(this.state.pagesCount)].map((page, i) =>
                    <PaginationItem active={i === this.state.currentPage} key={i}>
                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>
                    <PaginationLink
                      onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>
              </nav>

              <Modal isOpen={isOpenComplaint} toggle={this.closeModalComplaint}>
                <ModalHeader ><strong>Complaint</strong></ModalHeader>
                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <Grid>
                                            <Cell >
                                                <Row><label className="ml-2">Title</label></Row>
                                                <Row>
                                                    <Input name="title" className="ml-2" type="text" onChange={this.onChange} />
                                                </Row>
                                                <Row><label className="ml-2 mt-2">Description</label></Row>
                                                <Row>
                                                    <Input name="description" className="ml-2" type="textarea" rows={3} onChange={this.onChange} />
                                                </Row>
                                                
                                                </Cell>
                                            </Grid>
                                            </Col>
                                        </ModalBody>
                <ModalFooter>
                  <Button className="m-2 ml-4" color="primary" borderColor="transparent" onClick={this.addNewComplaint}>Submit{this.state.hidden_complaint && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</Button>
                  <Button color="secondary" onClick={this.closeModalComplaint}>Close</Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={isOpenDetails} toggle={this.closeModalDetails}>
                <ModalHeader ><strong>Job Details</strong></ModalHeader>

                <ModalBody>
                  <Col lg='12' md='12' sm='12' xs='12'>
                    <div className='d-flex bg-light mb-2'>
                      <Col lg='12' md='8' sm='12' xs='12'>
                        <span><strong className="text-dark">User Name :</strong> {this.state.job && this.state.job.user_name}</span>
                      </Col>
                    </div>
                    <div className='d-flex bg-light mb-2'>
                      <Col lg='12' md='8' sm='12' xs='12'>
                        <span><strong className="text-dark">Address :</strong> {this.state.job && this.state.job.address}</span>
                      </Col>
                    </div>
                    <div className='d-flex bg-light mb-2'>
                      <Col lg='12' md='8' sm='12' xs='12'>
                        <span><strong className="text-dark">Person :</strong> {this.state.job && this.state.job.person}</span>
                      </Col>
                    </div>
                    <div className='d-flex bg-light mb-2'>
                      <Col lg='12' md='12' sm='12' xs='12'>
                        <span><strong className="text-dark text-justify">Job Description :</strong> {this.state.job && this.state.job.job_desc}</span>
                      </Col>
                    </div>
                    <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> Start Time :</strong>{this.state.job && this.state.job.start_time}</span>
                                            </Col>
                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> End Time :</strong>{this.state.job && this.state.job.end_time}</span>
                                            </Col>
                                        </div>
                  </Col>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.closeModalDetails}>Close</Button>
                </ModalFooter>
              </Modal>
            </CardBody>
            }
          </Card>
        </GridItem>

      </GridContainer>
    );
  }

}
export default CustomercompletedJobs;