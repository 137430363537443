import { post, get, put, destroy } from '../defaults';
// remaining  --* //

 
 
const server = process.env.REACT_APP_URL
console.log("server", server);
//1-registerCustomer
export const customerSignup = (options = {}) => {
    return post(`${server}/user/registerCustomer`, options);
}
//2-registerEmploy
export const employSignup = (options = {}) => {
    return post(`${server}/user/registerEmploy`, options);
}
//3-registerCompany 
export const registerCompany = (options = {}) => {
    return post(`${server}/user/registerCompany`, options);
}
//4-loginAdmin
export const adminLogin = (options = {}) => {
    return post(`${server}/user/loginAdmin`, options);
}
//5-loginCustomer
export const customerLogin = (options = {}) => {
    return post(`${server}/user/loginCustomer`, options);
}
//6-getAllModule
export const getAllModule = (options = {}) => {
    return get(`${server}/user/getAllModule`, options);
}
//7-getAllJobs
export const getAllJobs = (pgNo,options = {}) => {
    return get(`${server}/job/getAllJobs/`+pgNo, options);
}
export const getAllJobsByCompany = (pgNo,options = {}) => {
    return get(`${server}/job/getAllJobsByCompany/`+pgNo, options);
}
//8-getAllJobsByUser     || total zada aa ra
export const getAllJobsbyUser = (pgNo,options = {}) => {
    return get(`${server}/job/getAllJobsByUser/`+pgNo, options);
}
//9-createJob
export const createJob = (options = {}) => {
    return post(`${server}/job/createJob`, options);
}
//10-getAllCompanies
export const getAllCompanies = (pgNo,options = {}) => {
    return get(`${server}/job/getAllCompanies/`+pgNo, options);
}
//11-getAllCompalints
export const getAllComplaints = (pgNo,options = {}) => {
    return get(`${server}/job/getAllComplaints/`+pgNo, options);
}
//12-updateStatus
export const approveCompany = (options = {}) => {
    return post(`${server}/user/updateStatus`, options);
}
//13-updateEmployee        || token ni hai
export const updateEmploy = (options = {}) => {
    return post(`${server}/user/updateEmploy`, options);
}
//14-updateCustomer         || token ni hai
export const updateCustomer = (options = {}) => {
    return post(`${server}/user/updateCustomer`, options)
}
//15-updateCompany          || token ni hai
export const updateCompany = (options = {}) => {
    return post(`${server}/user/updateCompany`, options)
}
//16-getUserDetailsByUser     --*
export const getUserDetailsByUser = (options = {}) => {
    return post(`${server}/job/getUserDetailsByUser`, options)
}
//17-addNewComplaint     --*
export const addNewComplaint = (options = {}) => {
    return post(`${server}/job/addNewComplaint`, options)
}
//18-forgot-password             || token ni hai
export const forgotpassword = (options = {}) => {
    return post(`${server}/user/forgot-password`, options)
}
//19-updatePassword                 || token ni hai 
export const updatePassword = (options = {}) => {
    return post(`${server}/user/updatePassword`, options)
}
//20-updateSettings     --*
export const updateSettings = (options = {}) => {
    return post(`${server}/setting/updateSettings`, options)
}
//21-saveLimitSettings     --*
export const saveLimitSettings = (options = {}) => {
    return post(`${server}/setting/saveLimitSettings`, options)
}
//22-getSettings
export const getSettings = (options = {}) => {
    return get(`${server}/setting/getSettings`, options)
}
//23-receivePayment     --*
export const receivePayment = (options = {}) => {
    return post(`${server}/package/receivePayment`, options)
}
//24-sendNotification
export const sendNotification = (options = {}) => {
    return post(`${server}/user/sendNotification`, options)
}
//25-updateComplaint     --*
export const updateComplaint = (options = {}) => {
    return post(`${server}/job/updateComplaint`, options)
}
//26-getAllEmployes
export const getAllEmployes = (pgNo,options = {}) => {
    return get(`${server}/user/getAllEmployes/`+pgNo, options)
}
//27-getAllUsers
export const getAllUsers = (pgNo,options = {}) => {
    return get(`${server}/user/getAllUsers/`+pgNo, options)
}
//28-createCustId
export const createCustId = (options = {}) => {
    return post(`${server}/package/createCustId`, options)
}
//29-sendPayment     --*
export const sendPayment = (options = {}) => {
    return post(`${server}/package/sendPayment`, options)
}
//30-applyforJob
export const applyforJob = (options = {}) => {
    return post(`${server}/job/getUserDetailsByJob`, options)
}
//31-assignJobtoUser
export const assignJobtoUser = (options = {}) => {
    return post(`${server}/job/assignJobToCompany`, options)
}
//32-getAllPurchasedJobsByCompany
export const getAllPurcahsedJobsByCompany = (pgNo,options = {}) => {
    return get(`${server}/job/getAllJobsPurchasedByCompany/`+pgNo, options);
}
//33-getCompanyDashboardStats
export const getCompanyDashboardStats = (options = {}) => {
    return get(`${server}/user/getCompanyDashboardStats`,options);
}
//34-getAllAssignedJobsByCompany
export const getAllAssignedJobsByCompany = (pgNo,options = {}) => {
    return get(`${server}/job/getAllAssignedJobsByCompany/`+pgNo,options);
}
//35-getAdminDashboardStats
export const getAdminDashboardStats = (options = {}) => {
    return get(`${server}/user/getAdminDashboardStats`,options);
}
//36-getAllJobsByAdmin
export const getAllJobsByAdmin = (pgNo,options = {}) => {
    return get(`${server}/job/getAllJobsByAdmin/`+pgNo,options);
}
//37-addRatingForUser
export const addRatingForUser = (options = {}) => {
    return post(`${server}/job/addRatingForUser`,options);
}
//38-addRatingForCompany
export const addRatingForCompany = (options = {}) => {
    return post(`${server}/job/addRatingForCompany`,options);
}
//39-OnGoingJobsByUser
export const onGoingJobsByUser = (pgNo,options = {}) => {
    return get(`${server}/job/getAllOnGoingJobsByUser/`+pgNo,options);
}
//40-getAllCompletedJobsByUser
export const getAllCompletedJobsByUser = (pgNo,options = {}) => {
    return get(`${server}/job/getAllCompletedJobsByUser/`+pgNo,options);
}
//41-getAllCompletedJobsByCompany
export const getAllCompletedJobsByCompany = (pgNo,options = {}) => {
    return get(`${server}/job/getAllCompletedJobsByCompany/`+pgNo,options);
}
//42-getStatement
export const getStatement = (page,pgNo,options = {}) => {
    return get(`${server}/user/getStatement/`+page+`/`+pgNo,options);
}
//43-getStatementStats
export const getStatementStats = (page,options = {}) => {
    return get(`${server}/user/getStatementStats/`+page,options);
}
//44-getRating
export const getRating = (options = {}) => {
    return get(`${server}/job/getRating`,options);
}
