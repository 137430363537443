import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import CardFooter from "components/Card/CardFooter.js";
import { getAllJobsByCompany, getCompanyDashboardStats, applyforJob } from '../../services/admin/Index';
import _ from "lodash";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import history from '../../history';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Spinner, Button } from 'reactstrap';
import { Row } from 'reactstrap';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Grid, Cell } from 'react-mdl';

const useStyles = makeStyles(styles);


export default function Dashboard() {
  const [jobs, setAllJobs] = useState([]);
  const [stats, setStats] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [job, setJob] = useState();
  const [hidden, setSpinner] = useState(false);
  const [bid_tokens, setBidTokens] = useState();

  const showModel = (job) => {
    setIsOpen(true);
    setBidTokens(job.req_tokens);
    setJob(job);
  }

  const closeModal = () => {
    setIsOpen(!isOpen);
  }
  const applyforjob = async () => {
    try {
      setSpinner(!hidden);
      const userInfo = JSON.parse(localStorage.getItem('userData'));
      const user_token = userInfo.token
      if (userInfo.data.tokens >= bid_tokens) {
        const body = {
          job_id: job.id,
          token: user_token
        }
        const response = await applyforJob(body);
        if (response.status == 200) {
          setSpinner(hidden);
          closeModal();
          history.push('/company/purchaseJobs')
          NotificationManager.success('Applied Succesfuly');
        } else {
          NotificationManager.error('Something went wrong');
        }
      } else {
        history.push('/company/purchasetoken')
      }
    } catch (e) {
      throw (e);
    }
  }
  const renderRows = (allUserJobs) => {
    return allUserJobs && allUserJobs.data.map((job, key) => {
      {
        return <div className="flex bg-grey shadow-md m-4 p-6 rounded">
          <Col >
            <Row>
              
              <Col lg={6} md={6} xs={12}>
              <Row>
                  <strong>
                    <strong className="text-info">{job.person} Secuity Persons required for this job  </strong>
                  </strong>
                </Row>
                <Row>
                  <strong>
                    <strong className="text-info">Job Description : </strong>{job.job_desc}
                  </strong>
                </Row>
                <Row>
                  <strong className="text-info">
                    More info <span style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => { showModel(job) }} href="#" id="info">Click</span>.
                      </strong>
                </Row>
              </Col>
              <Col lg={6} xs={12}>
                <Row>
                  <strong>
                    <strong className="text-info">Start Time : </strong>{job.start_time.slice(11, 16)}
                  </strong>
                </Row>
                <Row>
                  <strong>
                    <strong className="text-info">End Time : </strong>{job.end_time.slice(11, 16)}
                  </strong>
                </Row>
                <Row>
                  <strong>
                    <strong className="text-info">Tokens Required : </strong>{job.req_tokens}
                  </strong>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
      }
    })
  }
  useEffect(() => {

    const fetchData = async () => {
      const userInfo = JSON.parse(localStorage.getItem('userData'));
      const token = userInfo.token;
      const companyStats = await getCompanyDashboardStats(token);
      setStats(companyStats.data);
      const allUserJobs = await getAllJobsByCompany(1, token);
      setAllJobs(allUserJobs);
    }
    fetchData();
  }, [])

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <NotificationContainer />
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="warning" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">{"Total Jobs : " + stats.total_jobs}</p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="success" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="success">
                  <Store />
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">{"Completed Jobs : " + stats.completed_jobs}</p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="danger" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">{"Available Tokens : " + stats.token}</p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="info" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="info">
                  <Accessibility />
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">{"No of Employees : " + stats.employs}</p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
        <Card>
          <CardHeader color="warning">
            <Row className="ml-2">
              <h4 className={classes.cardTitleWhite}>Latest Jobs</h4></Row>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>

            {
              jobs && jobs.data && renderRows(jobs)
            }
            {jobs && _.size(jobs.data) === 0 && <tr> <td scope="row">{"No Records Found"}  </td> </tr>

            }
          </CardBody>
        </Card>
      <Modal isOpen={isOpen} toggle={closeModal}>
        <ModalHeader ><strong>Job Detail</strong></ModalHeader>

        <ModalBody>
          <Col lg='12' md='12' sm='12' xs='12'>
            <Grid>
              <Cell>
                {/* <Row>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark">User Name :</strong> {job && job.username}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark">Address :</strong> {job && job.address}</span>
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark">Person :</strong> {job && job.person}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark text-justify">Job Description :</strong> {job && job.job_desc}</span>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark">Status :</strong> {job && job.status}</span>
                    </div>
                  </Col>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark">Bid ID :</strong> {job && job.bid_id}</span>
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark"> Start Time :</strong> {job && job.start_time}</span>
                    </div>
                  </Col>
                  <Col>
                    <div className='d-flex bg-light mb-2'>
                      <span><strong className="text-dark"> End Time :</strong> {job && job.end_time}</span>
                    </div>
                  </Col>
                </Row>
              </Cell>
            </Grid>
          </Col>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => { applyforjob() }}>Purchase Detail{hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</Button>
          <Button color="secondary" onClick={() => { closeModal() }}>Close</Button>
        </ModalFooter>
      </Modal>

    </div>
  );
}