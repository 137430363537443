import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import history from "../../history/index";
import { registerCompany } from "../../services/admin/Index";
import { auth, firestore } from "../../firebase/firebase";

const CompanyRegistration = () => {
  const [hidden, setHidden] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    email: "",
    sufix: "",
    name: "",
    city: "",
    state: "",
    zip_code: "",
    phone: "",
    expiry_date: "",
    issue_date: "",
    password: "",
    address1: "",
    address2: "",
    country: "",
    agency_code: "",
  });
  console.log("state", state);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInvalidSubmit = (event, errors, values) => {
    setEmail(values.email);
    setError(true);
  };

  const login = () => {
    history.push("/company-login");
  };

  const registeredCompany = async (event) => {
    event.preventDefault();
    console.log("state", state);
    console.log("event", event.target);
    if (state.password.length > 7) {
      if (state.password === state.confirmPassword) {
        delete state.confirmPassword;
        const response = await registerCompany(state);
        console.log("state", state);
        if (response.status >= 400) {
          NotificationManager.error("Something went wrong");
        } else {
          // NotificationManager.success("Signup Succesfuly");
          alert("Signup Succesfully")
          await auth
            .createUserWithEmailAndPassword(state.email, state.password)
            .then(async (result) => {
              await firestore.collection("users").add({
                company_id: response.data.id,
                name: state.name,
                id: result.user.uid,
                email: state.email,
                password: state.password,
                img_url: "",
                user_type: "company",
                isonline: false,
                blocklist: [],
                isverify: false,
              });
            })
            .catch((e) => {
              alert(e);
            });
          await auth.signOut();
          setHidden(false);
          history.push("/company-login");
        }
      } else {
        NotificationManager.success("Password Mismatched");
      }
    } else {
      NotificationManager.error(
        "Password should consists of atleast 8 characters"
      );
    }
  };

  return (
    <>
      <div className="bg-white">
        <Navbar />
      </div>
      <div>
        <div className="flex flex-col items-center min-h-screen pt-12 mb-12 sm:justify-center bg-gray-50 ">
          <div className="mt-8">
            <a href="/">
              <h3 className="text-4xl font-bold text-purple-600">
                Company Sign Up
              </h3>
            </a>
          </div>
          <div className="w-full px-6 py-4 mt-2 overflow-hidden bg-white   sm:max-w-4xl sm:rounded-lg">
            <form
              onSubmit={registeredCompany}
              onInvalidSubmit={handleInvalidSubmit}
            >
              <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
                <div>
                  <div className="mt-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      Company Name
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="name"
                        type="text"
                        required
                        value={state.name}
                        onChange={handleChange}
                        className="block h-12 p-2 w-full mt-1 border border-gray-300  rounded-md   focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      Email
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="email"
                        type="email"
                        required
                        value={state.email}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                
                  <div className="mt-4">
                    <label
                      htmlFor="suffix"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      suffix
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="sufix"
                        type="text"
                        required
                        value={state.sufix}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="issue_date"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      issue Date
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="issue_date"
                        type="date"
                        required
                        value={state.issue_date}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      city
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="city"
                        type="text"
                        required
                        value={state.city}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                 
                  <div className="mt-4">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      Password
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        type="password"
                        name="password"
                        value={state.password}
                        onChange={handleChange}
                        required
                        className="block h-12 p-2 w-full mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mt-4">
                    <label
                      htmlFor="address1"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      Address
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="address1"
                        type="text"
                        required
                        value={state.address1}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="Phone"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      Phone{" "}
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="phone"
                        type="text"
                        required
                        value={state.phone}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      state
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="state"
                        type="text"
                        required
                        value={state.state}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                
                  <div className="mt-4">
                    <label
                      htmlFor="expiry_date"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      expiry Date
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="expiry_date"
                        type="date"
                        required
                        value={state.expiry_date}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="zip_code"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      Zip Code
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        name="zip_code"
                        type="text"
                        required
                        value={state.zip_code}
                        onChange={handleChange}
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                  
                 
                  <div className="mt-4">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700 undefined"
                    >
                      Confirm Password
                    </label>
                    <div className="flex flex-col items-start">
                      <input
                        type="password"
                        name="confirmPassword"
                        value={state.confirmPassword}
                        onChange={handleChange}
                        required
                        className="block h-12 w-full p-2 mt-1 border border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <a href="#" className="text-xs text-purple-600 hover:underline">
                Forget Password?
              </a>
              <div className="flex items-center mt-4">
                <button
                  className="w-full h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                  type="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div className="mt-4 text-grey-600">
              Already have an account?
              <span>
                <Link
                  to="/company-login"
                  className="text-purple-600 hover:underline"
                >
                  <b> Log In</b>
                </Link>
              </span>
            </div>
          </div>
        </div>

       
      </div>
    </>
  );
};

export default CompanyRegistration;
