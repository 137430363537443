
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';

// Note: this API requires redux@>=3.1.0
const store = createStore(rootReducer, applyMiddleware(thunk));
// store.subscribe(()=>{
//     const state=store.getState();
//     console.log('I dispatched successfully: ',state)
// })

export default store;