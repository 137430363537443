import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/footers/FiveColumnWithBackground.js";
import {
  BugAntIcon,
  ChatBubbleLeftRightIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/outline";

const Support = () => {
  return (
    <div>
      <div className="bg-white w-full">
        <Navbar />
      </div>
      <div className="relative bg-gray-200">
        <div className="relative h-80 overflow-hidden bg-purple-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
          <img
            className="h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80"
            alt=""
          />
          <svg
            viewBox="0 0 926 676"
            aria-hidden="true"
            className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
          >
            <path
              fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
              fillOpacity=".4"
              d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
            />
            <defs>
              <linearGradient
                id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
                x1="926.392"
                x2="-109.635"
                y1=".176"
                y2="321.024"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#776FFF" />
                <stop offset={1} stopColor="#FF4694" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40">
          <div className="pr-6 md:ml-auto md:w-2/3 lg:w-1/2 lg:pr-0 pl-8">
            <h2 className="text-lg font-semibold leading-7 text-indigo-700">
              Award winning support
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-black sm:text-4xl">
              We’re here to help
            </p>
            <p className="mt-6 text-lg leading-7 text-gray-800">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et,
              egestas tempus tellus etiam sed. Quam a scelerisque amet
              ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat
              quisque ut interdum tincidunt duis.
            </p>
            <div className="mt-6 justify-center">
              <button
                className="w-64 h-12 px-3 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                type="submit"
              >
                Visit Here
              </button>
            </div>
            <div className="isolate px-6 lg:px-8">
              <div className="mx-auto w-full mt-20  space-y-16">
                <div className="flex gap-x-6">
                  <div className="flex h-12 w-16 shrink-0 items-center justify-center rounded-lg bg-purple-600">
                    <ChatBubbleLeftRightIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold leading-7 text-gray-900">
                      Sales support
                    </h3>
                    <p className="mt-2 leading-7 text-gray-600">
                      Ut cursus est ut amet. Lobortis eget egestas leo vitae
                      eget porttitor risus blandit. Nunc a in lorem vel iaculis
                      porttitor.
                    </p>
                    <p className="mt-4">
                      <button
                        className="w-48 h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                        type="submit"
                      >
                        Contact us <span aria-hidden="true">&rarr;</span>
                      </button>
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-6">
                  <div className="flex h-12 w-16 shrink-0 items-center justify-center rounded-lg bg-purple-600">
                    <BugAntIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold leading-7 text-gray-900">
                      Bug reports
                    </h3>
                    <p className="mt-2 leading-7 text-gray-600">
                      Expedita qui non ut quia ipsum voluptatum ipsam pariatur.
                      Culpa vitae ipsum minus eius vero quo quibusdam.
                    </p>
                    <p className="mt-4">
                      <button
                        className="w-48 h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                        type="submit"
                      >
                        Report a bug <span aria-hidden="true">&rarr;</span>
                      </button>
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-6">
                  <div className="flex h-12 w-16 shrink-0 items-center justify-center rounded-lg bg-purple-600">
                    <ComputerDesktopIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold leading-7 text-gray-900">
                      Technical support
                    </h3>
                    <p className="mt-2 leading-7 text-gray-600">
                      Sint aut modi porro consequatur architecto commodi qui
                      consequatur. Dignissimos adipisci minima.
                    </p>
                    <p className="mt-4">
                      <button
                        className="w-48 h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                        type="submit"
                      >
                        Join our Discord <span aria-hidden="true">&rarr;</span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Support;
