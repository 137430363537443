import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from 'reactstrap';
import { getAllEmployes } from '../../services/admin/Index';
import _ from "lodash";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Button, Spinner, Alert,Pagination,PaginationItem,PaginationLink} from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReactTooltip from "react-tooltip";
import profile from "../../assets/img/profile.png";
import {CardImg} from 'reactstrap';
import { Grid, Cell } from 'react-mdl';

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.showModel = this.showModel.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      allEmployees: '',
      pagesCount: 0,
      currentPage: 0,
      tooltipOpen: false,
      isOpen: false,
      showProgress: true,
      token: '',
    };
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    const allEmployees = await getAllEmployes(this.state.currentPage+1,token);
    if(allEmployees.data.length!=0){
      this.setState({
                      allEmployees: allEmployees,
                      pagesCount: Math.ceil(allEmployees.total/allEmployees.data.length),
                      showProgress: false,
                      token : token
                  })
    }else{
      this.setState({
        allEmployees: allEmployees,
        showProgress: false,
        token : token
      })
    }
  }
  
  async handleClick(e, index) {
      e.preventDefault();
      const allEmployees = await getAllEmployes(index+1,this.state.token);
      this.setState({
        currentPage: index,
        allEmployees:allEmployees
      })
  }

  showModel(employee) {
    this.setState({ isOpen: true, employee });

  }
  closeModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  renderResultRows(allEmployees) {
    return allEmployees && this.state.allEmployees.data.map((employee, key) => {
      {
        return <tr key={key} >
          <td scope="row">{employee.id}  </td>
          <td scope="row">{employee.first_name +' '+ employee.last_name}  </td>
          {/* <td scope="row">{employee.user_name}  </td> */}
          <td scope="row">{employee.email}  </td>
          {/* <td scope="row">{employee.gender}  </td> */}
          <td scope="row">{employee.phone}  </td>
          <td scope="row">{employee.address}  </td>
          {/* <td scope="row">{employee.city}  </td>
          <td scope="row">{employee.state}  </td>
          <td scope="row">{employee.country}  </td> */}
          <td><span>
              <div className="col-md-6 col-md-offset-6">
                <span style={{ cursor: "pointer" }} data-tip="Block Request"> 
                  <i className="fa fa-trash mr-2 text-warning" color="secondary" id={'block-' + key} /></span></div>
            <ReactTooltip />
            {/* <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showModel.bind(this, employee)}> <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span>
            <ReactTooltip /> */}
          </span></td>
          <td><span>
              <div className="col-md-6 col-md-offset-6">
                <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showModel.bind(this, employee)}>
                  <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
            <ReactTooltip />
          </span></td>
        </tr>
      }
    })
  }

  
  render() {
    const { allEmployees, tooltipOpen, isOpen, employee, showProgress } = this.state;
    return (

        <Card style={{"min-width":"270px"}}>
          <CardHeader color="primary">
            <Row className="ml-2">
              <h4>Employees</h4>
            </Row>
            <Row className="ml-2">
              <p>All Employees records</p>
            </Row>
          </CardHeader>
          <NotificationContainer />
          {showProgress &&
            <CardBody>
              <div style={{
                position: 'absolute', left: '50%', height: '70%', width: '100%', top: '10%',
              }} xs={12}></div>
            </CardBody>
          }
          <CardBody>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th >ID</th>
                  <th>Name</th>
                  {/* <th>User Name</th> */}
                  <th>Email</th>
                  {/* <th>Gender</th> */}
                  <th>Phone Number</th>
                  <th>Address</th>
                  {/* <th>City</th>
                  <th>State</th>
                  <th>Country</th> */}
                  <th>Action</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {
                  allEmployees && this.renderResultRows(allEmployees)
                }
                {allEmployees && _.size(allEmployees.data) === 0 && <td scope="row">{"No Records Found"}  </td>

                }

              </tbody>
            </Table>
            <nav>
                <Pagination className="pull-right mt-3" size="md" >
                
                <PaginationItem disabled={this.state.currentPage <= 0}>
                  
                  <PaginationLink
                    onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                    previous
                    href="#"
                  />
                  
                </PaginationItem>

                {[...Array(this.state.pagesCount)].map((page, i) => 
                  <PaginationItem active={i === this.state.currentPage} key={i}>
                    <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                      {i+1}
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>
                  
                  <PaginationLink
                    onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                    next
                    href="#"
                    />
                  
                </PaginationItem>
                
              </Pagination>
            </nav>
            <Modal isOpen={isOpen} toggle={this.closeModal}>
              <ModalHeader ><strong>Employee Detail</strong></ModalHeader>

              <ModalBody>
                <Col lg='12' md='12' sm='12' xs='12'>
                  <Grid>
                    <Cell >
                      <Row>
                        <Col lg='3' sm="3"></Col>
                        <Col lg='6' sm="3">
                          <img
                                      alt={profile}
                                      className="rounded-circle"
                                      src={employee && employee.img_url!=="" && employee.img_url!==null ? "http://localhost:4500/uploads/"+employee.img_url : profile}
                                      style={{width: 160, height: 160}}
                                    />
                        </Col>
                        <Col lg='3' sm="3"></Col>
                      </Row>
                      <Row>
                        <br></br>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">First Name :</strong> {employee && employee.first_name}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Last Name :</strong> {employee && employee.last_name}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark text-justify"> Email :</strong> {employee && employee.email}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">User Name :</strong> {employee && employee.user_name}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Gender :</strong> {employee && employee.gender}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Device Type :</strong> {employee && employee.device_type}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark"> City :</strong> {employee && employee.city}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Country :</strong> {employee && employee.country}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Phone :</strong> {employee && employee.phone}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Vehicle Type :</strong> {employee && employee.vehicle_type}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Status :</strong> {employee && employee.status}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">State :</strong> {employee && employee.state}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Zip Code :</strong> {employee && employee.zip_code}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                          <span><strong className="text-dark">Available Hours :</strong> {employee && employee.available_hours}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex bg-light mb-2'>
                            <span><strong className="text-dark">Address :</strong> {employee && employee.address}</span>
                          </div>
                        </Col>
                      </Row>
                    </Cell>
                  </Grid>
                </Col>
              </ModalBody>

              <ModalFooter>
                <Button color="secondary" onClick={this.closeModal}>Close</Button>
              </ModalFooter>
            </Modal>

          </CardBody>
        </Card>
      );
  } 
}
export default Employees;
