import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CardBody from 'components/Card/CardBody';
import { Row, Col, Spinner } from 'reactstrap';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import './login.scss'
import { connect } from "react-redux";
import { addArticle } from "../actions/index";
import { forgotpassword, updatePassword } from '../services/admin/Index';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import history from '../history';
import './style.css'
class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);

    this.sendEmail = this.sendEmail.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.newPassword =  this.newPassword.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.login =this.login.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = { 
        email: '',
        count: 0,
        user_type: '',
        verify_code: 0,
        password: '',
        confirm_password: '',
        token: '',
        hidden: false
    };
    localStorage.removeItem('userData');
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  async sendEmail() {
    const type = this.state.user_type
    if(type.toLowerCase()=='customer'){
      await this.setState({user_type : 'customer'})
    }else if(type.toLowerCase()=='company'){
      await this.setState({user_type : 'company'})
    }else if(type.toLowerCase()=='employee'){
      await this.setState({user_type : 'employ'})
    }
    const body = {
      email: this.state.email,
      user_type: this.state.user_type,
      token: this.state.token
    }
    console.log(body)
    const response = await forgotpassword(body)
    console.log('response : ',response)
    // this.setState({
    //     count : this.state.count + 1,
    //     hidden : false,
    // });
  }

  async verifyCode(event, model) {
    if(this.state.verify_code == model.code){
      this.setState({
          count : this.state.count + 1
      });
    }else{
      NotificationManager.success('Code Incorrect');
    }
  }

  async newPassword() {
    if(this.state.password==this.state.confirm_password){
      const body = {
        email : this.state.email,
        password : this.state.password
      }
      const response = await updatePassword(body)
      this.setState({
          count : 0
      });
      this.login()
    }else{
      NotificationManager.success('Password Mismatch');
    }
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ email: values.email, error: true });
  }
  
  login() {
    history.push('/login')
  }

  closeModal() {
    this.setState({ email: false, error: false });
  }
  render() {
    const modalError = this.state.error ? 'not' : ''; // This is just for the modal
    const { hidden } = this.state;
    return (
      <body>
        <div id='landing'>
          <div class="landing-inner text-light">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1 class="display-10 mb-2 text-info">GREATPATRIOTS</h1>
                  <h1 class="display-5 mb-4">Forgot Password</h1>
                  
                </div>
                
              </div>
            </div>
            {this.state.count==0 &&
                    <div>
                      <Row className='mx-0'>
                        <Col lg='6' md='8' sm='12' xs='12' className='m-auto'>
                            <Card>
                            <NotificationContainer />
                            <CardBody>
                                <AvForm onValidSubmit={this.sendEmail} onInvalidSubmit={this.handleInvalidSubmit} className='group'>
                                <label className="pull-left">Email Address</label>
                                <AvField name="email" type="email" onChange={this.onChange} required className='form-input' />
                                <label className="pull-left">Please select account type</label>
                                <AvField type="select" name="user_type" onChange={this.onChange} >
                                  <option>Account Type</option>
                                  <option>Admin</option>
                                  <option>Company</option>
                                  <option>Customer</option>
                                  <option>Employee</option>
                              </AvField>
                                <CardBody>

                                </CardBody>
                                <Button type="submit" color="primary w-100">Send {hidden && <Spinner size="sm" role="status"></Spinner>}</Button>
                                <div className='pull-left'>
                                    <span type="button" className='text-primary' onClick={this.login}>Log in</span>
                                </div>
                                </AvForm>

                            </CardBody>
                            </Card>
                        </Col>
                        </Row>
                    </div>
                  }
                  {this.state.count==1 &&
                    <div>
                      <Row className='mx-0'>
                        <Col lg='6' md='8' sm='12' xs='12' className='m-auto'>
                            <Card>
                            <NotificationContainer />
                            <CardBody>
                                <AvForm onValidSubmit={this.verifyCode} onInvalidSubmit={this.handleInvalidSubmit} className='group'>
                                <label className='pull-left'>Enter Code</label>
                                <AvField name="code" type="code" required className='form-input' />
                                <CardBody>

                                </CardBody>
                                <Button type="submit" color="primary w-100">Verify {hidden && <Spinner size="sm" role="status"></Spinner>}</Button>
                                <div className='pull-left'>
                                    <span type="button" className='text-primary' onClick={this.login}>Log in</span>
                                </div>
                                </AvForm>

                            </CardBody>
                            </Card>
                        </Col>
                        </Row>
                    </div>
                  }
                  {this.state.count==2 &&
                    <div>
                      <Row className='mx-0'>
                        <Col lg='6' md='8' sm='12' xs='12' className='m-auto'>
                            <Card>
                            <NotificationContainer />
                            <CardBody>
                                <AvForm onValidSubmit={this.newPassword} onInvalidSubmit={this.handleInvalidSubmit} className='group'>
                                <label className='pull-left'>Password</label>
                                <AvField name="password" type="password" onChange={this.onChange} required className='form-input' />
                                <label className='pull-left'>Confirm Password</label>
                                <AvField name="confirm_password" type="password" onChange={this.onChange} required className='form-input' />
                                <CardBody>

                                </CardBody>
                                <Button type="submit" color="primary w-100">Submit {hidden && <Spinner size="sm" role="status">
                                <span className="sr-only">Loading...</span></Spinner>}</Button>
                                <div className='pull-left'>
                                    <span type="button" className='text-primary' onClick={this.login}>Log in</span>
                                </div>
                                </AvForm>

                            </CardBody>
                            </Card>
                        </Col>
                        </Row>
                    </div>
                  }
          </div>
        </div>
      </body>
    );
  }
}

const mapStateToProps = state => {
  return { articles: state.USER_DATA };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addArticle: articles => dispatch(addArticle({ a: "articles" }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
