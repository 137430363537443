import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
//import { postNewJob } from '../../services/admin/Index';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table, Row, Col, Input,Button , Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { lowerCase } from "lodash";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import _ from "lodash";
import {createJob } from '../../services/admin/Index';
import history from '../../history';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import './style.css'



class PostNewJob extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.createJob = this.createJob.bind(this);
    // this.getMyLocation = this.getMyLocation.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      latitude: 0,
      longitude: 0,
      numPerson: '',
      address: '',
      userName: '',
      jobDescription: '',
      token: '',
      hidden: false,
      address: '',
      latLng: { lat: 40.748817, lng: -73.985428 }
    };
    
  }

  async componentWillMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    await this.setState({
      token: token})
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({latLng , address: address}) 
        )
      .catch(error => console.error('Error', error));
  };
  
  // getMyLocation() {
  //   const location = window.navigator && window.navigator.geolocation

  //   if (location) {
  //       location.getCurrentPosition((position) => {
  //           this.setState({
  //               latitude: position.coords.latitude,
  //               longitude: position.coords.longitude,
  //           })
  //       }, (error) => {
  //           this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
  //       })
  //   }

  // } 

  async createJob() {
    try{
      this.setState({hidden : true});
      const userInfo = JSON.parse(localStorage.getItem('userData'));
      const token = userInfo.token;
      if (this.state.startDate===this.state.endDate && this.state.numPerson>0 ){
        if(this.state.startTime < this.state.endTime){
          const body = {
              start_time: this.state.startDate+" "+this.state.startTime,
              end_time: this.state.endDate+" "+this.state.endTime,
              person: this.state.numPerson,
              address: this.state.address,
              lat: this.state.latLng.lat,
              lng: this.state.latLng.lng,
              user_name: this.state.userName,
              job_desc: this.state.jobDescription,
              token : token,
          }
          const jobCreated = await createJob(body);
          if (jobCreated.resStatus === 200) {
            NotificationManager.success('Job Created Successfully');
            this.setState({hidden : false});
            setTimeout(() => history.push('/customer/jobs'), 1000);
          } else {
            NotificationManager.error('Something went wrong');
            this.setState({hidden : false});
          }
        }else if(this.state.startTime > this.state.endTime){
          NotificationManager.error('Starting Time have to be earlier than ending time.');
          this.setState({hidden : false});  
        }
      }else if(this.state.startDate < this.state.endDate || this.state.numPerson>0){
        const body = {
          start_time: this.state.startDate+" "+this.state.startTime,
          end_time: this.state.endDate+" "+this.state.endTime,
          person: this.state.numPerson,
          address: this.state.address,
          lat: this.state.latLng.lat,
          lng: this.state.latLng.lng,
          user_name: this.state.userName,
          job_desc: this.state.jobDescription,
          token : token,
        }
        const jobCreated = await createJob(body);
        if (jobCreated.resStatus === 200) {
          NotificationManager.success('Job Created Successfully');
          this.setState({hidden : false});
          setTimeout(() => history.push('/customer/jobs'), 1000);
        } else {
          NotificationManager.error('Something went wrong');
          this.setState({hidden : false});
        }
      }else if(this.state.startDate > this.state.endDate){
        NotificationManager.error('Starting Date have to be earlier than ending date.');
        this.setState({hidden : false});
      }else if(this.state.numPerson < 0 || this.state.numPerson==0){
        NotificationManager.error('Number of Persons has to be grater then 0');
        this.setState({hidden : false});
      }
    }catch(e){
      throw(e);
    }
  }

  render() {
    const { allNotifications, isOpen , hidden } = this.state;
    const MapWrapper = withScriptjs(
        withGoogleMap(props => (
          <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: this.state.latLng.lat, lng: this.state.latLng.lng }}
            defaultOptions={{
              scrollwheel: false,
              styles: [
                {
                  featureType: "administrative",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#444444" }]
                },
                {
                  featureType: "landscape",
                  elementType: "all",
                  stylers: [{ color: "#f2f2f2" }]
                },
                {
                  featureType: "poi",
                  elementType: "all",
                  stylers: [{ visibility: "off" }]
                },
                {
                  featureType: "road",
                  elementType: "all",
                  stylers: [{ saturation: -100 }, { lightness: 45 }]
                },
                {
                  featureType: "road.highway",
                  elementType: "all",
                  stylers: [{ visibility: "simplified" }]
                },
                {
                  featureType: "road.arterial",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }]
                },
                {
                  featureType: "transit",
                  elementType: "all",
                  stylers: [{ visibility: "off" }]
                },
                {
                  featureType: "water",
                  elementType: "all",
                  stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
                }
              ]
            }}
          >
            <Marker position={{ lat: this.state.latLng.lat, lng: this.state.latLng.lng }} />
          </GoogleMap>
        ))
      );
    return (
      <GridContainer>
        <NotificationContainer />
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4 >Post New Job</h4>
              </Row>
            </CardHeader>
            <div>
              <CardBody>
                <div>
                  <div class="container">
                    <div class="row">
                      <div class="col m-auto">
                      < AvForm className='group' onValidSubmit={this.createJob}>
                      
                        <div className='d-flex'>
                          <Col >
                            <Row ><label className="pull-left">Job Start Time</label></Row>
                            <Row >
                              <Col lg='7' style={{ "min-width": "145px"}}>
                                <AvField style={{ "font-size": "0.9em", "min-width": "145px"}} 
                                  name="startDate" 
                                  type="date" 
                                  onChange={this.onChange} required className='form-input' /></Col>
                            
                              <Col lg='5' >
                                <AvField style={{ "font-size": "0.9em", "min-width": "110px"}}
                                name="startTime"
                                type="time"
                                onChange={this.onChange} required className='form-input' /></Col>
                            </Row>
                          </Col>
                        </div>
                        <div className='d-flex'>
                          <Col >
                            <Row ><label className="pull-left">Job End Time</label></Row>
                            <Row >
                              <Col lg="7" style={{ "min-width": "145px"}}>
                                <AvField style={{ "font-size": "0.9em", "min-width": "145px"}} 
                                name="endDate" 
                                type="date" 
                                onChange={this.onChange} required className='form-input' /></Col>
                            
                              <Col lg="5">
                                <AvField style={{ "font-size": "0.9em", "min-width": "110px"}} 
                                name="endTime" 
                                type="time" 
                                onChange={this.onChange} required className='form-input' /></Col>
                            </Row>
                          </Col>
                        </div>
                        <AvField name="numPerson" label="Person" value={0} type="number" onChange={this.onChange} required className='form-input' />
                        <AvField name="userName" label="User Name" type="text" onChange={this.onChange} required className='form-input' />
                        {/* <AvField name="address" label="Address" type="text" onChange={this.onChange} required className='form-input' /> */}
                        <AvField name="jobDescription" label="Job Description" type="text" onChange={this.onChange} required className='form-input' />
                        <div>
                          <Col >
                            <Row ><label className="pull-left">Address</label></Row>
                            <Row style={{width:"100%"}} >
                              <div style={{width:"100%"}}>
                                <PlacesAutocomplete
                                  value={this.state.address}
                                  onChange={this.handleChange}
                                  onSelect={this.handleSelect}
                                  >
                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                      <input
                                        {...getInputProps({
                                          placeholder: '',
                                          className: 'location-search-input',
                                        })}
                                      />
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                          
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? { backgroundColor: '#42a5f5', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                          return (
                                            <div className="input-suggestion"
                                              {...getSuggestionItemProps(suggestion, {
                                                
                                                style,
                                              })}
                                            >
                                            <i class="material-icons">location_on  </i> <span>{suggestion.description}</span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              </div>
                            </Row>
                          </Col>
                        </div>
                            <div class="container">
                            <div class="row">
                              <div class="col text-center">
                                <div className="mt-3">
                                  <Button type="submit" className=" mr-1" >
                                  {hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}
                                    Create Job</Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
          <Card className="shadow border-0">
            {/*API_Key should also be added in scripts /public/index.html*/}
            <MapWrapper
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgxymkaXaPiCDH7m3KHs5zTcWu01sSoeo"
              loadingElement={<div style={{ height: `100%`}} />}
              containerElement={
              <div
                style={{ height: `600px` }}
                className="map-canvas"
                id="map-canvas"
                />
                }
                mapElement={
              <div style={{ height: `100%`, borderRadius: "inherit" }} />
                }
            />
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

}
export default PostNewJob;