import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from 'reactstrap';

class Messages extends React.Component {

    
  render() {
    
    return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 >Messaging</h4>
              </CardHeader>
              <CardBody>
           
              </CardBody>
            </Card>
          </GridItem>
          
        </GridContainer>
      );
  }

}
export default Messages;