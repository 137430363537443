//Common
import _, { functions } from "lodash";
import PaymentModes from '././views/PaymentModes/paymentmode'
import Employess from '././views/Empolyees/employees';
import AllJobs from '././views/AllJobs/AllJobs';
import Complaints from '././views/Complaints/Complaints';
import Logout from '././views/Logout/Logout';
import Chat from '././views/Chat/Chat';
//Admin
import UpdateAdminProfile from '././views/UpdateProfile/updateprofileAdmin';
import Dashboard from "./views/Dashboard/Dashboard.js";
import User from '././views/User/user';
import Companies from '././views/Companies/companies'
import Settings from '././views/Settings/setting';
import Statements from '././views/Statements/Statement';
import PushNotification from '././views/PushNotification/PushNotification';
import PurchaseTokenAdmin from "views/PurchaseToken/PurchaseTokenAdmin";

//Customer
import CustomerDashboard from "./views/CustomerDashboard/CustomerDashboard.js";
import CustomerJobs from "./views/CustomerJobs/CustomerJobs";
import PostNewJob from '././views/PostNewJob/PostNewJob';
import UpdateCustomerProfile from '././views/UpdateProfile/updateprofileCustomer';
import CustomerOnGoingJobs from "./views/CustomerOnGoingJobs/CustomerOnGoingJobs"
import CustomerCompletedJobs from "./views/CustomerCompletedJobs/CustomerCompletedJobs"
//Company
import CompanyDashboard from "./views/CompanyDashboard/CompanyDashboard.js";
import PurchaseToken from './views/PurchaseToken/PurchaseToken';
import UpdateCompanyProfile from '././views/UpdateProfile/updateprofileCompany';
import CompanyPurcahsedJobs from "./views/CompanyPurchaseJobs/CompanyPurchaseJobs";
import CompanyAssignedJobs from "./views/CompanyAssignedJobs/CompanyAssignedJobs";
//Employee
import UpdateEmployeeProfile from '././views/UpdateProfile/UpdateEmployeeProfile';

export function camp(){
  const dashboardModules = JSON.parse(localStorage.getItem('dashboardModules'));
  const userInfo = JSON.parse(localStorage.getItem('userData'));
  let dashboardRoutes = []
  dashboardRoutes = dashboardModules && _.map(dashboardModules.data, function (route, key) {
      return {
        path: route.path ,
        name: route.title,
        icon: route.icon || 'chat',
        component: (route.title === 'Dashboard' && userInfo.data.user_type === 'admin' && Dashboard)  ||
          (route.title === 'Dashboard' && userInfo.data.user_type === 'company' && CompanyDashboard) ||
          (route.title === 'Dashboard' && userInfo.data.user_type === 'customer' && CustomerDashboard) ||
          (route.title === 'Companies' && Companies) ||
          (route.title === 'Users' && User) ||
          (route.title === 'Payments' && PaymentModes) ||
          (route.title === 'Employees' && Employess) ||
          (route.title === 'Push Messages' && PushNotification) ||
          (route.title === 'Settings' && Settings) || 
          (route.title === 'Complaints' && Complaints) || 
          (route.title === 'Statements' && Statements) || 
          (route.title === 'Logout' && Logout) ||
          (route.title === 'Post new Job' && userInfo.data.user_type === 'customer' && PostNewJob) ||
          (route.title === 'Purchase Token' && userInfo.data.user_type === 'company' && PurchaseToken) ||
          (route.title === 'Purchase Token' && userInfo.data.user_type === 'admin' && PurchaseTokenAdmin) ||
          (route.title === 'Update Profile' && userInfo.data.user_type === 'admin' && UpdateAdminProfile) ||
          (route.title === 'Update Profile' && userInfo.data.user_type === 'customer' && UpdateCustomerProfile) ||
          (route.title === 'Update Profile' && userInfo.data.user_type === 'employ' && UpdateEmployeeProfile) ||
          (route.title === 'Update Profile' && userInfo.data.user_type === 'company' && UpdateCompanyProfile) ||
          (route.title === 'Chats' && Chat) || 
          (route.title === 'Purchase Jobs' && userInfo.data.user_type === 'company' && CompanyPurcahsedJobs) ||
          (route.title === 'On Going Jobs' && userInfo.data.user_type === 'customer' && CustomerOnGoingJobs) ||
          (route.title === 'Completed Jobs' && userInfo.data.user_type === 'customer' && CustomerCompletedJobs) ||

          (route.title === 'Jobs' && userInfo.data && userInfo.data.user_type === 'admin' && AllJobs) ||
          (route.title === 'Jobs' && userInfo.data && userInfo.data.user_type === 'company' && AllJobs) ||
          (route.title === 'Assigned Jobs' && userInfo.data && userInfo.data.user_type === 'company' && CompanyAssignedJobs) ||
          (route.title === 'Jobs' && userInfo.data && userInfo.data.user_type === 'customer' && CustomerJobs),

        layout: (userInfo.data && userInfo.data.user_type === 'admin' && '/admin') ||
          (userInfo.data && userInfo.data.user_type === 'customer' && '/customer') ||
          (userInfo.data && userInfo.data.user_type === 'employ' && '/employ') ||
          (userInfo.data && userInfo.data.user_type === 'company' && '/company') , 
      };
    })
  return dashboardRoutes;
}


