import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";
import {
  getAllJobsByAdmin,
  getAdminDashboardStats,
} from "../../services/admin/Index";
import _ from "lodash";
import moment from "moment";
import { Row } from "reactstrap";
import { Table } from "reactstrap";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const [jobs, setAllJobs] = useState([]);
  const [stats, setStats] = useState([]);

  const renderRows = (allUserJobs) => {
    return (
      allUserJobs &&
      allUserJobs.data.map((user, key) => {
        {
          return (
            <tr key={key}>
              <td scope="row">{user.id} </td>
              <td scope="row">{user.user_name} </td>
              <td scope="row">{user.address} </td>
              <td scope="row">{user.person} </td>
              <td scope="row">{user.status} </td>
              <td scope="row">
                {moment(user.created_at).format("MMM Do YY")}{" "}
              </td>
            </tr>
          );
        }
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = JSON.parse(localStorage.getItem("userData"));
      const token = userInfo.token;
      const adminStats = await getAdminDashboardStats(token);
      setStats(adminStats.data);
      const allUserJobs = await getAllJobsByAdmin(1, token);
      setAllJobs(allUserJobs);
    };
    fetchData();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="warning" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">
                  {"Created Jobs : " + stats.created_jobs}
                </p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="success" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="success">
                  <Store />
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">
                  {"Revenue : $ " + stats.total_revenue}
                </p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="danger" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">
                  {"Completed Jobs : " + stats.completed_jobs}
                </p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ height: 140 }}>
            <CardHeader color="info" stats icon>
              <Row className="ml-1">
                <CardIcon style={{ height: 90 }} color="info">
                  <Accessibility />
                </CardIcon>
              </Row>
              <Row style={{ height: 40 }}>
                <p className="text-dark text-md ml-3">
                  {"Total Users : + " + stats.users}
                </p>
              </Row>
              <hr />
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart style={{ height: 290 }}>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Jobs Posted</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 25%
                </span>{" "}
                increase in today posted jobs.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart style={{ height: 290 }}>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Registerd Users</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 5%
                </span>{" "}
                increase.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 1 day ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart style={{ height: 290 }}>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Jobs</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 7%
                </span>{" "}
                increase.
              </p>{" "}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 1 day ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                )
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem>
        */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <Row className="ml-2">
                <h4 className={classes.cardTitleWhite}>Latest Jobs</h4>
              </Row>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <Table bordered responsive className="table table-bordered table-hover">
                <thead className="thead-dark shadow">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Person</th>
                    <th>Job Status</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#f2f2f2" }}>
                  {jobs && jobs.data && renderRows(jobs)}
                  {jobs && _.size(jobs.data) === 0 && (
                    <tr>
                      {" "}
                      <td scope="row">{"No Records Found"} </td>{" "}
                    </tr>
                  )}
                </tbody>
              </Table>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
