import React, { useRef } from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import { Button } from "reactstrap";
// import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Hero from "../components/hero/TwoColumnWithInput.js";
import LogoImage from "../images/logo-light.svg";
import Features from "../components/features/ThreeColWithSideImage.js";
import MainFeature from "../components/features/TwoColWithButton.js";
import MainFeature2 from "../components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "../components/features/TwoColWithSteps.js";
import FeatureWithStepsCompany from "../components/features/TwoColWithStepsCompany.js";
import Pricing from "../components/pricing/ThreePlans.js";
import Testimonial from "../components/testimonials/TwoColumnWithImageAndRating.js";
// import FAQ from "../components/faqs/SingleCol.js";
import GetStarted from "../components/cta/GetStarted";
import Footer from "../components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "../images/hero-screenshot-1.svg";
import macHeroScreenshotImageSrc from "../images/hero-screenshot-2.png";
import companySignupImage from "../images/screenimage1.svg";
import employeeSignupImage from "../images/screenimage2.svg";
import prototypeIllustrationImageSrc from "../images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { useState } from "react";

const SaaSProductLandingPage = () => {
  const testimonialRef = useRef(null);
  const featureRef = useRef(null);
  const newRef = useRef(null);
  const employeeRef = useRef(null);
  const topRef = useRef(null);

  const scrollToTestimonial = () => {
    testimonialRef.current.scrollIntoView({ behavior: "smooth" });
    setShowMenu(false);
  };

  const scrollToFeature = () => {
    featureRef.current.scrollIntoView({ behavior: "smooth" });
    setShowMenu(false);
  };
  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    setShowMenu(false);
  };

  const scrollToEmployee = () => {
    employeeRef.current.scrollIntoView({ behavior: "smooth" });
    setShowMenu(false);
  };
  const scrollToNew = () => {
    newRef.current.scrollIntoView({ behavior: "smooth" });
    setShowMenu(false);
  };
  const [showMenu, setShowMenu] = useState(false);
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
  const LogoImg = tw.img`w-48 h-20`;

/// ------practice setup --------------------







// Function to find the longest strings in an array
// const longest_string = (str_ara) => {
//   let max = str_ara[0].length; // Initialize max length with the length of the first string
//   str_ara.map(v => max = Math.max(max, v.length)); // Update max by finding the maximum length in the array
//   const result = str_ara.filter(v => v.length == max); // Filter strings that have the maximum length
//   return result; // Return the result array
// };

// // Example usage
// console.log(longest_string(['a', 'aa', 'aaa', 'aaaaa', 'acnacnjs']));



function longestString(strings) {
  let maxLength = strings[0].length; // Assume the first string has the maximum length
  
  // Loop through all strings to find the maximum length
  for (let i = 1; i < strings.length; i++) {
    const currentLength = strings[i].length;
    if (currentLength > maxLength) {
      maxLength = currentLength; // Update maxLength if a longer string is found
    }
  }
  
  // Filter strings that have the maximum length
  const longestStrings = strings.filter(str => str.length === maxLength);
  
  return longestStrings; // Return the array of longest strings
}

// Example usage
console.log(longestString(['a', 'aa', 'aaa', 'aaaaa', 'alif allah']));















//--------- ------------------------
  return (
    <>
      {/* <AnimationRevealPage> */}
      <header className="fixed w-full z-20">
        <nav className="bg-white text-black  border-gray-200 py-2.5 dark:bg-gray-900">
          <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto cursor-pointer">
            <Link to="/">
              <LogoContainer>
                <LogoImg src={LogoImage} />
              </LogoContainer>
            </Link>
            <div className="flex items-center lg:order-2">
              <Link to="/login">
                <Button color="danger" className="px-3 py-2 rounded-lg ">
                  Log in
                </Button>
              </Link>
              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
                onClick={() => setShowMenu(!showMenu)}
              >
                <span className="sr-only"></span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <div
              className={`items-center justify-between w-full ${
                showMenu ? "block" : "hidden"
              } lg:flex lg:w-auto lg:order-1`}
            >
              <div id="mobile-menu-2">
                
                <ul className="flex flex-col mt-4 font-medium lg:flex-row  lg:mt-0">
                  <li>
                    <a
                      // href="÷head"
                      className="block py-2 pl-3 pr-4 bg-primary-500 rounded lg:bg-transparent lg:text-primary-500 lg:p-0 dark:text-white"
                      aria-current="page"
                      onClick={scrollToTop}
                      style={{ Color: "black !important" }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      // href="#Features"
                      onClick={scrollToFeature}
                      // onClick={() => setShowMenu(false)}
                      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-indigo-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      // href="#new"
                      onClick={scrollToNew}
                      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-indigo-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 " 
                    >
                      Company
                    </a>
                  </li>
                  <li>
                    <a
                      // href="#Employee"
                      onClick={scrollToEmployee}
                      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-indigo-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Employee
                    </a>
                  </li>
                  <li>
                    <a
                      // href="#Testimonials"
                      onClick={scrollToTestimonial}
                      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-indigo-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Testimonials
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <br ref={topRef} />
      <div id=''><Hero /></div>
      
      <br ref={featureRef} />
      <Features
        ref={featureRef}
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We Offer Outstanding <HighlightedText>Services.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Our Process</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <br ref={newRef}/>
      <FeatureWithStepsCompany
        subheading={<Subheading>Get Started.</Subheading>}
        heading={
          <>
            Registration as <HighlightedText>Company.</HighlightedText>
          </>
        }
        textOnLeft={true}
        imageSrc={companySignupImage}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <br ref={employeeRef} />
      <FeatureWithSteps
        subheading={<Subheading>Get Started.</Subheading>}
        heading={
          <>
            Registration as <HighlightedText>Employee.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={employeeSignupImage}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Our VALUES</Subheading>}
        heading={
          <>
            Professionalism{" "}
            <HighlightedText> Responsiveness & Trust</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />

      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [
              "30 Templates",
              "7 Landing Pages",
              "12 Internal Pages",
              "Basic Assistance",
            ],
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: [
              "60 Templates",
              "15 Landing Pages",
              "22 Internal Pages",
              "Priority Assistance",
            ],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
              "90 Templates",
              "27 Landing Pages",
              "37 Internal Pages",
              "Personal Assistance",
            ],
          },
        ]}
      />
      <br ref={testimonialRef} />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc.",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC",
          },
        ]}
      />
      {/* <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "How long do you usually support an standalone template for ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Is there a subscribption service to get the latest templates ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Are the templates compatible with the React ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Do you really support Internet Explorer 11 ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
        ]}
      /> */}
      <GetStarted />
      <Footer />
      {/* </AnimationRevealPage> */}
    </>
  );
};

export default SaaSProductLandingPage;
