// import React, { useState } from "react";
// import styled from "styled-components";
// import tw from "twin.macro";
// //eslint-disable-next-line
// import { withRouter } from "react-router-dom";
// import { css } from "styled-components/macro";

// // import Header from "../headers/light.js";

// import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import DesignIllustration from "../../images/design-illustration-2.svg";
// import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

// const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
// const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
// const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

// const Heading = tw.h1`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-4xl text-gray-900 leading-tight`;
// const Heading1 = tw.h1`font-bold text-2xl md:text-2xl lg:text-2xl xl:text-2xl text-gray-900 leading-tight`;
// const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
// const Paragraph1 = tw.p`my-2 lg:my-3 xl:text-sm`;


// const Actions = styled.div`
//   ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
//   input {
//     ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
//   }
//   button {
//     ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
//   }
// `;

// const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// // Random Decorator Blobs (shapes that you see in background)
// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
// `;

// const CustomersLogoStrip = styled.div`
//   ${tw`mt-6 lg:mt-6`}
//   p {
//     ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
//   }
//   img {
//     ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
//   }
// `;

// export default withRouter((props) => {
//   const [email, setEmail] = useState("");

//   const { roundedHeaderButton } = props;

//   const handleClick = () => {
//     console.log("event", email);
//     localStorage.setItem("email", email);
//     props.history.push({
//       pathname: "/user-login",
//       state: { foo: "bar" },
//     });
//   };
//   return (
//     <>
//       {/* <Header roundedHeaderButton={roundedHeaderButton} /> */}
//       {/* <Navbar/> */}
//       <Container>
//         <TwoColumn>
//           <LeftColumn>
//             <Heading>
//               Welcome to <span tw="text-primary-500">GPSRecruiter.com</span>
//             </Heading>
//             <Heading1> Your Career Advancement Hub!</Heading1>
//             <Paragraph>
//               Join GPSRecruiter.com for tailored job opportunities and
//               connections with top employers seeking professionals like you. Our
//               platform supports career growth, expert recruiter assistance, and
//               an extensive network of skilled contractors.
//             </Paragraph>
//             <Actions>
//               <input
//                 type="text"
//                 onChange={(e) => setEmail(e.target.value)}
//                 name="email"
//                 placeholder="Your E-mail Address"
//               />
//               <button onClick={handleClick}>Get Started</button>
//             </Actions>
//             <br/>
//             <Heading1><span tw="text-primary-500"><u>Start By</u></span></Heading1>
//             <Paragraph1>
//               <b>Creating your profile:</b> Add your skills, experience, and
//               certifications.
//             </Paragraph1>
//             <Paragraph1>
//             <b>Applying for jobs:</b> Our recruiters will help you find the perfect
//             match.
//             </Paragraph1>
//             <Paragraph1>
//             <b>Connecting with contractors:</b> Browse our database for your
//             ideal project partner.
//             </Paragraph1>
//             <Heading1>
//               Elevate your career with <span tw="text-primary-500">GPSRecruiter.com</span> your trusted partner for professional success.
//             </Heading1>
//             <CustomersLogoStrip>
//               <p>Our TRUSTED Customers</p>
//               <img src={CustomersLogoStripImage} alt="Our Customers"/>
//             </CustomersLogoStrip>
//           </LeftColumn>
//           <RightColumn>
//             <IllustrationContainer>
//               <img
//                 tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
//                 src={DesignIllustration}
//                 alt="Design Illustration"
//               />
//             </IllustrationContainer>
//           </RightColumn>
//         </TwoColumn>
//         <DecoratorBlob1 />
//       </Container>
//     </>
//   );
// });





import React from "react";
// import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
// import { css } from "styled-components/macro";
import styled, { css } from "styled-components";
// import Header from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

const Container = tw.div`relative px-8 sm:px-8 md:px-16 lg:px-24 xl:px-32`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} /> */}
      {/* <Navbar/> */}
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
            Welcome to <span tw="text-primary-500">Gps Recruiter.</span>
            </Heading>
            <Paragraph>
            Your premier destination for connecting talent with opportunity based on geolocation. Our platform bridges the gap between job seekers and recruiters across all industries, offering a wide array of career possibilities. Whether you're seeking your next challenge or the perfect candidate, GPS Recruiter is your navigator to success.
            </Paragraph>
            <Actions>
              <input type="text" placeholder="Your E-mail Address" />
              <button>Get Started</button>
            </Actions>
            <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};

