// import React from "react";
// import styled from "styled-components";
// import tw from "twin.macro";
// //eslint-disable-next-line
// import { css } from "styled-components/macro";
// import {
//   SectionHeading,
//   Subheading as SubheadingBase,
// } from "../../components/misc/Headings.js";
// import { SectionDescription } from "../../components/misc/Typography.js";
// // import defaultCardImage from "../../../images/shield-icon.svg";

// import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// // import SupportIconImage from "../../images/support-icon.svg";
// // import ShieldIconImage from "../../images/shield-icon.svg";
// import CustomizeIconImage from "../../images/customize-icon.svg";
// import FastIconImage from "../../images/fast-icon.svg";
// import ReliableIconImage from "../../images/reliable-icon.svg";
// // import SimpleIconImage from "../../images/simple-icon.svg";

// const Container = tw.div`relative`;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
// `;
// const Subheading = tw(SubheadingBase)`mb-4`;
// const Heading = tw(SectionHeading)`w-full`;
// const Description = tw(SectionDescription)`w-full text-center`;

// const VerticalSpacer = tw.div`mt-10 w-full`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
// `;

// const Card = styled.div`
//   ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
//   .imageContainer {
//     ${tw`border text-center rounded-full p-5 flex-shrink-0`}
//     img {
//       ${tw`w-6 h-6`}
//     }
//   }

//   .textContainer {
//     ${tw`sm:ml-4 mt-4 sm:mt-2`}
//   }

//   .title {
//     ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
//   }

//   .description {
//     ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
//   }
// `;

// const DecoratorBlob = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
// `;

// export default ({
//   cards = null,
//   heading = "Amazing Features",
//   subheading = "Features",
//   description = "",
// }) => {
//   /*
//    * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
//    *  1) imageSrc - the image shown at the top of the card
//    *  2) title - the title of the card
//    *  3) description - the description of the card
//    *  If a key for a particular card is not provided, a default value is used
//    */

//   const defaultCards = [
//     {
//       imageSrc: CustomizeIconImage,
//       title: "Account management",
//       description:
//         "Our online account management system allows clients to easily manage their security personnel needs, including hiring, deployment, and billing.",
//     },
//     {
//       imageSrc: ReliableIconImage,
//       title: "International deployment",
//       description:
//         "Our security guards can be deployed to different countries, ensuring that our clients' security needs are met wherever they go.",
//     },
   
//     {
//       imageSrc: CustomizeIconImage,
//       title: "Customized solutions",
//       description:
//         "We work closely with our clients to understand their unique security needs and develop customized security solutions that meet those needs.",
//     }, {
//       imageSrc: FastIconImage,
//       title: "Security guard hire",
//       description:
//         "We offer trained and experienced security guards for hire to businesses and individuals worldwide",
//     },
//     { imageSrc: ReliableIconImage, title: "Reliable" },
//     { imageSrc: FastIconImage, title: "Fast" },
//   ];

//   if (!cards) cards = defaultCards;

//   return (
//     <Container>
//       <ThreeColumnContainer>
//         {subheading && <Subheading>{subheading}</Subheading>}
//         <Heading>{heading}</Heading>
//         {description && <Description>{description}</Description>}
//         <VerticalSpacer />
//         {cards.map((card, i) => (
//           <Column key={i}>
//             <Card>
//               <span className="imageContainer">
//                 <img src={card.imageSrc} alt="" />
//               </span>
//               <span className="textContainer">
//                 <span className="title">{card.title || "Fully Secure"}</span>
//                 <p className="description">
//                   {card.description ||
//                     "User Friendly reliable and secure. Easy to use for all Customers, Employees and Companies"}
//                 </p>
//               </span>
//             </Card>
//           </Column>
//         ))}
//       </ThreeColumnContainer>
//       <DecoratorBlob />
//     </Container>
//   );
// };




import React from "react";
// import styled from "styled-components";
import tw from "twin.macro";
import styled, { css } from "styled-components";
// import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../components/misc/Headings.js";
import { SectionDescription } from "../../components/misc/Typography.js";
// import defaultCardImage from "../../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// import SupportIconImage from "../../images/support-icon.svg";
// import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
// import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description = "",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */




  const defaultCards = [
    {
      imageSrc: CustomizeIconImage,
      title: "Comprehensive Management",
      description:
        "Our user-friendly online system allows clients to seamlessly manage their recruitment needs across a range of sectors including Accounting, IT, Construction, Healthcare, and more—covering hiring, deployment, and billing.",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Global Reach",
      description:
        "We connect professionals in various fields to opportunities worldwide. Whether you're in Engineering, Education, Marketing, or beyond, our network meets your career aspirations globally.",
    },
    
    
    {
      imageSrc: CustomizeIconImage,
      title: "Tailored Solutions",
      description:
        "Understanding the unique requirements of diverse industries, from Creative Arts to Finance, we collaborate with clients to provide bespoke recruitment solutions for every professional arena.",
    },
    {
      imageSrc: FastIconImage,
      title: "Diverse Opportunities",
      description:
        "Our platform offers access to an array of positions, whether you're seeking a role in Legal Services, Hospitality, Retail, or Security Guard services, we've got you covered.",
    },
    { imageSrc: ReliableIconImage, title: "Reliability and Ease" },
    { imageSrc: FastIconImage, title: "Efficient and User-Friendly",
     description:
       "Speed and reliability are at the heart of our service. We streamline the recruitment process for sectors like Environmental Science, Real Estate, and Education, ensuring quick and secure hiring." 
      },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Our platform is designed for simplicity and security, ensuring a smooth experience for job seekers and employers alike in industries such as Technology, Manufacturing, and Logistics."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
