
import { ADD_ARTICLE } from "../constants/action-types";

const initialState = {
  USER_DATA: []
};

function rootReducer(state = initialState, action) {
  if (action.type === ADD_ARTICLE) {
    state.USER_DATA.push(action.payload);
  }
  return state;
// if (action.type === ADD_ARTICLE) {
//     return Object.assign({}, state, {
//       articles: state.articles.concat(action.payload)
//     });
//   }
//   return state;
}

export default rootReducer;