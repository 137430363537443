import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { sendNotification } from '../../services/admin/Index';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table, Row, Col, Input,Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { lowerCase } from "lodash";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import _ from "lodash";
import {Spinner} from 'reactstrap';

class PushNotification extends React.Component {
  constructor(props) {
    super(props);
    this.send=this.send.bind(this);
    this.onChange =  this.onChange.bind(this);

    this.state = {
      allNotifications : [],
      user_type: '',
      message: '',
      token: '',
      hidden: false
    };
    
  }

  async componentWillMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    await this.setState({
      allNotifications : [],
      user_type: '',
      message: '',
      token: token,
      hidden: false
    })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  async send(){
    this.setState({hidden:true})
    const type = this.state.user_type
    if(type.toLowerCase()=='customer'){
      await this.setState({user_type : 'customer'})
    }else if(type.toLowerCase()=='company'){
      await this.setState({user_type : 'company'})
    }else if(type.toLowerCase()=='employee'){
      await this.setState({user_type : 'employ'})
    }
    const body = {
      type: this.state.user_type,
      message : this.state.message,
      token : this.state.token
    }
    console.log(body)
    const response = await sendNotification(body);
    console.log(response)
    if (response.status == 200) {
      this.componentWillMount()
      NotificationManager.success('Updated Succesfuly');
    } else {
      NotificationManager.error('Something went wrong');
    }
  }

  render() {
    const { allNotifications, isOpen } = this.state;
    return (
      <GridContainer>
        <NotificationContainer />
          <Card style={{"min-width":"300px"}}>
            <CardHeader color="primary">
              <Row className="ml-2" >
                <h4 >Push Notifications</h4>
              </Row>
            </CardHeader>
            <CardBody>
                <Row >
                  <Col className="mt-2" xs={4}>
                    <Row className="ml-1">
                      <div>
                        <strong> <h5 className="ml-2 text-dark">Sent to:</h5></strong>
                      </div>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <AvForm >
                      <AvField type="select" name="user_type" value={this.state.user_type} onChange={this.onChange}>
                        <option>Account Type</option>
                        {/* <option>Admin</option> */}
                        <option>Company</option>
                        <option>Customer</option>
                        <option>Employee</option>
                      </AvField>
                    </AvForm>
                  </Col>
                </Row>

                <Row >
                  <Col className="mt-2" xs={4}>
                    <Row className="ml-1">
                      <div>
                        <strong> <h5 className="ml-2 text-dark">Message:</h5></strong>
                      </div>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <AvForm>
                    <AvField name="message" placeholder="Push Message" type="input" value={this.state.message} onChange={this.onChange} required className='form-input' />
                    </AvForm>

                  </Col>
                </Row>

                <div class="container">
                  <div class="row">
                    <div class="col text-center">
                      {/* <a className="btn btn-light mr-2" onClick={this.send}>
                      <i className="fa fa-bell-o mr-2 text-info"></i> Send Notification</a> */}
                      <Button className="fa fa-bell-o" color="primary" onClick={this.send}> Send Notification{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</Button>
                    </div>
                  </div>
                </div>

            </CardBody>
          
          </Card>
          <Card style={{"min-width":"300px"}}>
            <CardHeader color="primary">
              <Row className="ml-2" >
                <h4 >Notifications History</h4>
              </Row>
            </CardHeader>
            <CardBody>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Send To</th>
                    <th>Message</th>
                    <th>Condition</th>
                    <th>Sent On</th>
                  </tr>
                </thead>
                <tbody>
                                    {this.state.allNotifications && _.map(this.state.allNotifications.data, function (notif, key) {
                                        {
                                            return <tr key={key}>
                                                <td scope="row">{notif}  </td>
                                                <td scope="row">{notif}  </td>
                                                <td scope="row">{notif}  </td>
                                                <td scope="row">{notif}  </td>
                                                <td scope="row">{notif}  </td>
                                                <td scope="row">{notif}  </td>
                                            </tr>
                                        }

                                    })
                                    }
                                    {allNotifications && _.size(allNotifications.data) === 0 && <td scope="row">{"No Records Found"}  </td>

                                    }

                                </tbody>
                {/* <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Customer</td>
                    <td>hi</td>
                    <td>Active</td>
                    <td>week ago</td>

                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Company</td>
                    <td>test</td>
                    <td>Active</td>
                    <td>week ago</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Employee</td>
                    <td> Notifications</td>
                    <td>Active</td>
                    <td>week ago</td>                    
                  </tr>
                </tbody> */}
              </Table>

            </CardBody>
          </Card>

      </GridContainer>
    );
  }

}
export default PushNotification;