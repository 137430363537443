import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from 'reactstrap';
import { getAllPurcahsedJobsByCompany } from '../../services/admin/Index';
import _ from "lodash";
import scroll from './scroll.css'
import ReactTooltip from "react-tooltip";
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Button, Spinner, Pagination, PaginationItem, PaginationLink, Input } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { auth, firestore } from "../../firebase/firebase";
import history from '../../history';

class CompanyPurchaseJobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime: '',
            rating: 0,
            hover: 0,
            endTime: '',
            allUserJobs: '',
            latitude: '',
            longitude: '',
            pagesCount: 0,
            currentPage: 0,
            tooltipOpen: false,
            isOpen: false,
            showProgress: true,
            token: '',
            modalcount: 0,
            hidden : false,
            sendmessage : 0,
            user_email: "",
            senderemail : '',
            message : ''
        };
        this.showModel = this.showModel.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.showMessageModal = this.showMessageModal.bind(this);
    }

    async componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        const token = userInfo.token;
        const email = userInfo.data.email;
        const allUserJobs = await getAllPurcahsedJobsByCompany(this.state.currentPage+1,token);
        if(allUserJobs.data.length!=0){
            await this.setState({ 
                allUserJobs: allUserJobs,
                pagesCount: Math.ceil(allUserJobs.total/allUserJobs.data.length),
                showProgress: false,
                token : token,
                hidden : true,
                user_email : email
            })
        }else{
            await this.setState({ 
                allUserJobs: allUserJobs,
                showProgress: false,
                token : token,
                hidden : true,
                user_email : email
            })
        }
    }

    async handleClick(e, index) {
        e.preventDefault();
        const allUserJobs = await getAllPurcahsedJobsByCompany(index+1,this.state.token);
        this.setState({
          currentPage: index,
          allUserJobs:allUserJobs
        })
    }

    async showModel(job) {
        if(job.status=="done"){
            await this.setState({ modalcount: 1,isOpen: true, job });
        }else{
            await this.setState({ modalcount: 1,isOpen: true, job });
        }
    };
    
    async closeModal() {
        await this.setState({ isOpen : !this.state.isOpen, company : "" , sendmessage : 0 , modalcount : 0 });
    };

    async showMessageModal(){
        try {
            const { job } = this.state;
            const userExists = await this.userExists(job.user_id);
            if (userExists) {
                const chatExists = await this.chatExists();
                try {
                    if(!chatExists){
                        await this.setState({sendmessage: 1, modalcount:0 })
                    }else{
                        NotificationManager.info("Chat Exist");
                        setTimeout(async() => await history.push("/company/chats"), 1000);
                    } 
                } catch (e) {
                  console.log(e);
                }
            }else {
                NotificationManager.error("User Is Not Exists Please Check It");
            }
        } catch (e) {
            throw (e);
        }
        
    }

    async sendMessage() {
        try {
            const { user_email, senderemail, message } = this.state;
            if (message != ""){
                this.createChat(user_email,senderemail,message)
                NotificationManager.success("Message Sent Successfully!");
                this.closeModal()
                this.componentDidMount()
            }else{
                NotificationManager.error("Write something to send!");
            }
        } catch (e) {
            NotificationManager.error(e);
            throw (e);
        }
    }

    userExists = async (customer) => {
        const userSnapshot = await firestore.collection("users").get();
        try {
            let count = [];
            userSnapshot.docs
            .map((docs) => count.push(docs.data()))
            const countlen = count.length
            let found = false
            for(var i = 0; i < countlen; i++) {
                if (count[i].user_id == customer) {
                    found = true
                    await this.setState({senderemail : count[i].email})
                    break;
                }
            }
            return found;
        } catch (e) {
          console.log(e);
          NotificationManager.error('Something went wrong');
        }
    };

    createChat = async (userEmail, senderEmail, msg) => {
        const docId = await this.buildId();
        const time = new Date().toLocaleTimeString("en-US", {
          hour: "numeric",
          hour12: true,
          minute: "numeric",
        });
        var today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const timestamp = Date.now();
        
        await firestore
            .collection("chats")
            .doc(docId)
            .set({
              docid: docId,
              time: timestamp,
              users: [userEmail, senderEmail],
              typing: [],
              messages: [
                {
                  message: msg,
                  sender: userEmail,
                  time: time+" "+date,
                  type: "text",
                },
              ],
        });
    };

    buildId = async () => {
        return [this.state.senderemail, this.state.user_email].sort().join(":");
    };

    chatExists = async () => {
        const docid = await this.buildId();
        const chat = await firestore.collection("chats").doc(docid).get();
        return chat.exists;
    };

    renderResultRows(allUserJobs) {
        return allUserJobs && this.state.allUserJobs.data.map((job, key) => {
          {
            return <tr key={key} >
              <td scope="row">{job.id}  </td>
              <td scope="row">{job.user_name}  </td>
              <td scope="row">{job.person}  </td>
              <td scope="row">{job.job_desc}  </td>
              <td><span>
                <div className="col-md-6 col-md-offset-6">
                    <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showModel.bind(this, job)}> 
                        <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
                <ReactTooltip />
              </span>
              
              </td>
            </tr>
          }
        })
      }

  render() {
    const { allUserJobs, isOpen, job, hidden } = this.state;
    return (
      <GridContainer>
        <NotificationContainer />
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{"min-width":"270px"}}>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4>Purchased Jobs</h4>
              </Row>
              <Row className="ml-2">
                <p>All Purchases Jobs record</p>
              </Row>
            </CardHeader>
            {!hidden &&
              <CardBody>
                <div style={{
                  position: 'absolute', left: '50%', top: '10%',
                }} xs={12}></div>
              </CardBody>
            }
            {hidden && <CardBody>
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Person</th>
                            <th>Job Description</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allUserJobs && this.renderResultRows(allUserJobs)
                        }
                        {allUserJobs && _.size(allUserJobs.data) === 0 && <tr><td scope="row">{"No Records Found"}  </td></tr>
                        }

                    </tbody>
                </Table>
                <nav>
                    <Pagination className="pull-right mt-3" size="md" >
                        <PaginationItem disabled={this.state.currentPage <= 0}>
                            <PaginationLink
                                    onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                                    previous
                                    href="#"
                                />
                        </PaginationItem>
                        {[...Array(this.state.pagesCount)].map((page, i) => 
                            <PaginationItem active={i === this.state.currentPage} key={i}>
                                <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                {i+1}
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>
                            <PaginationLink
                                    onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                                    next
                                    href="#"
                                    />
                        </PaginationItem>
                    </Pagination>
                </nav>
              <Modal isOpen={isOpen} toggle={this.closeModal}>
                {this.state.modalcount == 1 && <div>
                    <ModalHeader ><strong>Job details</strong></ModalHeader>
                    <ModalBody>
                                <Col lg='12' md='12' sm='12' xs='12'>
                                <div className='d-flex bg-light mb-2'>
                                <Col lg='12' md='8' sm='12' xs='12'>
                                    <span><strong className="text-dark">User Name :</strong> {job && job.user_name}</span>
                                </Col>
                                </div>
                                <div className='d-flex bg-light mb-2'>
                                <Col lg='12' md='8' sm='12' xs='12'>
                                    <span><strong className="text-dark">Email :</strong> {job && job.email}</span>
                                </Col>
                                </div>
                                <div className='d-flex bg-light mb-2'>
                                <Col lg='12' md='8' sm='12' xs='12'>
                                    <span><strong className="text-dark">Phone :</strong> {job && job.phone}</span>
                                </Col>
                                </div>
                                <div className='d-flex bg-light mb-2'>
                                <Col lg='12' md='8' sm='12' xs='12'>
                                    <span><strong className="text-dark">Person :</strong> {job && job.person}</span>
                                </Col>
                                </div>
                                <div className='d-flex bg-light mb-2'>
                                <Col lg='12' md='12' sm='12' xs='12'>
                                    <span><strong className="text-dark text-justify">Job Description :</strong> {job && job.job_desc}</span>
                                </Col>
                                </div>
                                <div className='d-flex bg-light mb-2'>
                                <Col lg='12' md='8' sm='12' xs='12'>
                                    <span><strong className="text-dark">Address :</strong> {job && job.address}</span>
                                </Col>
                                </div>
                                <div className='d-flex bg-light mb-2'>
                                                <Col lg='12' md='12' sm='12' xs='12'>
                                                    <span><strong className="text-dark"> Start Time :</strong>{job && job.start_time}</span>
                                                </Col>
                                            </div>
                                            <div className='d-flex bg-light mb-2'>
                                                <Col lg='12' md='12' sm='12' xs='12'>
                                                    <span><strong className="text-dark"> End Time :</strong>{job && job.end_time}</span>
                                                </Col>
                                            </div>
                                </Col>
                    </ModalBody>
                </div>}
                {this.state.sendmessage == 1 && <div>
                    <ModalHeader ><strong>Send Message</strong></ModalHeader>
                    <ModalBody>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <Row>
                                <Input name="message" 
                                        className="ml-2" 
                                        type="textarea" 
                                        rows={3} 
                                        onChange={this.onChange} />
                            </Row>
                            <Row> <br></br>
                            </Row>
                            <Row>
                                <Button className="align-center" 
                                        style={{"margin-left":"auto","margin-right":"auto"}} 
                                        color="primary"
                                        onClick={this.sendMessage}>Send</Button>
                            </Row>
                        </Col>
                    </ModalBody>
                </div>}
                <ModalFooter>
                    <Button color="primary" onClick={this.showMessageModal}>Message</Button>
                    <Button color="secondary" onClick={this.closeModal}>Close</Button>
                </ModalFooter>
              </Modal>
            </CardBody>
            }
          </Card>
        </GridItem>

      </GridContainer>
    );
  }

}
export default CompanyPurchaseJobs;