import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { getAllJobsbyUser, getAllCompanies, assignJobtoUser } from '../../services/admin/Index';
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import 'date-fns';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Table, Button, Spinner , Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import ReactTooltip from "react-tooltip";
import { Grid, Cell } from 'react-mdl';
import Tooltip from '@material-ui/core/Tooltip';
import Rating from '@material-ui/lab/Rating';
import { auth,firestore } from "../../firebase/firebase";
import history from '../../history';


class CustomerJobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime: '',
            rating: 0,
            hover: 0,
            endTime: '',
            allUserJobs: '',
            allCompanies: [],
            latitude: '',
            longitude: '',
            pagesCount: 0,
            currentPage: 0,
            pagesCount_company: 0,
            currentPage_company: 0,
            tooltipOpen: false,
            isOpen: false,
            showProgress: true,
            token: '',
            modalcount: 0,
            job_detail: 0,
            hidden : false,
            sendmessage : 0,
            user_email: "",
            senderemail : '',
            message : ''
        };
        this.onChange = this.onChange.bind(this);
        this.showModel = this.showModel.bind(this);
        this.showDetailModel = this.showDetailModel.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getMyLocation = this.getMyLocation.bind(this);
        this.startTime = this.startTime.bind(this);
        this.endTime = this.endTime.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickCompany = this.handleClickCompany.bind(this);
        this.assignJob = this.assignJob.bind(this);
        this.sendMessage = this.sendMessage.bind(this);

    }

    async componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        const token = userInfo.token;
        const allUserJobs = await getAllJobsbyUser(this.state.currentPage + 1, token);
        if (allUserJobs.data.length != 0) {
            this.setState({
                allUserJobs: allUserJobs,
                //pagesCount: Math.ceil(allUserJobs.total/allUserJobs.data &&  allUserJobs.data.length || 1),
                pagesCount: Math.ceil(allUserJobs.total / allUserJobs.data.length),
                showProgress: false,
                token: token,
                user_email: userInfo.data.email
            })
        } else {
            this.setState({
                allUserJobs: allUserJobs,
                showProgress: false,
                token: token,
                user_email: userInfo.data.email
            })
        }
        this.getMyLocation();
    }

    async onChange(e){
        await this.setState({[e.target.name]:e.target.value})
    }

    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                })
            }, (error) => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }

    }

    async handleClick(e, index) {
        e.preventDefault();
        const allUserJobs = await getAllJobsbyUser(index + 1, this.state.token);
        this.setState({
            currentPage: index,
            allUserJobs: allUserJobs
        })
    }

    async handleClickCompany(e, index) {
        e.preventDefault();
        const allListCompanies = await getAllCompanies(index + 1, this.state.token);
        this.setState({
            currentPage_company: index,
            allCompanies: allListCompanies
        })
    }

    endTime(event) {
        this.setState({ endTime: event.target.value })
    }
    startTime(event) {
        this.setState({ startTime: event.target.value })
    }

    async showModel(job) {
        if (job.status == "done") {
            await this.setState({ modalcount: 2, isOpen: true, job });
        } else if (job.status == "created") {
            await this.setState({ modalcount: 1, allCompanies: job.bids, isOpen: true, job });
        }
    }
    async showDetailModel(job) {
            await this.setState({ job_detail: 1, isOpen: true, job });
    }

    closeModal() {
        this.setState({ modalcount:0 ,job_detail:0 ,isOpen: !this.state.isOpen , company: "" , sendmessage:0 });
    }

    async assignJob(company) {
        try {
            this.setState({hidden : true});
            const userInfo = JSON.parse(localStorage.getItem('userData'));
            const user_token = userInfo.token
            const body = {
                user_id: company.id,
                job_id: this.state.job.id,
                token: user_token
            }
            const response = await assignJobtoUser(body);
            if (response.status == 200) {
                NotificationManager.success('Assigned Succesfuly');
                await this.componentDidMount();
                setTimeout(() => this.setState({ isOpen: false , hidden: false }), 1000);
            } else {
                NotificationManager.error('Something went wrong');
                setTimeout(() => this.setState({ isOpen: false,hidden: false }), 1000);
            }
        } catch (e) {
            throw (e);
        }
    }

    async showMessageModal(company){
        try {
            await this.setState({company: company})
            const userExists = await this.userExists(company.id);
            const { user_email, senderemail, message } = this.state;
            if (userExists) {
                const chatExists = await this.chatExists();
                try {
                    if(!chatExists){
                        this.setState({sendmessage: 1, modalcount:0 ,job_detail:0})
                    }else{
                        NotificationManager.info("Chat Exist");
                        setTimeout(async() => await history.push("/customer/chats"), 1000);
                    } 
                } catch (e) {
                  console.log(e);
                }
            }else {
                NotificationManager.error("User Is Not Exists Please Check It");
            }
        } catch (e) {
            throw (e);
        }
        
    }

    async sendMessage() {
        try {
            const { user_email, senderemail, message } = this.state;
            if (message != ""){
                this.createChat(user_email,senderemail,message)
                NotificationManager.success("Message Sent Successfully!");
                this.closeModal()
                this.componentDidMount()
            }else{
                NotificationManager.error("Write something to send!");
            }
        } catch (e) {
            throw (e);
        }
    }

    userExists = async (company) => {
        const userSnapshot = await firestore.collection("users").get();
        try {
            let count = [];
            userSnapshot.docs
            .map((docs) => count.push(docs.data()))
            const countlen = count.length
            let found = false
            for(var i = 0; i < countlen; i++) {
                if (count[i].company_id == company) {
                    found = true
                    await this.setState({senderemail : count[i].email})
                    break;
                }
            }
            return found;
        } catch (e) {
          console.log(e);
          NotificationManager.error('Something went wrong');
        }
    };

    createChat = async (userEmail, senderEmail, msg) => {
        const docId = await this.buildId();
        const time = new Date().toLocaleTimeString("en-US", {
          hour: "numeric",
          hour12: true,
          minute: "numeric",
        });
        var today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const timestamp = Date.now();
        
        await firestore
            .collection("chats")
            .doc(docId)
            .set({
              docid: docId,
              time: timestamp,
              users: [userEmail, senderEmail],
              typing: [],
              messages: [
                {
                  message: msg,
                  sender: userEmail,
                  time: time+" "+date,
                  type: "text",
                },
              ],
        });
    };

    buildId = async () => {
        return [this.state.senderemail, this.state.user_email].sort().join(":");
    };

    chatExists = async () => {
        const docid = await this.buildId();
        const chat = await firestore.collection("chats").doc(docid).get();
        return chat.exists;
    };

    renderCompanies(allCompanies) {
        return allCompanies && this.state.allCompanies.map((company, key) => {
            {
                return <Grid>
                    <Cell >
                        {/* <Card className="flex bg-grey shadow-md" size="md"> */}
                        <Row>
                            <Col xs={8}>
                                <h4 className="font-bold m-2 text-teal-500 pull-left">{company.name}</h4>
                                {/* <hr/> */}
                                <Rating className="m-2" name="read-only" value={company.rating} readOnly />
                                <br />

                            </Col>
                            <Col xs={4}>
                                <Tooltip title="Message">
                                    <Button  className="fa fa-commenting"
                                        color="primary"
                                        style={{ textAlign: 'center', borderRadius: 60,"margin-top": "10%" }} 
                                        onClick={this.showMessageModal.bind(this, company)} ></Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Tooltip title="Allocate to Company">
                                    <Button style={{ cursor: "pointer" }} onClick={this.assignJob.bind(this, company)} >{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}Assign job to Company</Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        {/* </Card> */}
                    </Cell>
                </Grid>
            }
        })
    }

    renderResultRows(allUserJobs) {
        return allUserJobs && this.state.allUserJobs.data.map((job, key) => {
            {
                return <tr key={key} >
                    <td scope="row">{job.id}  </td>
                    <td scope="row">{job.user_name}  </td>
                    <td scope="row">{job.address}  </td>
                    <td scope="row">{job.person}  </td>
                    <td scope="row">{job.job_desc}  </td>
                    <td scope="row">{job.status}  </td>
                    <td><span>
                        <div className="col-md-6 col-md-offset-6">
                            <span style={{ cursor: "pointer" }} data-tip="Offers" onClick={this.showModel.bind(this, job)}> 
                                <i className="fa fa-building-o mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
                        <ReactTooltip />
                    </span></td>
                    <td><span>
                        <div className="col-md-6 col-md-offset-6">
                            <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showDetailModel.bind(this, job)}> 
                                <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
                        <ReactTooltip />
                    </span></td>
                </tr>
            }
        })
    }

    render() {
        const { allUserJobs, job, isOpen } = this.state;

        return (
            <GridContainer>
                <NotificationContainer />
                <GridItem xs={12} sm={12} md={12}>
                    <Card style={{"min-width":"270px"}}>
                        <CardHeader color="primary">
                            <Row className="ml-2">
                            <h4>Jobs</h4></Row>
                            <Row className="ml-2">
                            <p>
                                All Jobs record
                            </p></Row>
                        </CardHeader>
                        <CardBody>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Address</th>
                                        <th>Person</th>
                                        <th>Job Description</th>
                                        <th>Job Status</th>
                                        <th>Offers</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserJobs && this.renderResultRows(allUserJobs)
                                    }
                                    {allUserJobs && _.size(allUserJobs.data) === 0 && <tr><td scope="row">{"No Records Found"}  </td></tr>
                                    }

                                </tbody>
                            </Table>
                            <nav>
                                <Pagination className="pull-right mt-3" size="md" >

                                    <PaginationItem disabled={this.state.currentPage <= 0}>

                                        <PaginationLink
                                            onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                                            previous
                                            href="#"
                                        />

                                    </PaginationItem>

                                    {[...Array(this.state.pagesCount)].map((page, i) =>
                                        <PaginationItem active={i === this.state.currentPage} key={i}>
                                            <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                                {i + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    )}

                                    <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>

                                        <PaginationLink
                                            onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                                            next
                                            href="#"
                                        />

                                    </PaginationItem>

                                </Pagination>
                            </nav>

                            <Modal isOpen={isOpen} toggle={this.closeModal}>
                                {this.state.modalcount == 1 && <div>
                                    <ModalHeader ><strong>Offering Companies</strong></ModalHeader>

                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            {this.state.allCompanies &&
                                                this.renderCompanies(this.state.allCompanies)
                                            }
                                            {this.state.allCompanies && _.size(this.state.allCompanies) === 0 && <tr><td scope="row">{"No Records Found"}  </td></tr>
                                            }
                                        </Col>
                                    </ModalBody></div>
                                }
                                {this.state.job_detail==1 && 
                                <div>
                                    <ModalHeader ><strong>Job details</strong></ModalHeader>

                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='8' sm='12' xs='12'>
                                            <span><strong className="text-dark">User Name :</strong> {job && job.user_name}</span>
                                        </Col>

                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='8' sm='12' xs='12'>
                                            <span><strong className="text-dark">Address :</strong> {job && job.address}</span>
                                        </Col>

                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='8' sm='12' xs='12'>
                                            <span><strong className="text-dark">Person :</strong> {job && job.person}</span>
                                        </Col>

                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <span><strong className="text-dark text-justify">Job Description :</strong> {job && job.job_desc}</span>
                                        </Col>
                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> Start Time :</strong>{job && job.start_time}</span>
                                            </Col>
                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> End Time :</strong>{job && job.end_time}</span>
                                            </Col>
                                        </div>
                                        </Col>
                                    </ModalBody>

                                    </div>}
                                {this.state.sendmessage == 1 && <div>
                                    <ModalHeader ><strong>Send Message</strong></ModalHeader>

                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                        <Row>
                                            <Input name="message" 
                                                className="ml-2" 
                                                type="textarea" 
                                                rows={3} 
                                                onChange={this.onChange} />
                                        </Row>
                                        <Row>
                                            <br></br>
                                        </Row>
                                        <Row>
                                            <Button className="align-center" 
                                                style={{"margin-left":"auto","margin-right":"auto"}} 
                                                color="primary"
                                                onClick={this.sendMessage}>Send</Button>
                                        </Row>
                                        </Col>
                                    </ModalBody>
                                </div>}
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.closeModal}>Close</Button>
                                </ModalFooter>
                            </Modal>
                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>
        );
    }

}
export default CustomerJobs;