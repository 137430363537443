import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CardBody from "components/Card/CardBody";
import { Row, Col, Spinner } from "reactstrap";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import './login.scss'
import { camp } from "../routes";
import { connect } from "react-redux";
import { addArticle } from "../actions/index";
import {
  adminLogin,
  customerLogin,
  getAllModule,
} from "../services/admin/Index";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { auth } from "../firebase/firebase";
import history from "../history";
import { Grid } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import "./style.css";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.forgotpassword = this.forgotpassword.bind(this);
    this.state = { email: false, hidden: false };
    localStorage.removeItem("userData");
    this.getstarted = this.getstarted.bind(this);

    this.state = {
      count: 0,
      hidden: false,
      selectedOption: "Admin",
      loading: false,
    };
  }

  handleClick = () => {
    this.setState({ loading: true });

    // Simulating an asynchronous operation
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  async componentDidMount() {
    await localStorage.clear();
    await auth.signOut();
  }

  async handleValidSubmit(event, model) {
    event.preventDefault();
    const selectedOption = this.state.selectedOption;
    localStorage.removeItem("userData");
    localStorage.removeItem("dashboardModules");
    this.setState({ hidden: true });
    const body = {
      email: model.email,
      password: model.password,
      user_type: selectedOption.toLowerCase(),
    };
    if (selectedOption.toLowerCase() === "admin") {
      const requestResult = await adminLogin(body);
      if (requestResult.status >= 400) {
        NotificationManager.error("Something went wrong");
        //alert(requestResult.message)
      } else {
        localStorage.setItem("userData", JSON.stringify(requestResult));
        //localStorage.setItem('userData',requestResult)
        const token = requestResult.token;
        const dashboard = await getAllModule(token);
        localStorage.setItem("dashboardModules", JSON.stringify(dashboard));
        (requestResult.data.user_type === "admin" && history.push("/admin")) ||
          (requestResult.message &&
            /**alert(requestResult)**/ NotificationManager.error(
              requestResult.message
            ));
      }
    } else if (selectedOption.toLowerCase() === "customer") {
      const requestResult = await customerLogin(body);
      localStorage.setItem("userData", JSON.stringify(requestResult));
      const token = requestResult.token;
      const dashboard = await getAllModule(token);
      localStorage.setItem("dashboardModules", JSON.stringify(dashboard));
      await auth.signInWithEmailAndPassword(model.email, model.password);
      (requestResult.data &&
        requestResult.data.user_type === "customer" &&
        history.push("/customer")) ||
        (requestResult.message &&
          /**alert(requestResult.message)**/ NotificationManager.error(
            requestResult.message
          ));
    }
    if (selectedOption.toLowerCase() === "employ") {
      const requestResult = await adminLogin(body);
      localStorage.setItem("userData", JSON.stringify(requestResult));
      const token = requestResult.token;
      const dashboard = await getAllModule(token);
      localStorage.setItem("dashboardModules", JSON.stringify(dashboard));
      await auth.signInWithEmailAndPassword(model.email, model.password);
      (requestResult.data &&
        requestResult.data.user_type === "employ" &&
        history.push("/employ")) ||
        (requestResult.message &&
          /**alert(requestResult.message)**/ NotificationManager.error(
            requestResult.message
          ));
    } else if (selectedOption.toLowerCase() === "company") {
      const requestResult = await adminLogin(body);
      localStorage.setItem("userData", JSON.stringify(requestResult));
      const token = requestResult.token;
      const dashboard = await getAllModule(token);
      localStorage.setItem("dashboardModules", JSON.stringify(dashboard));
      await auth.signInWithEmailAndPassword(model.email, model.password);
      (requestResult.data &&
        requestResult.data.user_type === "company" &&
        history.push("/company")) ||
        (requestResult.message &&
          /**alert(requestResult.message)**/ NotificationManager.error(
            requestResult.message
          ));
    }
    this.setState({ hidden: false });
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ email: values.email, error: true });
  }

  getstarted() {
    this.setState({ count: 1 });
  }

  signup() {
    history.push("/signup");
  }

  forgotpassword() {
    history.push("/forgotpassword");
  }

  closeModal() {
    this.setState({ email: false, error: false });
  }
  render() {
    const modalError = this.state.error ? "not" : ""; // This is just for the modal
    const { hidden } = this.state;
    const { loading } = this.state;
    return (
      <div>
        <body>
          <div id="landing">
            <div class="landing-inner text-light">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h1 class="display-10 mb-2 text-purple-600 font-weight-bold">
                      GpsRecruiter
                    </h1>
                  </div>
                </div>
              </div>
              <div>
                <Row className="mx-0">
                  <Col lg="6" md="8" sm="12" xs="12" className="m-auto">
                    <Card style={{ "min-width": "265px" }}>
                      <NotificationContainer />
                      <CardBody>
                        <AvForm
                          onValidSubmit={this.handleValidSubmit}
                          onInvalidSubmit={this.handleInvalidSubmit}
                          className="group"
                        >
                          <div className="mt-4 flex justify-center w-full">
                            <a href="/">
                              <h3 className="text-4xl font-bold text-purple-600">
                                Admin Sign In
                              </h3>
                            </a>
                          </div>
                          {/* <Nav tabs className="justify-between border border-primary bg-primary text-white" >
                            <NavItem className="border border-primary">
                              <NavLink
                                className={`${
                                  this.state.selectedOption === "Admin"
                                    ? "active"
                                    : ""
                                } text-black border border-primary font-weight-bold`}
                                onClick={() =>
                                  this.setState({ selectedOption: "Admin" })
                                }
                               
                              >
                                ADMIN
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  this.state.selectedOption === "Company"
                                    ? "active"
                                    : ""
                                } text-black border border-primary font-weight-bold`}
                                onClick={() =>
                                  this.setState({ selectedOption: "Company" })
                                }
                              >
                                COMPANY
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  this.state.selectedOption === "Customer"
                                    ? "active"
                                    : ""
                                } text-black border border-primary font-weight-bold`}
                                onClick={() =>
                                  this.setState({ selectedOption: "Customer" })
                                }
                              >
                                CUSTOMER
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  this.state.selectedOption === "Employ"
                                    ? "active"
                                    : ""
                                } text-black border border-primary font-weight-bold`}
                                onClick={() =>
                                  this.setState({ selectedOption: "Employ" })
                                }
                              >
                                EMPLOY
                              </NavLink>
                            </NavItem>
                          </Nav> */}
                          <label className="pull-left mt-4">
                            Email Address
                          </label>
                          <AvField
                            name="email"
                            type="email"
                            required
                            className="form-input"
                          />
                          <label className="pull-left">Password</label>
                          <AvField
                            name="password"
                            type="password"
                            required
                            lassName="form-input"
                          />
                          {/* <label className="pull-left">Please select account type</label>
                                  <AvField type="select" name="userType" required>
                                    <option>Account Type</option>
                                    <option>Admin</option>
                                    <option>Company</option>
                                    <option>Customer</option>
                                    <option>Employ</option>
                                  </AvField> */}
                          {/* <CardBody></CardBody> */}
                          {/* <Button type="submit" color="warning w-100">
                            Log In{" "}
                            {hidden && (
                              <Spinner size="sm" role="status"></Spinner>
                            )}
                          </Button> */}

                          <button
                            type="submit"
                            className="w-full h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                            onClick={this.handleClick}
                          >
                            <span>Log In{"  "}</span>
                            {loading &&  <Spinner size="sm" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>}
                          </button>

                          <div className="pull-left">
                            <span
                              type="button"
                              className="text-purple-600"
                              onClick={this.forgotpassword}
                            >
                              Forgot Password
                            </span>
                          </div>
                          {/* <div className="pull-right">
                            <p>
                              Don't have an account?{" "}
                              <span
                                type="button"
                                className="text-primary"
                                onClick={this.signup}
                              >
                                Sign up
                              </span>
                            </p>
                          </div> */}
                        </AvForm>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </body>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { articles: state.USER_DATA };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addArticle: (articles) => dispatch(addArticle({ a: "articles" })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
