import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { updateEmploy, updatePassword } from '../../services/admin/Index';

import CardHeader from "components/Card/CardHeader.js";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import _ from "lodash";
import { NotificationContainer, NotificationManager } from 'react-notifications';

import {
  Card, CardBody,
  CardTitle, CardSubtitle, Button, Col, Row, Spinner
} from 'reactstrap';
import ImageUploader from 'react-images-upload'
import profile from "../../assets/img/profile.png";
import { Grid, Cell } from 'react-mdl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';

class UpdateEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.addQualification = this.addQualification.bind(this);
    this.addExperience = this.addExperience.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.addNewQualification = this.addNewQualification.bind(this);
    this.addNewExperience = this.addNewExperience.bind(this);
    this.updateEmployeeProfile = this.updateEmployeeProfile.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.UpdatePassword = this.UpdatePassword.bind(this);
    this.renderQualification = this.renderQualification.bind(this);
    this.renderExperience = this.renderExperience.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.addSummary = this.addSummary.bind(this);
    this.editpersonal = this.editpersonal.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
    this.updateSummary = this.updateSummary.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.deleteQualification = this.deleteQualification.bind(this);
    this.deleteExperience = this.deleteExperience.bind(this);

    this.state = {
      count: 0,
      email: '',
      summary: '',
      old_password: '',
      new_password: '',
      confirm_password: '',
      img: '',
      img_url: '',
      first_name: '',
      last_name: '',
      address: '',
      available_hours: '',
      city: '',
      state: '',
      country: '',
      gender: '',
      is_available: '',
      phone: '',
      status: '',
      device_type: '',
      user_name: '',
      updated_at: '',
      qualification: [],
      experience: [],
      vehicle_type: '',
      token: '',
      experienceTitle: '',
      experienceCompany: '',
      experienceIndustry: '',
      experienceSalary: '',
      experienceStartDate: '',
      experienceEndDate: '',
      description: '',
      qualificationTitle: '',
      qualificationInstitute: '',
      qualificationLocation: '',
      qualificationSubject: '',
      qualificationCompletionYear: '',
      hidden: false
    }
  }

  async componentWillMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const user = userInfo.data
    const token = userInfo.token;
    if (user.img_url == null || user.img_url == '') {
      this.setState({
        email: user.email,
        img_url: user.img_url,
        first_name: user.first_name,
        last_name: user.last_name,
        address: user.address,
        available_hours: user.available_hours,
        city: user.city,
        state: user.state,
        country: user.country,
        gender: user.gender,
        is_available: user.is_available,
        phone: user.phone,
        status: user.status,
        device_type: user.device_type,
        user_name: user.user_name,
        updated_at: user.updated_at,
        qualification: user.qualification,
        experience: user.experience,
        vehicle_type: user.vehicle_type,
        token: token,
        count: 0,
        hidden: false,
        summary: user.summary
      })
    } else {
      this.setState({
        email: user.email,
        img_url: user.img_url,
        first_name: user.first_name,
        last_name: user.last_name,
        address: user.address,
        available_hours: user.available_hours,
        city: user.city,
        state: user.state,
        country: user.country,
        gender: user.gender,
        is_available: user.is_available,
        phone: user.phone,
        status: user.status,
        device_type: user.device_type,
        user_name: user.user_name,
        updated_at: user.updated_at,
        qualification: user.qualification,
        experience: user.experience,
        vehicle_type: user.vehicle_type,
        token: token,
        count: 0,
        hidden: false,
        summary: user.summary
      })
    }
  }

  async onDrop(e) {
    await this.setState({
      img: e[0],
      upload: 3
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  addSummary() {
    this.setState({ count: 3 })
  }

  editpersonal() {
    this.setState({ count: 4 })
  }

  editDetail() {
    this.setState({ count: 5 })
  }

  editAddress() {
    this.setState({ count: 1 })
  }

  goBack() {
    this.setState({
      count: 0
    })
  }

  addQualification() {
    this.setState({
      count: 1
    })
  }

  addExperience() {
    this.setState({
      count: 2
    })
  }

  editProfile() {
    this.setState({
      count: 3
    })
  }

  changePassword() {
    this.setState({
      count: 4
    })
  }

  async UpdatePassword() {
    try {
      this.setState({ hidden: true })
      if (this.state.new_password == this.state.confirm_password) {
        const body = {
          email: this.state.email,
          password: this.state.new_password,
          token: this.state.token
        }
        const response = await updatePassword(body);
        if (response.status == 200) {
          this.setState({ count: 0, hidden: false })
          NotificationManager.success('Updated Succesfuly');
        } else {
          NotificationManager.error('Something went wrong');
        }
      } else {
        NotificationManager.success('Password Mismatch');
      }
    } catch (e) {
      throw (e);
    }
  }

  async updateEmployeeProfile() {
    try {
      this.setState({ hidden: true })
      let img = ''
      if (this.state.img != ''){
        img = this.state.img
      }else{
        img = this.state.img_url
      }
      const data = new FormData();
      data.append('img_url', img);
      data.append('first_name', this.state.first_name);
      data.append('last_name', this.state.last_name);
      data.append('phone', this.state.phone);
      data.append('gender', this.state.gender);
      data.append('user_name', this.state.user_name);
      let accessToken = this.state.token;
      axios
      .post('http://localhost:4500/user/updateEmploy', data,
      { headers:
      { 'Content-Type': 'multipart/form-data', Authorization: accessToken }
      })
      .then(res => {
        console.log('res', res);
        if (res.status == 200) {
            NotificationManager.success('Updated Succesfully');
            this.setState({ hidden: false, img: '' });
            localStorage.setItem('userData', JSON.stringify(res.data));
            setTimeout(() => this.componentWillMount(), 2000);
          } else {
            NotificationManager.error('Something went wrong');
            this.setState({ hidden: false, img: '' })
            setTimeout(() => this.componentWillMount(), 2000);
          }
      });
      // const body = {
      //   email: this.state.email,
      //   img_url: img,
      //   first_name: this.state.first_name,
      //   last_name: this.state.last_name,
      //   phone: this.state.phone,
      //   gender: this.state.gender,
      //   user_name: this.state.user_name,
      //   token: this.state.token
      // }
      // console.log(body)
      // const response = await updateEmploy(body);
      // console.log(response)
      // if (response.status == 200) {
      //   NotificationManager.success('Updated Succesfully');
      //   this.setState({ hidden: false, img: '' })
      //   await localStorage.setItem('userData', JSON.stringify(response));
      //   setTimeout(() => this.componentWillMount(), 2000);
      // } else {
      //   NotificationManager.error('Something went wrong');
      //   this.setState({ hidden: false, img: '' })
      //   setTimeout(() => this.componentWillMount(), 2000);
      // }
    } catch (e) {
      throw (e);
    }

  }

  async updateAddress() {
    try {
      this.setState({ hidden: true })
      const body = {
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zip_code: this.state.zip_code,
        token: this.state.token
      }
      const response = await updateEmploy(body);
      if (response.status == 200) {
        NotificationManager.success('Updated Succesfully');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }

  }

  async updateSummary() {
    try {
      this.setState({ hidden: true })
      const body = {
        summary: this.state.summary,
        token: this.state.token
      }
      const response = await updateEmploy(body);
      if (response.status == 200) {
        NotificationManager.success('Updated Succesfully');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }

  }

  async updateDetails() {
    try {
      this.setState({ hidden: true })
      const body = {
        available_hours: this.state.available_hours,
        is_available: this.state.is_available,
        status: this.state.status,
        device_type: this.state.device_type,
        vehicle_type: this.state.vehicle_type,
        token: this.state.token
      }
      const response = await updateEmploy(body);
      if (response.status == 200) {
        NotificationManager.success('Updated Succesfully');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }

  }

  async addNewQualification() {
    try {
      this.setState({ hidden: true })
      const body = {
        title: this.state.qualificationTitle,
        institute: this.state.qualificationInstitute,
        location: this.state.qualificationLocation,
        subject: this.state.qualificationSubject,
        completion_year: this.state.qualificationCompletionYear,
        action: 'insert'
      }
      await this.setState({ qualification: this.state.qualification.concat(body) })
      const new_body = {
        qualification: this.state.qualification,
        token: this.state.token
      }
      console.log(new_body)
      const response = await updateEmploy(new_body);
      console.log(response)
      if (response.status == 200) {
        NotificationManager.success('Qualification Added Succesfully!');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }
  }

  async deleteQualification(qual) {
    try {
      const body = {
        title: qual.title,
        institute: qual.institute,
        location: qual.location,
        subject: qual.subject,
        completion_year: qual.completionYear,
        action: 'delete',
        token: this.state.token
      }
      const response = await updateEmploy(body);
      console.log(response)
      if (response.status == 200) {
        NotificationManager.success('Qualification Deleted Succesfully!');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }
  }

  async addNewExperience() {
    try {
      this.setState({ hidden: true })
      const body = {
        title: this.state.experienceTitle,
        company: this.state.experienceCompany,
        industry: this.state.experienceIndustry,
        salary: this.state.experienceSalary,
        start_date: this.state.experienceStartDate,
        end_date: this.state.experienceEndDate,
        description: this.state.experienceDescription,
        action: 'insert'
      }
      await this.setState({ experience: this.state.experience.concat(body) })
      const new_body = {
        experience: this.state.experience,
        token: this.state.token
      }
      console.log(new_body)
      const response = await updateEmploy(new_body);
      console.log(response)
      if (response.status == 200) {
        NotificationManager.success('Experience Added Succesfully!');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }
  }

  async deleteExperience(exp) {
    try {
      const body = {
        title: exp.title,
        company: exp.company,
        industry: exp.industry,
        salary: exp.salary,
        start_date: exp.start_date,
        end_date: exp.end_date,
        description: exp.description,
        action: 'delete'
      }
      const new_body = {
        experience: this.state.experience,
        token: this.state.token
      }
      const response = await updateEmploy(new_body);
      if (response.status == 200) {
        NotificationManager.success('Experience Deleted Succesfully!');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }
  }

  renderQualification(qualification) {
    return qualification && qualification.map((qual, key) => {
      {
        return <Grid>
          <Cell >
            <Card className="flex bg-grey shadow-md m-1" size="md">
              <Row>
                <Col>
                  <Row className="ml-1">
                  ` <h4 className="font-bold m-2 text-teal-500 pull-left">{qual.title}</h4></Row>
                </Col>
                <Col>
                  <Tooltip title="Edit">
                    <Button className="fa fa-pencil-square-o pull-right " color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true"></Button>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button className="fa fa-trash pull-right " color="transparent" onClick={this.deleteQualification.bind(this, qual)} style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true"></Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardBody>
                    <Row >
                      <Col>
                        <Row className="ml-1">
                          <p className="text-base font-bold">Institute</p>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="ml-1">
                          <p className="text-base">{qual.institute}</p>
                        </Row>
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        <Row className="ml-1">
                          <p className="text-base font-bold">Location</p>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="ml-1">
                          <p className="text-base">{qual.location}</p>
                        </Row>
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        <Row className="ml-1">
                          <p className="text-base font-bold">Subject</p>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="ml-1">
                          <p className="text-base">{qual.subject}</p>
                        </Row>
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        <Row className="ml-1">
                         <p className="text-base font-bold">Completion Year</p>
                         </Row>
                      </Col>
                      <Col>
                        <Row className="ml-1">
                          <p className="text-base">{qual.completion_year}</p>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Cell>
        </Grid>
      }
    })
  }

  renderExperience(experience) {
    return experience && experience.map((exp, key) => {
      {
        return <Grid>
          <Cell >
            <Card className="flex bg-grey shadow-md m-1" size="md">
              <Row>
                <Col>
                  <Row className="ml-1">
                    <h4 className="font-bold m-2 text-teal-500 pull-left">{exp.title}</h4></Row>
                </Col>
                <Col>
                  <Tooltip title="Edit">
                    <Button className="fa fa-pencil-square-o pull-right" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true"></Button>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button className="fa fa-trash pull-right" color="transparent" onClick={this.deleteExperience.bind(this, exp)} style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true"></Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardBody>
                    <Row >
                      <Col><Row className="ml-1">
                        <p className="text-base font-bold">Company</p></Row>
                      </Col>
                      <Col><Row className="ml-1">
                        <p className="text-base">{exp.company}</p></Row>
                      </Col>
                    </Row>
                    <Row >
                      <Col><Row className="ml-1">
                        <p className="text-base font-bold">Industry</p></Row>
                      </Col>
                      <Col><Row className="ml-1">
                        <p className="text-base">{exp.industry}</p></Row>
                      </Col>
                    </Row>
                    <Row >
                      <Col><Row className="ml-1">
                        <p className="text-base font-bold">Salary</p></Row>
                      </Col>
                      <Col><Row className="ml-1">
                        <p className="text-base">{exp.salary}</p></Row>
                      </Col>
                    </Row>
                    <Row >
                      <Col><Row className="ml-1">
                        <p className="text-base font-bold">Description</p></Row>
                      </Col>
                      <Col><Row className="ml-1">
                        <p className="text-base">{exp.description}</p></Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Cell>
        </Grid>
      }
    })
  }

  render() {
    var IMG_URL = "";
    if (this.state.img_url!=="" && this.state.img_url!==null){
      IMG_URL = "http://localhost:4500/uploads/"+this.state.img_url;
    }
    return (
      <div>
        <NotificationContainer />
        
          <Card>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4 >Profile</h4>
              </Row>
            </CardHeader>
            <CardBody>
              <Grid>
                <Cell >
                  <CardBody >
                    <Card className="flex flex-initial bg-grey shadow-md">
                      <Row>
                        <Col>
                          <Row className="ml-1">
                            <h2 className="font-bold m-2 text-lg">Personal Info</h2>
                          </Row>
                        </Col>
                        <Col>
                          <Tooltip title="Edit">
                            <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} onClick={this.editpersonal}></Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      {this.state.count == 0 && <div>
                        <Row>
                          <Col xs={12}>
                            <div className="card-profile-image m-2 ml-4">
                              <a>
                                <img
                                  className="rounded-circle"
                                  src={IMG_URL!="" ? IMG_URL : profile}
                                  style={{width: 160, height: 160}}
                                />
                              </a>
                            </div>
                          </Col>
                          <Col xs={12}>
                            <CardBody>
                              <CardTitle ><Row className="ml-1"><strong> <h4 className="font-weight-bold text-teal-400">{this.state.user_name}</h4></strong></Row></CardTitle>
                              <CardTitle><Row className="ml-1"><strong>{this.state.first_name + " " + this.state.last_name}</strong></Row></CardTitle>
                              <CardTitle><Row className="ml-1"><strong>{this.state.email}</strong></Row></CardTitle>
                              <CardTitle><Row className="ml-1"><strong>{this.state.phone}</strong></Row></CardTitle>
                              {/* <CardSubtitle><strong>{"Male"}</strong></CardSubtitle> */}
                            </CardBody>
                          </Col>
                        </Row></div>}
                      {this.state.count == 4 &&
                        <div>
                          <div class="container">
                            <div class="row">
                              <div class="col-md-8 m-auto">
                                <AvForm className='group'>
                                  <label className="pull-left">Profile Image</label>
                                  <br/>
                                  <ImageUploader
                                    className='img_url'
                                    withIcon={true}
                                    buttonText='Choose images'
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    singleImage={true}
                                  />
                                  <AvField name="user_name" label="User Name" type="user_name" onChange={this.onChange} value={this.state.user_name} required className='form-input' />
                                  <AvField name="first_name" label="First Name" type="first_name" onChange={this.onChange} value={this.state.first_name} required className='form-input' />
                                  <AvField name="last_name" label="Last Name" type="last_name" onChange={this.onChange} value={this.state.last_name} required className='form-input' />
                                  <AvField name="phone" label="Phone" type="phone" onChange={this.onChange} value={this.state.phone} required className='form-input' />
                                  <AvField type="select" name="gender" label="Gender" onChange={this.onChange} value={this.state.gender}>
                                    <option>Please Select</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Other</option>
                                  </AvField>
                                  <div class="container">
                                    <div class="row">
                                      <div class="col text-center">
                                        <div className="mt-3">
                                          <a className="btn btn-light mr-2" onClick={this.updateEmployeeProfile}>
                                            <i className="fa fa-pencil-square-o mr-2 text-info" ></i>Update{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                          <a className="btn btn-light mr-2" onClick={this.goBack}>
                                            <i className="fa fa-times mr-2 text-info"></i> Cancel</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </AvForm>
                                <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                              </div>
                            </div>
                          </div>
                        </div>}
                    </Card>
                  </CardBody>
                </Cell>
              </Grid>
              <Grid>
                <Cell >
                  <Tabs>
                    <TabList xs={12} className='row justify-content-around'>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Intro</Tab>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Qualification</Tab>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Experience</Tab>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Address</Tab>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Password</Tab>
                    </TabList>
                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          
                          <Row>
                            <Col>
                              <Row className="ml-1">
                                <h2 className="font-bold m-2 text-lg">Summary</h2>
                              </Row>
                            </Col>
                            <Col>
                              <Tooltip title="Edit">
                                <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.addSummary}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {this.state.count == 0 && <Row className="ml-1"><p className="m-3 text-base">No Summary</p></Row>}
                              {this.state.count == 3 &&
                                <div>
                                  <div class="container">
                                    <div class="row">
                                      <div class="col-md-8 m-auto">

                                        <AvForm className='group'>
                                          <AvField name="summary" label="Summary" type="textarea" onChange={this.onChange} value={this.state.summary} required className='form-input' />
                                          <div class="container">
                                            <div class="row">
                                              <div class="col text-center">
                                                <div className="mt-3">
                                                  <a className="btn btn-light mr-2" onClick={this.updateSummary}>
                                                    <i className="fa fa-floppy-o mr-2 text-info" ></i>Save{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                                  <a className="btn btn-light mr-2" onClick={this.goBack}>
                                                    <i className="fa fa-times mr-2 text-info"></i> Cancel</a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </AvForm>
                                        <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                            </Col>
                          </Row>
                        </Card>
                      </CardBody>
                    </TabPanel>

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col>
                              <Row className="ml-1">
                                <h2 className="font-bold m-2 text-lg">Qualification</h2>
                              </Row>
                            </Col>
                            <Col>
                              <Tooltip title="Add">
                                <Button className="fa fa-plus pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.addQualification}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          {this.state.count == 0 &&
                            <Row>
                              <Col>
                                {
                                  this.state.qualification && this.renderQualification(this.state.qualification)
                                }
                                {this.state.qualification && _.size(this.state.qualification) === 0 && <td scope="row">{"No Records Found"}  </td>

                                }
                              </Col>
                            </Row>
                          }{this.state.count == 1 &&
                            <div>
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-8 m-auto">


                                    <AvForm onValidSubmit={this.addNewQualification} onInvalidSubmit={this.handleInvalidSubmit} className='group'>
                                      <AvField name="qualificationTitle" label="Title" type="title" onChange={this.onChange} required className='form-input' />
                                      <AvField name="qualificationInstitute" label="Institute" type="institute" onChange={this.onChange} required className='form-input' />
                                      <AvField name="qualificationLocation" label="Location" type="location" onChange={this.onChange} required className='form-input' />
                                      <AvField name="qualificationSubject" label="Subject" type="subject" onChange={this.onChange} required className='form-input' />
                                      <AvField name="qualificationCompletionYear" label="Completion Year" type="year" onChange={this.onChange} required className='form-input' />

                                      <div class="container">
                                        <div class="row">
                                          <div class="col text-center">
                                            <div className="mt-3">
                                              <a className="btn btn-light mr-2" onClick={this.addNewQualification}>
                                                <i className="fa fa-plus mr-2 text-info"></i>Add{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                              <a className="btn btn-light mr-2" onClick={this.goBack}>
                                                <i className="fa fa-times mr-2 text-info"></i> Cancel</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </AvForm>
                                    <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </Card>
                      </CardBody>
                    </TabPanel>

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col><Row className="ml-1">
                              <h2 className="font-bold m-2 text-lg">Experience</h2></Row>
                            </Col>
                            <Col>
                              <Tooltip title="Add">
                                <Button className="fa fa-plus pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.addExperience}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          {this.state.count == 0 &&
                            <Row>
                              <Col>
                                {
                                  this.state.experience && this.renderExperience(this.state.experience)
                                }
                                {this.state.experience && _.size(this.state.experience) === 0 && <td scope="row">{"No Records Found"}  </td>

                                }
                              </Col>
                            </Row>
                          }{this.state.count == 2 &&
                            <div>
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-8 m-auto">

                                    <AvForm onValidSubmit={this.addNewExperience} onInvalidSubmit={this.handleInvalidSubmit} className='group'>
                                      <AvField name="experienceTitle" label="Title" type="title" onChange={this.onChange} required className='form-input' />
                                      <AvField name="experienceCompany" label="Company" type="company" onChange={this.onChange} required className='form-input' />
                                      <AvField name="experienceIndustry" label="Industry" type="industry" onChange={this.onChange} required className='form-input' />
                                      <AvField name="experienceSalary" label="Salary" type="salary" onChange={this.onChange} required className='form-input' />
                                      <AvField name="experienceStartDate" label="StartDate" type="date" onChange={this.onChange} required className='form-input' />
                                      <AvField name="experienceEndDate" label="EndDate" type="date" onChange={this.onChange} required className='form-input' />
                                      <AvField name="experienceDescription" label="Description" type="description" onChange={this.onChange} required className='form-input' />
                                      <div class="container">
                                        <div class="row">
                                          <div class="col text-center">
                                            <div className="mt-3">
                                              <a className="btn btn-light mr-2" onClick={this.addNewExperience}>
                                                <i className="fa fa-plus mr-2 text-info"></i>Add{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                              <a className="btn btn-light mr-2" onClick={this.goBack}>
                                                <i className="fa fa-times mr-2 text-info"></i> Cancel</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </AvForm>
                                    <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </Card>
                      </CardBody>
                    </TabPanel>

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col><Row className="ml-1">
                              <h2 className="font-bold m-2 text-lg">Address</h2></Row>
                            </Col>
                            <Col>
                              <Tooltip title="Edit">
                                <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.editAddress}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          {this.state.count == 0 && <div><Row>
                            <Col><Row className="ml-1">
                              <p className="ml-2 text-base font-bold">City</p></Row>
                            </Col>
                            <Col><Row className="ml-1">
                              <p className="text-base">{this.state.city}</p></Row>
                            </Col>
                          </Row>
                            <Row>
                              <Col><Row className="ml-1">
                                <p className="ml-2 text-base font-bold">State</p></Row>
                              </Col>
                              <Col><Row className="ml-1">
                                <p className="text-base">{this.state.state}</p></Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col><Row className="ml-1">
                                <p className="ml-2 text-base font-bold">Zip Code</p></Row>
                              </Col>
                              <Col><Row className="ml-1">
                                <p className="text-base">{this.state.zip_code}</p></Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col><Row className="ml-1">
                                <p className="ml-2 text-base font-bold">Country</p></Row>
                              </Col>
                              <Col><Row className="ml-1">
                                <p className="text-base">{this.state.country}</p></Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col><Row className="ml-1">
                                <p className="ml-2 text-base font-bold">Address</p></Row>
                              </Col>
                              <Col><Row className="ml-1">
                                <p className="text-base">{this.state.address}</p></Row>
                              </Col>
                            </Row></div>}
                          {this.state.count == 1 &&
                            <div>
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-8 m-auto">

                                    <AvForm className='group'>
                                      <AvField name="city" label="City" type="city" onChange={this.onChange} value={this.state.city} className='form-input' />
                                      <AvField name="zip_code" label="ZipCode" type="zipcode" onChange={this.onChange} value={this.state.zip_code} className='form-input' />
                                      <AvField name="state" label="State" type="state" onChange={this.onChange} value={this.state.state} className='form-input' />
                                      <AvField type="select" name="country" label="Country" onChange={this.onChange} value={this.state.country}>
                                        <option></option>
                                        <option >Afghan</option>
                                        <option >Albanian</option>
                                        <option >Algerian</option>
                                        <option >American</option>
                                        <option >Angolan</option>
                                        <option >Antigua</option>
                                        <option >Argentinian</option>
                                        <option >Australian</option>
                                        <option >Austrian</option>
                                        <option >Azerbaijani</option>
                                        <option >Bahraini</option>
                                        <option >Bangladeshi</option>
                                        <option >Barbadian</option>
                                        <option >Barbuda</option>
                                        <option >Belarusian</option>
                                        <option >Belgian</option>
                                        <option >Belizian</option>
                                        <option >Bosnian</option>
                                        <option >Botswanan</option>
                                        <option >Brazilian</option>
                                        <option >British</option>
                                        <option >Bruneian</option>
                                        <option >Bulgarian</option>
                                        <option >Burmese</option>
                                        <option >Burundian</option>
                                        <option >Cambodian</option>
                                        <option >Cameroonian</option>
                                        <option >Canadian</option>
                                        <option >Chadian</option>
                                        <option >Chechnya</option>
                                        <option >Chilean</option>
                                        <option >Chinese</option>
                                        <option >Colombian</option>
                                        <option >Congolese</option>
                                        <option >Costa Rican</option>
                                        <option >Croat or Croatian</option>
                                        <option >Cuban</option>
                                        <option >Cypriot</option>
                                        <option >Czech</option>
                                        <option >Danish</option>
                                        <option >Democratic Republic of the Congo</option>
                                        <option >Djiboutian</option>
                                        <option >Dominican</option>
                                        <option >Duch</option>
                                        <option >Ecuadorian</option>
                                        <option >Egyptian</option>
                                        <option >Emirati</option>
                                        <option >Eritrean</option>
                                        <option >Estonian</option>
                                        <option >Ethiopian</option>
                                        <option >Fijian</option>
                                        <option >Filipino</option>
                                        <option >Finnish</option>
                                        <option >French</option>
                                        <option >Gambian</option>
                                        <option >Georgian</option>
                                        <option >German</option>
                                        <option >Ghanaian</option>
                                        <option >Gibraltarian</option>
                                        <option >Greek</option>
                                        <option >Guinean</option>
                                        <option >Haitian</option>
                                        <option >Hong Kong</option>
                                        <option >Hungarian</option>
                                        <option >Icelandic</option>
                                        <option >Indian</option>
                                        <option >Indonesian</option>
                                        <option >Iranian</option>
                                        <option >Iraqi</option>
                                        <option >Irish</option>
                                        <option >Italian</option>
                                        <option >Jamaican</option>
                                        <option >Japanese</option>
                                        <option >Jordanian</option>
                                        <option >Kazakh</option>
                                        <option >Kenyan</option>
                                        <option >Kosovo</option>
                                        <option >Kuwaiti</option>
                                        <option >Kyrgyzstan</option>
                                        <option >Lao Peoples Democratic Republic</option>
                                        <option >Latvian</option>
                                        <option >Lebanese</option>
                                        <option >Liberian</option>
                                        <option >Libyan</option>
                                        <option >Luxembourgers</option>
                                        <option >Madagascan</option>
                                        <option >Malawian</option>
                                        <option >Malaysian</option>
                                        <option >Maldivian</option>
                                        <option >Malian</option>
                                        <option >Maltese</option>
                                        <option >Mauritanian</option>
                                        <option >Mauritian</option>
                                        <option >Mexican</option>
                                        <option >Moroccan</option>
                                        <option >Mozambican</option>
                                        <option >Nepalese</option>
                                        <option >New Zealand</option>
                                        <option >Niger</option>
                                        <option >Nigerian</option>
                                        <option >Norwegian</option>
                                        <option >Omani</option>
                                        <option >Pakistan</option>
                                        <option >Palestinian</option>
                                        <option >Papua New Guinean</option>
                                        <option >Peruvian</option>
                                        <option >Polish</option>
                                        <option >Portuguese</option>
                                        <option >Puerto Rican</option>
                                        <option >Qatari</option>
                                        <option >Romanian</option>
                                        <option >Russian</option>
                                        <option >Rwandan</option>
                                        <option >Saudi</option>
                                        <option >Savlbardian</option>
                                        <option >Senegalese</option>
                                        <option >Serbian</option>
                                        <option >Sierra Leonian</option>
                                        <option >Singaporean</option>
                                        <option >Slovak</option>
                                        <option >Slovenian</option>
                                        <option >Solomon Islands</option>
                                        <option >Somali</option>
                                        <option >South African</option>
                                        <option >South Georgian</option>
                                        <option >South Korean</option>
                                        <option >South Sudanese</option>
                                        <option >Spanish</option>
                                        <option >Sri Lankan</option>
                                        <option >Sudanese</option>
                                        <option >Swedish</option>
                                        <option >Swiss</option>
                                        <option >Syrian</option>
                                        <option >Taiwanese</option>
                                        <option >Tajik or Tadjik</option>
                                        <option >Tanzanian</option>
                                        <option >Thai</option>
                                        <option >Timorese</option>
                                        <option >Tunisian</option>
                                        <option >Turkish</option>
                                        <option >Turkoman</option>
                                        <option >Ugandan</option>
                                        <option >Ukrainian</option>
                                        <option >Uzbek</option>
                                        <option >Venezuelan</option>
                                        <option >Vietnamese</option>
                                        <option >Yemeni</option>
                                        <option >Zimbabwean</option>
                                      </AvField><AvField name="address" label="Address" type="address" onChange={this.onChange} value={this.state.address} className='form-input' />
                                      <div class="container">
                                        <div class="row">
                                          <div class="col text-center">
                                            <div className="mt-3">
                                              <a className="btn btn-light mr-1" onClick={this.updateAddress}>
                                                <i className="fa fa-pencil-square-o mr-1 text-info" ></i>Update{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                              <a className="btn btn-light mr-1" onClick={this.goBack}>
                                                <i className="fa fa-times mr-1 text-info"></i> Cancel</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </AvForm>
                                    <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </Card>
                      </CardBody>
                    </TabPanel>

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col><Row className="ml-1">
                              <h2 className="font-bold m-2 text-lg">Change Password</h2></Row>
                            </Col>
                          </Row>
                          <div>
                            <div class="container">
                              <div class="row">
                                <div class="col-md-8 m-auto">

                                  <AvForm className='group'>
                                    <AvField name="old_password" label="Old Password" type="password" onChange={this.onChange} required className='form-input' />
                                    <AvField name="new_password" label="New Password" type="password" onChange={this.onChange} required className='form-input' />
                                    <AvField name="confirm_password" label="Confirm New Password" type="password" onChange={this.onChange} required className='form-input' />

                                    <div class="container">
                                      <div class="row">
                                        <div class="col text-center">
                                          <div className="mt-3">
                                            <a className="btn btn-light mr-1" onClick={this.UpdatePassword}>
                                              <i className="fa fa-pencil-square-o mr-1 text-info"></i>Update Password{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </AvForm>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                        </Card>
                      </CardBody>
                    </TabPanel>
                  </Tabs>
                </Cell>
              </Grid>
            </CardBody>
          </Card>
      </div>
    )
  }
}
export default UpdateEmployee;