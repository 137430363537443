import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table, Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import history from '../../history';
import CustomInput from "components/CustomInput/CustomInput";


class PaymentMode extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showStripe: true, showPayPal: false };
  }

  stripCall() {
    this.setState({
      showStripe: !this.state.showStripe
    })
  }
  paypalCall() {
    this.setState({
      showPayPal: !this.state.showPayPal
    })
  }

  render() {
    const { showStripe, showPayPal } = this.state;
    return (
      <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
          <CardHeader color="primary">
            <strong> <h4 className="font-weight-bold">Payment Modes</h4></strong>
          </CardHeader>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 >Stripe Payments</h4>
            </CardHeader>
            <CardBody>
              <Row >
                <Col className="mt-2" xs={3}>
                  <div>
                    <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Stripe Card Payment:</h5></strong>
                  </div>
                </Col>
                <Col className="mt-3" xs={6}>
                  <CustomInput onClick={() => this.stripCall()} defaultChecked={true} type="switch" id="exampleCustomSwitch" name="customSwitch" />
                </Col>
              </Row>
              {showStripe &&
                <div>
                  <Row >
                    <Col className="mt-2" xs={3}>
                      <div>
                        <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Secret key:</h5></strong>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <AvForm>
                        <AvField name="stripeKey" placeholder="Stripe key" type="input" required className='form-input' />
                      </AvForm>
                    </Col>
                  </Row>
                  <Row >
                    <Col className="mt-2" xs={3}>
                      <div>
                        <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Publishable key:</h5></strong>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <AvForm>
                        <AvField name="stripePublishableKey" placeholder="Stripe Publishable key" type="input" required className='form-input' />
                      </AvForm>
                    </Col>
                  </Row>
                </div>
              }
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 >PayPal Payments</h4>
            </CardHeader>
            <CardBody>
              <Row >
                <Col className="mt-2" xs={3}>
                  <div>
                    <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> PayPal Card Payment:</h5></strong>
                  </div>
                </Col>
                <Col className="mt-3" xs={6}>
                  <CustomInput onClick={() => this.paypalCall()} type="switch" id="paypalSwitch" name="customPaypalSwitch" />
                </Col>
              </Row>
              {showPayPal &&
                <div>
                  <Row >
                    <Col className="mt-2" xs={3}>
                      <div>
                        <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Secret key:</h5></strong>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <AvForm>
                        <AvField name="PayPalKey" placeholder="PayPal key" type="input" required className='form-input' />
                      </AvForm>
                    </Col>
                  </Row>
                  <Row >
                    <Col className="mt-2" xs={3}>
                      <div>
                        <strong> <h5 className="ml-2 text-dark" className="font-weight-bold mt-1"> Publishable key:</h5></strong>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <AvForm>
                        <AvField name="PayPalPublishableKey" placeholder="PayPal Publishable key" type="input" required className='form-input' />
                      </AvForm>
                    </Col>
                  </Row>
                </div>
              }
              <div className="pull-right">
                <Button color="secondary" size="sm" onClick={()=> {history.goBack()}}>Cancel</Button>{' '}
                <Button color="danger" size="sm">Update Payment Setting</Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    );
  }

}
export default PaymentMode;