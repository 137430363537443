import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { onGoingJobsByUser, getAllCompanies, addNewComplaint, addRatingForCompany } from '../../services/admin/Index';
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import 'date-fns';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Table, Button, Spinner , Pagination, PaginationItem, PaginationLink,Input } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import scroll from './scroll.css'
import ReactTooltip from "react-tooltip";
import { Grid, Cell } from 'react-mdl';
import Rating from '@material-ui/lab/Rating';
import StarsRating from 'stars-rating';
import { firestore } from "../../firebase/firebase";
import history from "../../history";
import Tooltip from '@material-ui/core/Tooltip';
import { ContactSupportOutlined } from "@material-ui/icons";

class CustomerOnGoingJobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden : false,
            hidden_send : false,
            allUserJobs: '',
            allCompanies: [],
            pagesCount: 0,
            currentPage: 0,
            pagesCount_company: 0,
            currentPage_company: 0,
            tooltipOpen: false,
            isOpen: false,
            showProgress: true,
            token: '',
            modalcount: 0,
            job_detail: 0,
            complaint: 0,
            hidden_complaint: false,
            title : '',
            description : '',
            modal_feedback : 0,
            user_id : '',
            rating : 0 ,
            sendmessage : 0,
            user_email: "",
            senderemail : '',
            message : '',
            job : '',
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeRate = this.onChangeRate.bind(this);
        this.showModel = this.showModel.bind(this);
        this.showModelComplaint = this.showModelComplaint.bind(this);
        this.showModelFeedback = this.showModelFeedback.bind(this);
        this.showDetailModel = this.showDetailModel.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickCompany = this.handleClickCompany.bind(this);
        this.addNewComplaint = this.addNewComplaint.bind(this);
        this.submitfeedback = this.submitfeedback.bind(this);
        this.showMessageModal = this.showMessageModal.bind(this);
    }

    async componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        const token = userInfo.token;
        const email = userInfo.data.email;
        const allUserJobs = await onGoingJobsByUser(this.state.currentPage + 1, token);
        if (allUserJobs.data.length != 0) {
            await this.setState({
                allUserJobs: allUserJobs,
                pagesCount: Math.ceil(allUserJobs.total / allUserJobs.data.length),
                showProgress: false,
                token: token,
                hidden: true,
                user_id : userInfo.data.id,
                user_email: email
            })
        } else {
            await this.setState({
                allUserJobs: allUserJobs,
                showProgress: false,
                token: token,
                hidden: true,
                user_id : userInfo.data.id,
                user_email: email
            })
        }
    }

    async onChange(e) {
        await this.setState({ [e.target.name]: e.target.value })
    }

    async onChangeRate(e) {
        await this.setState({ rating: e })
    }

    async handleClick(e, index) {
        e.preventDefault();
        const allUserJobs = await onGoingJobsByUser(index + 1, this.state.token);
        await this.setState({
            currentPage: index,
            allUserJobs: allUserJobs
        })
    }

    async handleClickCompany(e, index) {
        e.preventDefault();
        const allListCompanies = await getAllCompanies(index + 1, this.state.token);
        await this.setState({
            currentPage_company: index,
            allCompanies: allListCompanies
        })
    }

    async showModel(job) {
        await this.setState({ modalcount: 1,isOpen: true, job,job_detail:0 ,complaint: 0,modal_feedback : 0 });
    }

    async showModelComplaint(job) {
        await this.setState({ complaint: 1,isOpen: true, job,job_detail:0 ,modalcount: 0,modal_feedback : 0 });
    }

    async showModelFeedback(job) {
        await this.setState({ modal_feedback: 1,isOpen: true, job,job_detail:0 ,complaint: 0,modalcount : 0 });
    }

    async showDetailModel(job) {
        await this.setState({ job_detail: 1,isOpen: true, job,modalcount:0 ,complaint: 0,modal_feedback : 0 });
    }

    async closeModal() {
        await this.setState({ modalcount:0 ,job_detail:0 ,complaint: 0,modal_feedback : 0,isOpen: !this.state.isOpen, company: "" , sendmessage:0 });
    }

    async addNewComplaint(){
        try{
            await this.setState({hidden_complaint:true})
            const body = {
                title : this.state.title,
                description : this.state.description,
                job_id : this.state.job.id,
                token : this.state.token
            }
            const response = await addNewComplaint(body);
            //console.log(response)
            if (response.status == 200) {
                NotificationManager.success('Complaint Submitted Successfully');
                setTimeout(async() => await this.setState({ isOpen: false , hidden_complaint: false }), 1000);
            } else {
                NotificationManager.error('Something went wrong');
                setTimeout(async() => await this.setState({ isOpen: false , hidden_complaint: false }), 1000);
            }
        }catch(e){
            throw e;
        }
    }

    async submitfeedback() {
        try {
          await this.setState({ hidden_send : true })
          const body = {
            job_id : this.state.job.id,
            company_id : this.state.user_id,
            rating : this.state.rating,
            review : this.state.feedback,
            token : this.state.token
          }
          const response = await addRatingForCompany(body)
          //console.log(response)
          if (response.status == 200) {
            NotificationManager.success('Feedback Submitted Succesfuly');
            await this.setState({ hidden_send : false, isOpen: !this.state.isOpen,rating:0 });
            this.componentDidMount()
          } else {
            NotificationManager.error('Something went wrong');
            await this.setState({ hidden : false, isOpen: !this.state.isOpen,rating:0 });
          }
        } catch (e) {
          throw (e);
        }
    }

    async showMessageModal(){
        try {
            const { job } = this.state;
            const userExists = await this.userExists(job.assigned_to);
            const { user_email, senderemail, message } = this.state;
            if (userExists) {
                const chatExists = await this.chatExists();
                try {
                    if(!chatExists){
                        await this.setState({sendmessage: 1, modalcount:0 ,job_detail:0,isOpen: true})
                    }else{
                        NotificationManager.info("Chat Exist");
                        setTimeout(async() => await history.push("/customer/chats"), 1000);
                    } 
                } catch (e) {
                  console.log(e);
                }
            }else {
                NotificationManager.error("User Is Not Exists Please Check It");
            }
        } catch (e) {
            throw (e);
        }
        
    }

    async sendMessage() {
        try {
            const { user_email, senderemail, message } = this.state;
            if (message != ""){
                this.createChat(user_email,senderemail,message)
                NotificationManager.success("Message Sent Successfully!");
                this.closeModal()
                this.componentDidMount()
            }else{
                NotificationManager.error("Write something to send!");
            }
        } catch (e) {
            console.log(e);
            throw (e);
        }
    }

    userExists = async (company) => {
        const userSnapshot = await firestore.collection("users").get();
        try {
            let count = [];
            userSnapshot.docs
            .map((docs) => count.push(docs.data()))
            const countlen = count.length
            let found = false
            for(var i = 0; i < countlen; i++) {
                if (count[i].company_id == company) {
                    found = true
                    await this.setState({senderemail : count[i].email})
                    break;
                }
            }
            return found;
        } catch (e) {
          console.log(e);
          NotificationManager.error('Something went wrong');
        }
    };

    createChat = async (userEmail, senderEmail, msg) => {
        const docId = await this.buildId();
        const time = new Date().toLocaleTimeString("en-US", {
          hour: "numeric",
          hour12: true,
          minute: "numeric",
        });
        var today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const timestamp = Date.now();
        
        await firestore
            .collection("chats")
            .doc(docId)
            .set({
              docid: docId,
              time: timestamp,
              users: [userEmail, senderEmail],
              typing: [],
              messages: [
                {
                  message: msg,
                  sender: userEmail,
                  time: time+" "+date,
                  type: "text",
                },
              ],
        });
    };

    buildId = async () => {
        return [this.state.senderemail, this.state.user_email].sort().join(":");
    };

    chatExists = async () => {
        const docid = await this.buildId();
        const chat = await firestore.collection("chats").doc(docid).get();
        return chat.exists;
    };

    renderResultRows(allUserJobs) {
        return allUserJobs && this.state.allUserJobs.data.map((job, key) => {
            {
                return <tr key={key} >
                    <td scope="row">{job.id}  </td>
                    <td scope="row">{job.user_name}  </td>
                    <td scope="row">{job.address}  </td>
                    <td scope="row">{job.person}  </td>
                    <td scope="row">{job.job_desc}  </td>
                    <td scope="row">{job.status}  </td>
                    <td><span>
                        <div className="col-md-offset-6" style={{ width:65, height:5 }}>
                            <span style={{ cursor: "pointer"}} data-tip="Feedback" onClick={this.showModelFeedback.bind(this, job)}>
                                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                            </span>
                            <ReactTooltip />
                        </div>
                    </span></td>
                    <td><span>
                        <div className="col-md-6 col-md-offset-6">
                            <span style={{ cursor: "pointer" }} data-tip="Offers" onClick={this.showModel.bind(this, job)}> 
                                <i className="fa fa-building-o mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
                        <ReactTooltip />
                    </span></td>
                    <td><span>
                        <div className="col-md-6 col-md-offset-6">
                            <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showDetailModel.bind(this, job)}>
                                <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} /></span></div>
                        <ReactTooltip />
                    </span></td>
                </tr>
            }
        })
    }

    render() {
        const { allUserJobs, job, isOpen, hidden } = this.state;

        return (
            <GridContainer>
                <NotificationContainer />
                <GridItem xs={12} sm={12} md={12}>
                    <Card style={{"min-width":"270px"}}>
                        <CardHeader color="primary">
                            <Row className="ml-2">
                            <h4>On Going Jobs</h4></Row>
                            <Row className="ml-2">
                            <p>
                                All on Going Jobs record
                            </p></Row>
                        </CardHeader>
                        {!hidden &&
                        <CardBody>
                            <div style={{
                            position: 'absolute', left: '50%', top: '10%',
                            }} xs={12}><Spinner style={{ background: "text-success", width: '3rem', height: '3rem' }} value={false} /></div>
                        </CardBody>
                        }
                        {hidden &&
                        <CardBody>
                            <Table bordered responsive class={scroll}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Address</th>
                                        <th>Person</th>
                                        <th>Job Description</th>
                                        <th>Job Status</th>
                                        <th>Feedback</th>
                                        {/* <th>Complaint</th> */}
                                        <th>Offers</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        allUserJobs && this.renderResultRows(allUserJobs)
                                    }
                                    {
                                        allUserJobs && _.size(allUserJobs.data) === 0 && <tr><td scope="row">{"No Records Found"}  </td></tr>
                                    }

                                </tbody>
                            </Table>
                            <nav>
                                <Pagination className="pull-right mt-3" size="md" >

                                    <PaginationItem disabled={this.state.currentPage <= 0}>

                                        <PaginationLink
                                            onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                                            previous
                                            href="#"
                                        />

                                    </PaginationItem>

                                    {[...Array(this.state.pagesCount)].map((page, i) =>
                                        <PaginationItem active={i === this.state.currentPage} key={i}>
                                            <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                                {i + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    )}

                                    <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>

                                        <PaginationLink
                                            onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                                            next
                                            href="#"
                                        />

                                    </PaginationItem>

                                </Pagination>
                            </nav>

                            <Modal isOpen={isOpen} toggle={this.closeModal}>
                                {this.state.modalcount == 1 && <div>
                                    <ModalHeader ><strong>Offering Companies</strong></ModalHeader>

                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <Grid>
                                                <Cell >
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Row className="ml-2">
                                                            <h4 className="font-bold m-2 text-teal-500 pull-left">{this.state.job.name}</h4></Row>
                                                            <Row className="ml-2">
                                                            <Rating className="m-2" name="read-only" value={this.state.job.company_rating} readOnly /></Row>
                                                            <br />

                                                        </Col>
                                                        <Col xs={6}>
                                                            <Tooltip title="Message">
                                                                <Button  className="fa fa-commenting"
                                                                    color="primary"
                                                                    style={{ textAlign: 'center', borderRadius: 60,"margin-top": "10%" }}
                                                                    onClick ={this.showMessageModal} 
                                                                    ></Button>
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </Cell>
                                            </Grid>
                                        </Col>
                                    </ModalBody></div>
                                }
                                {this.state.job_detail==1 && 
                                <div>
                                    <ModalHeader ><strong>Job details</strong></ModalHeader>

                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='8' sm='12' xs='12'>
                                            <span><strong className="text-dark">User Name :</strong> {job && job.user_name}</span>
                                        </Col>

                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='8' sm='12' xs='12'>
                                            <span><strong className="text-dark">Address :</strong> {job && job.address}</span>
                                        </Col>

                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='8' sm='12' xs='12'>
                                            <span><strong className="text-dark">Person :</strong> {job && job.person}</span>
                                        </Col>

                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <span><strong className="text-dark text-justify">Job Description :</strong> {job && job.job_desc}</span>
                                        </Col>
                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> Start Time :</strong>{job && job.start_time}</span>
                                            </Col>
                                        </div>
                                        <div className='d-flex bg-light mb-2'>
                                            <Col lg='12' md='12' sm='12' xs='12'>
                                                <span><strong className="text-dark"> End Time :</strong>{job && job.end_time}</span>
                                            </Col>
                                        </div>
                                        </Col>
                                    </ModalBody>

                                    </div>}
                                {this.state.complaint == 1 && <div>
                                    <ModalHeader ><strong>Complaint</strong></ModalHeader>
                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <Grid>
                                            <Cell >
                                                <Row><label className="ml-2">Title</label></Row>
                                                <Row>
                                                    <Input name="title" className="ml-2" type="text" onChange={this.onChange} />
                                                </Row>
                                                <Row><label className="ml-2 mt-2">Description</label></Row>
                                                <Row>
                                                    <Input name="description" className="ml-2" type="textarea" rows={3} onChange={this.onChange} />
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    <Col><Button className="m-2 ml-4" color="primary" borderColor="transparent" onClick={this.addNewComplaint}>Submit{this.state.hidden_complaint && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</Button></Col>
                                                    <Col></Col>
                                                </Row>
                                                </Cell>
                                            </Grid>
                                            </Col>
                                        </ModalBody></div>
                                }
                                {this.state.modal_feedback==1 && 
                                    <div>
                                        <Modal isOpen={isOpen} toggle={this.closeModal}>
                                        <ModalHeader ><strong>Feedback</strong></ModalHeader>
                                        <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <Grid>
                                            <Cell >
                                                <Row>
                                                <Col>
                                                    <h4 className="font-bold m-2 text-teal-500 pull-left">{this.state.job.name}</h4>
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col><label className="ml-2">Rating</label></Col>
                                                
                                                <Col>
                                                        <StarsRating
                                                            count={5}
                                                            value={this.state.rating}
                                                            onChange={this.onChangeRate}
                                                            size={20}
                                                            half={false}
                                                            color2={'#ffd700'} />
                                                    
                                                </Col>
                                                
                                                </Row>
                                                <Row><label className="ml-4">Feedback</label></Row>
                                                <Row>
                                                    <Input name="feedback" className="ml-4" type="textarea" rows={3} onChange={this.onChange} />
                                                </Row>
                                                </Cell>
                                            </Grid>
                                            </Col>
                                        </ModalBody>
                                        <ModalFooter>
                                        <Button color="secondary" onClick={this.closeModal}>Close</Button>
                                        <Button className="m-2 ml-4" color="primary" borderColor="transparent" onClick={this.submitfeedback}>Submit{this.state.hidden_send && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>}
                                {this.state.sendmessage == 1 && <div>
                                    <ModalHeader ><strong>Send Message</strong></ModalHeader>

                                    <ModalBody>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                        <Row>
                                            <Input name="message" 
                                                className="ml-2" 
                                                type="textarea" 
                                                rows={3} 
                                                onChange={this.onChange} />
                                        </Row>
                                        <Row>
                                            <br></br>
                                        </Row>
                                        <Row>
                                            <Button className="align-center" 
                                                style={{"margin-left":"auto","margin-right":"auto"}} 
                                                color="primary"
                                                onClick={this.sendMessage}>Send</Button>
                                        </Row>
                                        </Col>
                                    </ModalBody>
                                </div>}
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.closeModal}>Close</Button>
                                </ModalFooter>
                            </Modal>
                        </CardBody>
                        }
                    </Card>
                </GridItem>
                
            </GridContainer>
        );
    }

}
export default CustomerOnGoingJobs;