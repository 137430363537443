import { fire, firestore } from "../../../firebase/firebase";
import React from "react";
import { Row, Input } from "reactstrap";
import profile from "../../../assets/img/profile.png"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { CollectionsOutlined } from "@material-ui/icons";

class ChatRoom extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            user_email : "",
            oponentUserData: [],
            oponentUserEmail: "",
            textMsg: "",
            showProfile: false,
            showMoreDialogBox: false,
            unsendIndex: "",
            currentUserDocId: "",
            isOponentTyping: false,
            showImageBeforeUpload: false,
            imgFile: "",
            currentSelectedImg: "",
            loading: false,
            imgName: "",
            msgType: "",
            progress: 0,
            oldChatsLength: [],
        };
    };

    componentDidMount = async () => {
        const UserInfo = JSON.parse(localStorage.getItem("userData"));
        const email = UserInfo.data.email;
        await this.setState({ user_email : email})
        await this.getOponentUserInfo();
        try {
          await this.getTypingData();
        } catch (e) {
          console.log(e);
        }
        await this.snapshotOnCall();
    
        await this.setState({ oldChats: this.props.chatData.messages.length });
    
        var time = setTimeout(async () => {
          await this.scrollingToEnd();
          clearTimeout(time);
        }, 1000);
    };

    componentWillUnmount = async() => {
        await this.typing();
    };

    componentDidUpdate = async() => {
        const chat = this.props.chatData.messages.length;
        const { oldChats } = this.state;
        if(chat!== oldChats){
            await this.isChatChanged(chat);
        }
    };

    getOponentUserInfo = async() => {
        const {user_email} = this.state;
        const userData = [];

        var fetchEmail = this.fetchEmail(user_email);

        await this.props.allUserData.map((user) => {
            if (user.email === fetchEmail){
                userData.push(user)
            }
        });
        await this.setState({
            oponentUserData: userData[0],
            oponentUserEmail: fetchEmail,
        });
    };

    fetchEmail = (userEmail) => {
        const { users } = this.props.chatData;
        return users[0] !== userEmail ? users[0] : users[1]
    };

    getTypingData = async() => {
        const { docid } = this.props.chatData;
        const { oponentUserEmail } = this.state;

        try {
            await firestore
                .collection("chats")
                .doc(docid)
                .onSnapshot(async (snapshot) => {
                if (snapshot.data()) {
                    const typing = snapshot.data().typing;
                    if (typing.includes(oponentUserEmail)) {
                        await this.setState({ isOponentTyping: true });
                    } else {
                        await this.setState({ isOponentTyping: false });
                    }
                }
            });
        }catch(e){
            console.log(e)
        };
    };

    snapshotOnCall = async() => {
        const { oponentUserEmail } = this.state;
        await firestore.collection("users").where("email","==",oponentUserEmail)
            .onSnapshot (async()=>{
                await this.getOponentUserInfo();
            });
    };

    isChatChanged = async(chat) => {
        await this.scrollingToEnd();
        await this.setState({ oldChats: chat })
    };

    scrollingToEnd = async() => {
        try{
            const scroll = document.getElementById("scrolling");
            
            scroll.scrollTop = scroll.scrollHeight;
        }catch(e){
            console.log(e);
        }
    };

    typing = async(value) => {
        const { docid } = this.props.chatData;
        const { user_email } =  this.state;

        let finalData = await this.getCurrentTypingData(docid);
        if(value){
            if(!finalData.includes(user_email)){
                await finalData.push(user_email);
            }
        } else {
            finalData = finalData.filter((obj)=>{
                if(obj !== user_email){
                    return obj;
                }
            })
        }
        await firestore.collection("chats").doc(docid).update({
            typing: finalData,
          });
    };

    getCurrentTypingData = async(docid) => {
        const array = [];

        try {
            const data = await firestore.collection("chats").doc(docid)
                            .get()
                            .then((obj)=>{
                                return obj.data().typing;
                            });
            return data;
        }catch(e){
            console.log(e);
            return [];
        }
    };

    sendMessage = async () => {
        const { docid } = this.props.chatData;
        const { user_email, textMsg } = this.state;

        const time = new Date().toLocaleTimeString("en-Us",{
            hour: "numeric",
            hour12: true,
            minute: "numeric"
        });
        var today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const timestamp = Date.now();

        if(textMsg){
            await firestore.collection("chats").doc(docid)
                    .update({
                        messages: fire.firestore.FieldValue.arrayUnion({
                            sender : user_email,
                            message: textMsg,
                            time: time+" "+date,
                            type: "text"
                        }),
                        time: timestamp,
                    });
            NotificationManager.success("Meessage Sent")
            await this.scrollingToEnd();
            await this.setState({textMsg: ""})
            
        }else {
            NotificationManager.error("Please Enter Valid Message");
        }
    };

    render(){
        const { oponentUserData, oponentUserEmail } = this.state;
        return (<div style={{width:"100%"}}>
            <NotificationContainer />
            <div class="mesgs" >
                  <div class="msg_history" id="scrolling">
                  {this.props.chatData.messages.map((list,index) => (
                      <div key={index}>
                          {list.sender === oponentUserEmail ?( <div>
                            <div class="incoming_msg">
                                <div class="incoming_msg_img" style={{ "min-width" : "25px" }}> 
                                    <img className="rounded-circle" src={oponentUserData.img_url!="" ? oponentUserData.img_url : profile}
                                        style={{ borderRadius: 60, "max-height": "25px", "min-height" : "25px"
                                        ,"max-width": "25px", "min-width" : "25px" }} /> </div>
                                <div class="received_msg">
                                    <div class="received_withd_msg">
                                    <p>{list.message}</p>
                                    <span class="time_date">{list.time}</span></div>
                                </div>
                            </div>
                             </div>) : (
                                <div>
                                    <div class="outgoing_msg">
                                        <div class="sent_msg" >
                                            <p>{list.message}</p>
                                            <span class="time_date"> {list.time}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>))}
                    
                  </div>
                  <div class="type_msg">
                    <div class="input_msg_write">
                      <input type="textarea"
                            name = "textMsg"
                            class="write_msg" 
                            placeholder="Type a message"
                            value={this.state.textMsg}
                            onChange={(e) => {
                                this.setState({ textMsg: e.target.value });
                                this.typing(e.target.value);
                              }}/>
                      <button className="m-2" 
                            class="msg_send_btn" 
                            type="button"
                            onClick={() => this.sendMessage()}>
                                <i class="fa fa-paper-plane-o" aria-hidden="true">
                                </i>
                        </button>
                    </div>
                  </div>
                </div>
                </div>
        )
    }
}

export default ChatRoom;