import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {StripeProvider,Elements} from 'react-stripe-elements'
import StripePayment from '../StripePayment/StripePayment';

// import { purchaseToken } from '../../services/admin/Index';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table, Row, Col, Input,Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { lowerCase } from "lodash";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import _ from "lodash";

class PurchaseTokenAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.purchase = this.purchase?.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      count: 0,
      allTokens : [],
      tokens: '',
      quantity: '',
      token: ''
    };
    
  }

  async componentWillMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    await this.setState({
      allTokens : [],
      tokens: '',
      quantity: '',
      token: token})
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  // async purchase(){
  //   const body = {
  //     tokens: this.state.tokens,
  //     quantity : this.state.quantity,
  //     token : this.state.token
  //   }
  //   console.log(body)
  //   this.setState({
  //     count : 1,
  //   })
  //   const response = await purchaseToken(body);
  //   console.log(response)
  //   await this.componentWillMount()
  //   if (response.status >= 400) {
  //     NotificationManager.error('Something went wrong');
  //   } else {
  //     NotificationManager.success('Updated Succesfuly');
  //   }
  // }

  render() {
    const { allNotifications, isOpen } = this.state;
    return (
      <dib>
       <GridContainer>
        <NotificationContainer />
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 >Purchase Token</h4>
            </CardHeader>
            <CardBody>
                <div class="container">
                  <div class="row">
                    <div class="col text-center">
                        <Row >
                            <Col className="mt-2" xs={2}>
                                <div>
                                    <strong> <h5 className="ml-2 text-dark">Tokens :</h5></strong>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <AvForm >
                                <AvField type="select" name="tokens" value={this.state.tokens} required onChange={this.onChange}>
                                    {<option>$</option>}
                                    <option>5 $</option>
                                    <option>10 $</option>
                                    <option>11 $</option>
                                </AvField>
                                </AvForm>
                            </Col>
                        </Row>

                        <Row >
                        <Col className="mt-2" xs={2}>
                            <div>
                            <strong> <h5 className="ml-2 text-dark">Quantity :</h5></strong>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <AvForm>
                            <AvField name="quantity" placeholder="Quantity" type="input" value={this.state.quantity} onChange={this.onChange} required className='form-input' />
                            </AvForm>

                        </Col>
                        </Row>

                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <a className="btn btn-light mr-2" onClick={this.purchase}>
                                        <i className="fa fa-money mr-2 text-info"></i> Purchase </a>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                

            </CardBody>
          
          </Card>
        </GridItem>
      </GridContainer> 
      
        {/* <StripeProvider apiKey="pk_test_rjENfJoCZDGq9GuKaZ8JhpzD00WjAlSrTD">
          <Elements>
              <StripePayment />
          </Elements>
        </StripeProvider> */}
      
      </dib>
    );
  }

}
export default PurchaseTokenAdmin;