/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./store/index";
import history from "./history/index";

// core components
import Admin from "layouts/Admin.js";
//import Admin from "././views/Dashboard/Dashboard";
import Login from "layouts/Login.js";
import Customer from "layouts/Customer.js";
import Company from "layouts/Company.js";
import Employ from "layouts/Employee.js";
import PrivateRoute from "./components/PrivateRoute";

import Messages from "././views/Messages/messages";
import Payment from "././views/Payments/payment";
import Companies from "././views/Companies/companies";
import Employess from "././views/Empolyees/employees";
import EmployProfile from "././views/employProfile/employProfile";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import "react-notifications/lib/notifications.css";
import SignUp from "./layouts/SignUp";
import ForgotPassword from "./layouts/ForgotPassword";
import EmployeesForm from "./layouts/EmployeesForm";
import Employee from "layouts/Employee";
import SaaSProductLandingPage from "./demos/SaaSProductLandingPage";
import Career from "./demos/Career";
import Policy from "./demos/Policy";
import Conditions from "./demos/Conditions";
import Disclaimer from "./demos/Disclaimer";
import Gdpr from "./demos/Gdpr";
import Blog from "./demos/Blog";
import Faq from "./demos/Faq";
import Support from "./demos/Support";
import UserRegistration from "./signup-pages/UserSignup/Registration";
import CompanyRegistration from "./signup-pages/CompanySignup/Registration";
import EmployeeRegistration from "./signup-pages/EmploySignup/Registration";
import UserLogin from "./login-pages/UserLogin/Login";
import CompanyLogin from "./login-pages/CompanyLogin/Login";
import EmployeeLogin from "./login-pages/EmployLogin/Login";

ReactDOM.render(
  <Provider store={store}>
    <HashRouter history={history}>
      <Switch>
        <Route exact path="/home" component={SaaSProductLandingPage} />
        <PrivateRoute path="/admin" component={Admin} />
        <PrivateRoute path="/customer" component={Customer} />
        <PrivateRoute path="/company" component={Company} />
        <PrivateRoute path="/employ" component={Employ} />
        <Route path="/login" component={Login} />
        <Route path="/company-signup" component={CompanyRegistration} />
        <Route path="/user-signup" component={UserRegistration} />
        <Route path="/employee-signup" component={EmployeeRegistration} />
        <Route path="/company-login" component={CompanyLogin} />
        <Route path="/user-login" component={UserLogin} />
        <Route path="/employee-login" component={EmployeeLogin} />
        <Route path="/employee-login" component={EmployeeLogin} />

        <Route path="/logout" component={Login} />
        <Route path="/career" component={Career} />
        <Route path="/policy" component={Policy} />
        <Route path="/faq" component={Faq} />
        <Route path="/condition" component={Conditions} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/gdpr" component={Gdpr} />
        <Route path="/blog" component={Blog} />
        <Route path="/support" component={Support} />
        <Route path="/messages" component={Messages} />
        <Route path="/payment" component={Payment} />
        <Route path="/Companies" component={Companies} />
        <Route path="/employess" component={Employess} />
        <Route path="/signup" component={SignUp} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/employProfile" component={EmployProfile} />
        <Route path="/employeesform" component={EmployeesForm} />
        <Redirect from="/" to="/home" />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
