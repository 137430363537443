import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table } from 'reactstrap';
import { getAllAssignedJobsByCompany,addRatingForUser } from '../../services/admin/Index';
import _ from "lodash";
import scroll from './scroll.css'
import ReactTooltip from "react-tooltip";
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Button, Spinner, Pagination, PaginationItem, PaginationLink, Input } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Grid, Cell } from 'react-mdl';
import StarsRating from 'stars-rating'
import { auth, firestore } from "../../firebase/firebase";
import history from '../../history';

class CompanyAssignedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      allUserJobs: '',
      pagesCount: 0,
      currentPage: 0,
      tooltipOpen: false,
      isOpen: false,
      isOpenDetails: false,
      showProgress: true,
      token: '',
      hidden: false,
      hidden_send: false,
      rating: 0,
      feedback: '',
      company : '',
      sendmessage : 0,
      user_email: "",
      senderemail : '',
      message : '',
      detail : 0
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeRate = this.onChangeRate.bind(this);
    this.showModel = this.showModel.bind(this);
    this.showModelDetails = this.showModelDetails.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalDetails = this.closeModalDetails.bind(this);
    this.submitfeedback = this.submitfeedback.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.showMessageModal = this.showMessageModal.bind(this);
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const token = userInfo.token;
    const email = userInfo.data.email
    const allUserJobs = await getAllAssignedJobsByCompany(this.state.currentPage + 1, token);
    if (allUserJobs.data.length != 0) {
      await this.setState({
        allUserJobs: allUserJobs,
        pagesCount: Math.ceil(allUserJobs.total / allUserJobs.data.length),
        showProgress: false,
        company : userInfo,
        token: token, 
        hidden: true,
        user_email: email
      })
    } else {
      await this.setState({
        allUserJobs: allUserJobs,
        showProgress: false,
        company : userInfo,
        token: token, 
        hidden: true,
        user_email: email
      })
    }
  }

  async handleClick(e, index) {
    e.preventDefault();
    const allUserJobs = await getAllAssignedJobsByCompany(index + 1, this.state.token);
    this.setState({
      currentPage: index,
      allUserJobs: allUserJobs
    })

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeRate(e) {
    this.setState({ rating: e })
}

  showModel(job) {
    this.setState({ isOpen: true,  job });
  }

  closeModal() {
    this.setState({ isOpen: !this.state.isOpen , sendmessage : 0 , detail: 0 });
  }

  showModelDetails(job) {
    this.setState({ isOpenDetails: true, detail: 1, job });
  }

  closeModalDetails() {
    this.setState({ isOpenDetails: false, sendmessage : 0 , detail: 0});
  }

  async submitfeedback() {
    try {
      this.setState({ hidden_send : true })
      const body = {
        job_id : this.state.job.id,
        user_id : this.state.company.data.id,
        rating : this.state.rating,
        review : this.state.feedback,
        token : this.state.token
      }
      const response = await addRatingForUser(body)
      //console.log(response)
      if (response.status == 200) {
        NotificationManager.success('Feedback Submitted Succesfuly');
        this.setState({ hidden_send : false, isOpen: !this.state.isOpen,rating:0 });
        this.componentDidMount()
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden : false, isOpen: !this.state.isOpen,rating:0 });
      }
    } catch (e) {
      throw (e);
    }
  };

  async showMessageModal(){
    try {
        const { job } = this.state;
        const userExists = await this.userExists(job.user_id);
        if (userExists) {
          const chatExists = await this.chatExists();
          try {
              if(!chatExists){
                  await this.setState({sendmessage: 1, modalcount:0 })
              }else{
                  NotificationManager.info("Chat Exist");
                  setTimeout(async() => await history.push("/company/chats"), 1000);
              } 
          } catch (e) {
            console.log(e);
          }
      }else {
          NotificationManager.error("User Is Not Exists Please Check It");
      }
    } catch (e) {
        throw (e);
    }
  }

  async sendMessage() {
      try {
          const { user_email, senderemail, message } = this.state;
          if (message != ""){
              this.createChat(user_email,senderemail,message)
              NotificationManager.success("Message Sent Successfully!");
              this.closeModal()
              this.componentDidMount()
          }else{
              NotificationManager.error("Write something to send!");
          }
      } catch (e) {
          throw (e);
      }
  }

  userExists = async (customer) => {
      const userSnapshot = await firestore.collection("users").get();
      try {
          let count = [];
          userSnapshot.docs
          .map((docs) => count.push(docs.data()))
          const countlen = count.length
          let found = false
          for(var i = 0; i < countlen; i++) {
              if (count[i].user_id == customer) {
                  found = true
                  await this.setState({senderemail : count[i].email})
                  break;
              }
          }
          return found;
      } catch (e) {
        console.log(e);
        NotificationManager.error('Something went wrong');
      }
  };

  createChat = async (userEmail, senderEmail, msg) => {
      const docId = await this.buildId();
      const time = new Date().toLocaleTimeString("en-US", {
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      });
      var today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const timestamp = Date.now();
      
      await firestore
          .collection("chats")
          .doc(docId)
          .set({
            docid: docId,
            time: timestamp,
            users: [userEmail, senderEmail],
            typing: [],
            messages: [
              {
                message: msg,
                sender: userEmail,
                time: time+" "+date,
                type: "text",
              },
            ],
      });
  };

  buildId = async () => {
      return [this.state.senderemail, this.state.user_email].sort().join(":");
  };

  chatExists = async () => {
      const docid = await this.buildId();
      const chat = await firestore.collection("chats").doc(docid).get();
      return chat.exists;
  };

  renderRows(allUserJobs) {
    return allUserJobs && this.state.allUserJobs.data.map((user, key) => {
      {
        return <tr key={key}>
          <td scope="row">{user.id}  </td>
          <td scope="row">{user.user_name}  </td>
          <td scope="row">{user.address}  </td>
          <td scope="row">{user.person}  </td>
          <td scope="row">{user.status}  </td>
          <td><span>
            <div className="col-md-offset-6" style={{ width:65, height:5 }}>
              <span style={{ cursor: "pointer"}} data-tip="Feedback" onClick={this.showModel.bind(this, user)}>
                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
                <i className="fa fa-star text-info" color="secondary" id={'review-' + key} />
              </span>
              <ReactTooltip />
            </div>
          </span>
          </td>
          <td><span>
            <div className="col-md-6 col-md-offset-6">
              <span style={{ cursor: "pointer" }} data-tip="Details" onClick={this.showModelDetails.bind(this, user)}>
                <i className="fa fa-table mr-2 text-info" color="secondary" id={'details-' + key} />
              </span>
              <ReactTooltip />
            </div>
          </span>
          </td>
        </tr>
      }

    })
  }

  render() {
    const { allUserJobs, isOpen,isOpenDetails, hidden } = this.state;
    return (
      <GridContainer>
        <NotificationContainer />
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{"min-width":"270px"}}>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4>Assigned Jobs</h4>
              </Row>
              <Row className="ml-2">
                <p>All Assigned Jobs record</p>
              </Row>
            </CardHeader>
            {!hidden &&
              <CardBody>
                <div style={{
                  position: 'absolute', left: '50%', top: '10%',
                }} xs={12}></div>
              </CardBody>
            }
            {hidden && <CardBody>
              <Table bordered responsive class={scroll}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Person</th>
                    <th>Job Status</th>
                    <th>Feedback</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allUserJobs && this.renderRows(allUserJobs)
                  }
                  {
                    allUserJobs && _.size(allUserJobs.data) === 0 && <td scope="row">{"No Records Found"}  </td>
                  }
                </tbody>
              </Table>
              <nav>
                <Pagination className="pull-right mt-3" size="md">
                  <PaginationItem disabled={this.state.currentPage <= 0}>
                    <PaginationLink
                      onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                      previous
                      href="#" />
                  </PaginationItem>
                  {[...Array(this.state.pagesCount)].map((page, i) =>
                    <PaginationItem active={i === this.state.currentPage} key={i}>
                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount - 1}>
                    <PaginationLink
                      onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>
              </nav>
              <Modal isOpen={isOpen} toggle={this.closeModal}>
                <ModalHeader ><strong>Feedback</strong></ModalHeader>
                <ModalBody>
                  <Col lg='12' md='12' sm='12' xs='12'>
                    <Grid>
                      <Cell >
                        <Row>
                          <Col>
                            <h4 className="font-bold m-2 text-teal-500 pull-left">{this.state.job && this.state.job.user_name}</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col><label className="ml-2">Rating</label></Col>
                          <Col>
                          <StarsRating
                                                            count={5}
                                                            value={this.state.rating}
                                                            onChange={this.onChangeRate}
                                                            size={20}
                                                            half={false}
                                                            color2={'#ffd700'} />
                          </Col>
                          </Row>
                          <Row><label className="ml-4">Feedback</label></Row>
                          <Row>
                            <Input name="feedback" className="ml-4" type="textarea" rows={3} onChange={this.onChange} />
                          </Row>
                        </Cell>
                      </Grid>
                    </Col>
                  </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.closeModal}>Close</Button>
                  <Button className="m-2 ml-4" color="primary" borderColor="transparent" onClick={this.submitfeedback}>Send{this.state.hidden_send && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={isOpenDetails} toggle={this.closeModalDetails}>
                {this.state.detail == 1 && <div>
                  <ModalHeader ><strong>Job Details</strong></ModalHeader>
                  <ModalBody>
                    <Col lg='12' md='12' sm='12' xs='12'>
                      <div className='d-flex bg-light mb-2'>
                        <Col lg='12' md='8' sm='12' xs='12'>
                          <span><strong className="text-dark">User Name :</strong> {this.state.job && this.state.job.user_name}</span>
                        </Col>
                      </div>
                      <div className='d-flex bg-light mb-2'>
                        <Col lg='12' md='8' sm='12' xs='12'>
                          <span><strong className="text-dark">Address :</strong> {this.state.job && this.state.job.address}</span>
                        </Col>
                      </div>
                      <div className='d-flex bg-light mb-2'>
                        <Col lg='12' md='8' sm='12' xs='12'>
                          <span><strong className="text-dark">Person :</strong> {this.state.job && this.state.job.person}</span>
                        </Col>
                      </div>
                      <div className='d-flex bg-light mb-2'>
                        <Col lg='12' md='12' sm='12' xs='12'>
                          <span><strong className="text-dark text-justify">Job Description :</strong> {this.state.job && this.state.job.job_desc}</span>
                        </Col>
                      </div>
                      <div className='d-flex bg-light mb-2'>
                                              <Col lg='12' md='12' sm='12' xs='12'>
                                                  <span><strong className="text-dark"> Start Time :</strong>{this.state.job && this.state.job.start_time}</span>
                                              </Col>
                                          </div>
                                          <div className='d-flex bg-light mb-2'>
                                              <Col lg='12' md='12' sm='12' xs='12'>
                                                  <span><strong className="text-dark"> End Time :</strong>{this.state.job && this.state.job.end_time}</span>
                                              </Col>
                                          </div>
                    </Col>
                  </ModalBody>
                </div>}
                {this.state.sendmessage == 1 && <div>
                    <ModalHeader ><strong>Send Message</strong></ModalHeader>
                    <ModalBody>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <Row>
                                <Input name="message" 
                                        className="ml-2" 
                                        type="textarea" 
                                        rows={3} 
                                        onChange={this.onChange} />
                            </Row>
                            <Row> <br></br>
                            </Row>
                            <Row>
                                <Button className="align-center" 
                                        style={{"margin-left":"auto","margin-right":"auto"}} 
                                        color="primary"
                                        onClick={this.sendMessage}>Send</Button>
                            </Row>
                        </Col>
                    </ModalBody>
                </div>}
                <ModalFooter>
                  {this.state.sendmessage!==1 && <Button color="primary" onClick={this.showMessageModal}>Message</Button>}
                  <Button color="secondary" onClick={this.closeModalDetails}>Close</Button>
                </ModalFooter>
              </Modal>
            </CardBody>
            }
          </Card>
        </GridItem>

      </GridContainer>
    );
  }

}
export default CompanyAssignedJobs;