import firebase from "firebase";

// var firebaseConfig = {
//     apiKey: "AIzaSyDPDObQUyOnFdNNWUvQkOcZ2D5sk5iba_Y",
//     authDomain: "greatpatriots-messenger.firebaseapp.com",
//     projectId: "greatpatriots-messenger",
//     storageBucket: "greatpatriots-messenger.appspot.com",
//     messagingSenderId: "597853123684",
//     appId: "1:597853123684:web:74b45afc02db8eb3f99b71",
//     measurementId: "G-00VKHMQ5DN"
//   };


const firebaseConfig = {
  apiKey: "AIzaSyB6tccys2ebXS7T4RrYFOha9ltXZsu3e6Y",
  authDomain: "gpsrecruiter-1b639.firebaseapp.com",
  databaseURL: "https://gpsrecruiter-1b639-default-rtdb.firebaseio.com",
  projectId: "gpsrecruiter-1b639",
  storageBucket: "gpsrecruiter-1b639.appspot.com",
  messagingSenderId: "705781158930",
  appId: "1:705781158930:web:fc923761fba7c88b8074cb",
  measurementId: "G-FTWHN2BZ19"
};
firebase.initializeApp(firebaseConfig);

export const fire = firebase;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();