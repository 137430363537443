import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Store from "@material-ui/icons/Store";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import {
  Button,
  ButtonGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { Table, Row, Col } from "reactstrap";
import { getStatement, getStatementStats } from "../../services/admin/Index";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import _ from "lodash";

class Statements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statementType: 1,
      overall: "",
      monthly: "",
      yearly: "",
      daily: "",
      currentPage: 0,
      currentTableName: "overall",
      currentTable: "",
      downloadType: null,
      pagesCount: 0,
      token: "",
      hidden: false,
      hiddenSpin: true,
    };
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const token = userInfo.token;
    const daily = await getStatementStats("daily", token);
    const monthly = await getStatementStats("monthly", token);
    const yearly = await getStatementStats("yearly", token);
    const overall = await getStatementStats("overall", token);
    const statements = await getStatement(
      this.state.currentTableName,
      this.state.currentPage + 1,
      token
    );
    if (statements.data.length != 0) {
      await this.setState({
        daily: daily.data[0],
        monthly: monthly.data[0],
        yearly: yearly.data[0],
        overall: overall.data[0],
        currentTable: statements,
        pagesCount: Math.ceil(statements.total / statements.data.length),
        token: token,
        hidden: true,
        hiddenSpin: true,
      });
    } else {
      await this.setState({
        daily: daily.data[0],
        monthly: monthly.data[0],
        yearly: yearly.data[0],
        overall: overall.data[0],
        currentTable: statements,
        token: token,
        hidden: true,
        hiddenSpin: true,
      });
    }
  }

  async handleClick(e, index) {
    e.preventDefault();
    const statements = await getStatement(
      this.state.currentTableName,
      this.state.currentPage + 1,
      this.state.token
    );
    this.setState({
      currentPage: index,
      currentTable: statements,
    });
  }

  renderResultRows(currentTable) {
    const no = 0;
    return (
      currentTable &&
      this.state.currentTable.data.map((data, key) => {
        {
          return (
            <tr key={key}>
              <td scope="row">{no + 1} </td>
              <td scope="row">{data.name} </td>
              <td scope="row">{data.amount} </td>
              <td scope="row">{data.tokens} </td>
              <td scope="row">{data.phone} </td>
            </tr>
          );
        }
      })
    );
  }

  render() {
    const { statementType, downloadType } = this.state;
    const useStyles = makeStyles(styles);
    const { classes } = useStyles;
    let dailytoken = 0;
    let dailyamount = 0;
    let dailytotal = 0;
    if (this.state.daily.tokens != null) {
      dailytoken = this.state.daily.tokens;
    }
    if (this.state.daily.amount != null) {
      dailyamount = this.state.daily.amount;
    }
    if (this.state.daily.total != null) {
      dailytotal = this.state.daily.total;
    }
    let monthlytoken = 0;
    let monthlyamount = 0;
    let monthlytotal = 0;
    if (this.state.monthly.tokens != null) {
      monthlytoken = this.state.monthly.tokens;
    }
    if (this.state.monthly.amount != null) {
      monthlyamount = this.state.monthly.amount;
    }
    if (this.state.monthly.total != null) {
      monthlytotal = this.state.monthly.total;
    }
    let yearlytoken = 0;
    let yearlyamount = 0;
    let yearlytotal = 0;
    if (this.state.yearly.tokens != null) {
      yearlytoken = this.state.yearly.tokens;
    }
    if (this.state.yearly.amount != null) {
      yearlyamount = this.state.yearly.amount;
    }
    if (this.state.yearly.total != null) {
      yearlytotal = this.state.yearly.total;
    }
    let overalltoken = 0;
    let overallamount = 0;
    let overalltotal = 0;
    if (this.state.overall.tokens != null) {
      overalltoken = this.state.overall.tokens;
    }
    if (this.state.overall.amount != null) {
      overallamount = this.state.overall.amount;
    }
    if (this.state.overall.total != null) {
      overalltotal = this.state.overall.total;
    }
    return (
      <>
        <GridContainer>
          {/* <GridItem xs={12} sm={12} md={12}>
            <CardHeader color="primary">
              <strong> <h4 className="font-weight-bold">Statement</h4></strong>
            </CardHeader>
          </GridItem> */}
          {!this.state.hidden && (
            <CardBody>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "10%",
                }}
                xs={12}
              >
               
              </div>
            </CardBody>
          )}
        </GridContainer>
        {this.state.hidden && (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card style={{ height: 180 }}>
                  <CardHeader color="warning" stats icon>
                    <Row>
                      <Col>
                        <CardIcon color="warning">
                          <Icon>content_copy</Icon>
                        </CardIcon>
                      </Col>
                    </Row>
                    <Row style={{ height: 25 }}>
                      <Col pull-right>
                        <p class="text-dark text-lg">Daily</p>
                      </Col>
                    </Row>
                    <h3 class="text-dark text-sm">{"Tokens: " + dailytoken}</h3>
                    <h3 class="text-dark text-sm">
                      {"Amount: " + dailyamount}
                    </h3>
                    <h3 class="text-dark text-sm">{"Total: " + dailytotal}</h3>
                    <hr />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card style={{ height: 180 }}>
                  <CardHeader color="success" stats icon>
                    <Row>
                      <Col>
                        <CardIcon color="success">
                          <Store />
                        </CardIcon>
                      </Col>
                    </Row>
                    <Row style={{ height: 25 }}>
                      <Col pull-right>
                        <p class="text-dark text-lg">Monthly</p>
                      </Col>
                    </Row>
                    <h3 class="text-dark text-sm">
                      {"Tokens: " + monthlytoken}
                    </h3>
                    <h3 class="text-dark text-sm">
                      {"Amount: " + monthlyamount}
                    </h3>
                    <h3 class="text-dark text-sm">
                      {"Total: " + monthlytotal}
                    </h3>
                    <hr />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card style={{ height: 180 }}>
                  <CardHeader color="danger" stats icon>
                    <Row>
                      <Col>
                        <CardIcon color="danger">
                          <Icon>info_outline</Icon>
                        </CardIcon>
                      </Col>
                    </Row>
                    <Row style={{ height: 25 }}>
                      <Col pill-right>
                        <p class="text-dark text-lg">Yearly</p>
                      </Col>
                    </Row>
                    <h3 class="text-dark text-sm">
                      {"Tokens: " + yearlytoken}
                    </h3>
                    <h3 class="text-dark text-sm">
                      {"Amount: " + yearlyamount}
                    </h3>
                    <h3 class="text-dark text-sm">{"Total: " + yearlytotal}</h3>
                    <hr />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card style={{ height: 180 }}>
                  <CardHeader color="info" stats icon>
                    <Row>
                      <Col>
                        <CardIcon color="info">
                          <Icon>content_copy</Icon>
                        </CardIcon>
                      </Col>
                    </Row>
                    <Row style={{ height: 25 }}>
                      <Col pull-right>
                        <p class="text-dark text-lg">Overall</p>
                      </Col>
                    </Row>
                    <h3 class="text-dark text-sm">
                      {"Tokens: " + overalltoken}
                    </h3>
                    <h3 class="text-dark text-sm">
                      {"Amount: " + overallamount}
                    </h3>
                    <h3 class="text-dark text-sm">
                      {"Total: " + overalltotal}
                    </h3>
                    <hr />
                  </CardHeader>
                  <CardFooter stats></CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="warning">
                    <h4 className="mt-1">Statement Type</h4>
                  </CardHeader>
                  <CardBody>
                    {/* <div>
                  <ButtonGroup>
                    <Button className="ml-1" color="success" onClick={() => { this.setState({ currentTableName: 'overall',currentPage:0,pagesCount: 0, hiddenSpin:false })
                      this.componentDidMount() }}>Overall Statement</Button>
                    <Button className="ml-4" color="success" onClick={() => { this.setState({ currentTableName: 'yearly',currentPage:0,pagesCount: 0, hiddenSpin:false })
                      this.componentDidMount() }}>Yearly Statement</Button>
                    <Button className="ml-4" color="success" onClick={() => { this.setState({ currentTableName: 'monthly',currentPage:0,pagesCount: 0,hiddenSpin:false })
                      this.componentDidMount() }}>Monthly Statement</Button>
                    <Button className="ml-4" color="success" onClick={() => { this.setState({ currentTableName: 'daily',currentPage:0,pagesCount: 0,hiddenSpin:false })
                      this.componentDidMount() }}>Daily Statement</Button>
                  </ButtonGroup>
                </div> */}

                    <Row>
                      <Col xs={12} md={2} className="px-3 py-1">
                        <ButtonGroup>
                          <Button
                            color="success"
                            onClick={() => {
                              this.setState({
                                currentTableName: "overall",
                                currentPage: 0,
                                pagesCount: 0,
                                hiddenSpin: false,
                              });
                              this.componentDidMount();
                            }}
                          >
                            Overall Statement
                          </Button>
                        </ButtonGroup>
                      </Col>
                      <Col xs={12} md={2} className="px-3 py-1">
                        <ButtonGroup>
                          <Button
                            color="success"
                            onClick={() => {
                              this.setState({
                                currentTableName: "yearly",
                                currentPage: 0,
                                pagesCount: 0,
                                hiddenSpin: false,
                              });
                              this.componentDidMount();
                            }}
                          >
                            Yearly Statement
                          </Button>
                        </ButtonGroup>
                      </Col>
                      <Col xs={12} md={2} className="px-3 py-1">
                        <ButtonGroup>
                          <Button
                            color="success"
                            onClick={() => {
                              this.setState({
                                currentTableName: "monthly",
                                currentPage: 0,
                                pagesCount: 0,
                                hiddenSpin: false,
                              });
                              this.componentDidMount();
                            }}
                          >
                            Monthly Statement
                          </Button>
                        </ButtonGroup>
                      </Col>
                      <Col xs={12} md={2} className="px-3 py-1">
                        <ButtonGroup>
                          <Button
                            color="success"
                            onClick={() => {
                              this.setState({
                                currentTableName: "daily",
                                currentPage: 0,
                                pagesCount: 0,
                                hiddenSpin: false,
                              });
                              this.componentDidMount();
                            }}
                          >
                            Monthly Statement
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </GridItem>

              {statementType && (
                <GridItem xs={12} sm={12} md={12}>
                  <Card style={{ "min-width": "270px" }}>
                    <CardHeader color="warning">
                      <h4 className="mt-1">Earnings</h4>
                    </CardHeader>
                    <CardBody>
                      <div>
                        <ButtonGroup>
                          <Button
                            color="info"
                            onClick={() => {
                              this.setState({ downloadType: "1" });
                            }}
                          >
                            Excel
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              this.setState({ downloadType: "2" });
                            }}
                          >
                            CSV
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              this.setState({ downloadType: "3" });
                            }}
                          >
                            PDF
                          </Button>
                        </ButtonGroup>
                      </div>
                      <br />
                      {!this.state.hiddenSpin && (
                        <div
                          style={{
                            position: "relative",
                            left: "50%",
                            top: 13,
                          }}
                          xs={12}
                        >
                        
                        </div>
                      )}
                      {this.state.hiddenSpin && (
                        <div>
                          <Table
                            bordered
                            responsive
                            className="table table-bordered table-hover"
                          >
                            <thead className="thead-dark shadow">
                              <tr>
                                <th>No.</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Tokens</th>
                                <th>Phone</th>
                              </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "#f2f2f2" }}>
                              {this.state.currentTable &&
                                this.renderResultRows(this.state.currentTable)}
                              {this.state.currentTable &&
                                _.size(this.state.currentTable.data) === 0 && (
                                  <tr>
                                    <td scope="row">{"No Records Found"} </td>
                                  </tr>
                                )}
                            </tbody>
                          </Table>
                          <nav>
                            <Pagination className="pull-right mt-3" size="md">
                              <PaginationItem
                                disabled={this.state.currentPage <= 0}
                              >
                                <PaginationLink
                                  onClick={(e) =>
                                    this.handleClick(
                                      e,
                                      this.state.currentPage - 1
                                    )
                                  }
                                  previous
                                  href="#"
                                />
                              </PaginationItem>

                              {[...Array(this.state.pagesCount)].map(
                                (page, i) => (
                                  <PaginationItem
                                    active={i === this.state.currentPage}
                                    key={i}
                                  >
                                    <PaginationLink
                                      onClick={(e) => this.handleClick(e, i)}
                                      href="#"
                                    >
                                      {i + 1}
                                    </PaginationLink>
                                  </PaginationItem>
                                )
                              )}

                              <PaginationItem
                                disabled={
                                  this.state.currentPage >=
                                  this.state.pagesCount - 1
                                }
                              >
                                <PaginationLink
                                  onClick={(e) =>
                                    this.handleClick(
                                      e,
                                      this.state.currentPage + 1
                                    )
                                  }
                                  next
                                  href="#"
                                />
                              </PaginationItem>
                            </Pagination>
                          </nav>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </GridItem>
              )}
            </GridContainer>
          </div>
        )}
      </>
    );
  }
}
export default Statements;
