import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { updateCompany, updatePassword } from '../../services/admin/Index';

import CardHeader from "components/Card/CardHeader.js";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import _ from "lodash";
import { NotificationContainer, NotificationManager } from 'react-notifications';

import {
  Card, CardBody,
  CardTitle, Button, Col, Row, Spinner
} from 'reactstrap';
import ImageUploader from 'react-images-upload';
import profile from "../../assets/img/profile.png";
import { Grid, Cell } from 'react-mdl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { X_TOKEN } from '../../constants/action-types';
import { auth , firestore } from "../../firebase/firebase";
import history from "../../history";

class UpdateCompany extends React.Component {
    constructor(props) {
      super(props);
      this.goBack = this.goBack.bind(this);
      this.editProfile = this.editProfile.bind(this);
      this.updateCompanyProfile = this.updateCompanyProfile.bind(this);
      this.changePassword = this.changePassword.bind(this);
      this.UpdatePassword = this.UpdatePassword.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onDrop = this.onDrop.bind(this);
      this.addSummary = this.addSummary.bind(this);
      this.editpersonal = this.editpersonal.bind(this);
      this.editDetail = this.editDetail.bind(this);
      this.editAddress = this.editAddress.bind(this);
      this.updateDetails = this.updateDetails.bind(this);
      this.updateSummary = this.updateSummary.bind(this);
      this.updateAddress = this.updateAddress.bind(this);
      this.updateLic = this.updateLic.bind(this);
  
      this.state = {
        count: 0,
        upload: 0,
        id: '',
        email: '',
        old_password: '',
        new_password: '',
        confirm_password: '',
        img: '',
        img_url: '',
        name: '',
        address1: '',
        address2: '',
        agency_code: '',
        city: '',
        country: '',
        issue_date: '',
        lic_number: '',
        lic_type: '',
        lic_type_code: '',
        no_of_employ: '',
        phone: '',
        rating: '',
        state: '',
        street: '',
        fcm_token: '',
        stripe_token: '',
        sufix: '',
        tokens: '',
        type: '',
        zip_code: '',
        token: '',
        picture: [],
        hidden: false,
        user_docid : ""
      }
    }
  
    async componentWillMount() {
      await auth.onAuthStateChanged(async (user) => {
        if (!user) {
          history.push("/");
        } else {
          await this.getProfileDocId(user.uid);
        }
      });
      const userInfo = JSON.parse(localStorage.getItem('userData'));
      const user = userInfo.data
      const token = userInfo.token
      if(user.img_url==null || user.img_url==''){
        await this.setState({
          id: user.id,
          email: user.email,
          img_url: user.img_url,
          name: user.name,
          address1: user.address1,
          address2: user.address2,
          agency_code: user.agency_code,
          city: user.city,
          country: user.country,
          issue_date: user.issue_date,
          lic_number: user.lic_number,
          lic_type: user.lic_type,
          lic_type_code: user.lic_type_code,
          no_of_employ: user.no_of_employ,
          phone: user.phone,
          fcm_token: user.fcm_token,
          rating: user.rating,
          state: user.state,
          street: user.street,
          stripe_token: user.stripe_token,
          sufix: user.sufix,
          tokens: user.tokens,
          type: user.type,
          zip_code: user.zip_code,
          token: token,
          upload: 0,
          count: 0,
          hidden: false
        })
      }else {
        await this.setState({
          id: user.id,
          email: user.email,
          img_url: user.img_url,
          name: user.name,
          address1: user.address1,
          address2: user.address2,
          agency_code: user.agency_code,
          city: user.city,
          country: user.country,
          issue_date: user.issue_date,
          lic_number: user.lic_number,
          lic_type: user.lic_type,
          lic_type_code: user.lic_type_code,
          fcm_token: user.fcm_token,
          no_of_employ: user.no_of_employ,
          phone: user.phone,
          rating: user.rating,
          state: user.state,
          street: user.street,
          stripe_token: user.stripe_token,
          sufix: user.sufix,
          tokens: user.tokens,
          type: user.type,
          zip_code: user.zip_code,
          token: token,
          upload: 1,
          count: 0,
          hidden: false
        })
  
      }
    }
  
    async onDrop(e) {
      await this.setState({
        img : e[0]
      });
    }

  getProfileDocId = async (ID) => {
      await firestore
        .collection("users")
        .where("id", "==", ID)
        .get()
        .then(async (snapshot) => {
          await snapshot.forEach(async (doc) => {
            await this.setState({
              user_docid: doc.id,
            });
          });
        });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  addSummary() {
    this.setState({ count: 3 })
  }

  editpersonal() {
    this.setState({ count: 4 })
  }

  editDetail() {
    this.setState({ count: 5 })
  }

  editAddress() {
    this.setState({ count: 1 })
  }

  goBack() {
    this.setState({
      count: 0
    })
  }

  editProfile() {
    this.setState({
      count: 3
    })
  }

  changePassword() {
    this.setState({
      count: 4
    })
  }

  async UpdatePassword() {
    try {
      this.setState({ hidden: true })
      if (this.state.new_password == this.state.confirm_password) {
        const body = {
          email: this.state.email,
          password: this.state.new_password,
          token: this.state.token
        }
        const response = await updatePassword(body);
        if (response.status == 200) {
          this.setState({ count: 0, hidden: false })
          NotificationManager.success('Updated Succesfuly');
        } else {
          NotificationManager.error('Something went wrong');
        }
      } else {
        NotificationManager.success('Password Mismatch');
      }
    } catch (e) {
      throw (e);
    }
  }

  async updateCompanyProfile() {
    try {
      this.setState({ hidden: true })
      let img = ''
      if (this.state.img != ''){
        img = this.state.img
      }else{
        img = this.state.img_url
      }
      console.log("img ",img)
      const data = new FormData();
      data.append('img_url', img);
      data.append('name', this.state.name);
      data.append('phone', this.state.phone);
      let accessToken = this.state.token;
      axios
      .post('http://localhost:4500/user/updateCompany', data,
      { headers:
      { 'Content-Type': 'multipart/form-data', Authorization: accessToken }
      })
      .then(async(res) => {
        console.log('res', res);
        if (res.status == 200) {
            NotificationManager.success('Updated Succesfully');
            await this.setState({ hidden: false, img: '' });
            if(res.data.data.img_url!==null && res.data.data.img_url!==""){
              const { user_docid } = this.state;
              await firestore.collection("users").doc(user_docid).update({
                img_url: "http://localhost:4500/uploads/"+res.data.data.img_url,
              });
            }
            localStorage.setItem('userData', JSON.stringify(res.data));
            setTimeout(() => this.componentWillMount(), 2000);
          } else {
            NotificationManager.error('Something went wrong');
            this.setState({ hidden: false, img: '' })
            setTimeout(() => this.componentWillMount(), 2000);
          }
      });
      
    } catch (e) {
      throw (e);
    }

  }

  async updateAddress() {
    try {
      this.setState({ hidden: true })
      const body = {
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zip_code: this.state.zip_code,
        token: this.state.token
      }
      const response = await updateCompany(body);
      if (response.status == 200) {
        NotificationManager.success('Updated Succesfully');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }

  }

  async updateLic() {
    try {
      this.setState({ hidden: true })
      const body = {
        lic_number: this.state.lic_number,
        lic_type: this.state.lic_type,
        lic_type_code: this.state.lic_type_code,
        token: this.state.token
      }
      const response = await updateCompany(body);
      if (response.status == 200) {
        NotificationManager.success('Updated Succesfully');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }

  }

  async updateSummary() {
    try {
      this.setState({ hidden: true })
      const body = {
        summary: this.state.summary,
        token: this.state.token
      }
      const response = await updateCompany(body);
      if (response.status == 200) {
        NotificationManager.success('Updated Succesfully');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }

  }

  async updateDetails() {
    try {
      this.setState({ hidden: true })
      const body = {
        available_hours: this.state.available_hours,
        is_available: this.state.is_available,
        status: this.state.status,
        device_type: this.state.device_type,
        vehicle_type: this.state.vehicle_type,
        token: this.state.token
      }
      const response = await updateCompany(body);
      if (response.status == 200) {
        NotificationManager.success('Updated Succesfully');
        this.setState({ hidden: false })
        await localStorage.setItem('userData', JSON.stringify(response));
        setTimeout(() => this.componentWillMount(), 2000);
      } else {
        NotificationManager.error('Something went wrong');
        this.setState({ hidden: false })
        setTimeout(() => this.componentWillMount(), 2000);
      }
    } catch (e) {
      throw (e);
    }

  }

  render() {
    var IMG_URL = "";
    if (this.state.img_url!=="" && this.state.img_url!==null){
      IMG_URL = "http://localhost:4500/uploads/"+this.state.img_url;
    }
    return (
      <div>
      <NotificationContainer />
          <Card>
            <CardHeader color="primary">
              <Row className="ml-2">
                <h4 >Profile</h4>
              </Row>
            </CardHeader>
            <CardBody>
              <Grid>
                <Cell >
                  <CardBody >
                    <Card className="flex flex-initial bg-grey shadow-md">
                      <Row>
                        <Col>
                          <Row className="ml-1">
                            <h2 className="font-bold m-2 text-lg">Personal Info</h2>
                          </Row>
                        </Col>
                        <Col>
                          <Tooltip title="Edit">
                            <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} onClick={this.editpersonal}></Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      {this.state.count == 0 && <div>
                        <Row>
                          <Col xs={12}>
                            <div className="card-profile-image m-2 ml-4">
                              <a>
                                <img
                                  className="rounded-circle"
                                  src={IMG_URL!="" ? IMG_URL : profile}
                                  style={{width: 160, height: 160}}
                                />
                              </a>
                            </div>
                          </Col>
                          <Col xs={12}>
                            <CardBody>
                              <CardTitle ><Row className="ml-1"><strong> <h4 className="font-weight-bold text-teal-400">{this.state.name}</h4></strong></Row></CardTitle>
                              <CardTitle><Row className="ml-1"><strong>{this.state.email}</strong></Row></CardTitle>
                              <CardTitle><Row className="ml-1"><strong>{this.state.phone}</strong></Row></CardTitle>
                            </CardBody>
                          </Col>
                        </Row></div>}
                      {this.state.count == 4 &&
                        <div>
                          <div class="container">
                            <div class="row">
                              <div class="col-md-8 m-auto">
                                <AvForm className='group'>
                                  <label className="pull-left">Profile Image</label>
                                  <br></br>
                                  <ImageUploader
                                    className='img_url'
                                    withIcon={true}
                                    buttonText='Choose images'
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    singleImage={true}
                                  />
                                  <AvField name="name" label="Name" type="name" onChange={this.onChange} value={this.state.name} required className='form-input' />
                                  <AvField name="phone" label="Phone" type="phone" onChange={this.onChange} value={this.state.phone} required className='form-input' />
                                  <div class="container">
                                    <div class="row">
                                      <div class="col text-center">
                                        <div className="mt-3">
                                          <a className="btn btn-light mr-2" onClick={this.updateCompanyProfile}>
                                            <i className="fa fa-pencil-square-o mr-2 text-info" ></i>Update{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                          <a className="btn btn-light mr-2" onClick={this.goBack}>
                                            <i className="fa fa-times mr-2 text-info"></i> Cancel</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </AvForm>
                                <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                              </div>
                            </div>
                          </div>
                        </div>}
                    </Card>
                  </CardBody>
                </Cell>
              </Grid>
              <Grid>
                <Cell >
                  <Tabs>
                    <TabList xs={12} className='row justify-content-around'>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Intro</Tab>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Address</Tab>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>License</Tab>
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Password</Tab>
                    </TabList>
                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col>
                              <Row className="ml-1">
                                <h2 className="font-bold m-2 text-lg">Summary</h2>
                              </Row>
                            </Col>
                            <Col>
                              <Tooltip title="Edit">
                                <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.addSummary}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {this.state.count == 0 && <Row className="ml-1"><p className="m-3 text-base">No Summary</p></Row>}
                              {this.state.count == 3 &&
                                <div>
                                  <div class="container">
                                    <div class="row">
                                      <div class="col-md-8 m-auto">

                                        <AvForm className='group'>
                                          <AvField name="summary" label="Summary" type="textarea" onChange={this.onChange} value={this.state.summary} required className='form-input' />
                                          <div class="container">
                                            <div class="row">
                                              <div class="col text-center">
                                                <div className="mt-3">
                                                  <a className="btn btn-light mr-2" onClick={this.updateSummary}>
                                                    <i className="fa fa-floppy-o mr-2 text-info" ></i>Save{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                                  <a className="btn btn-light mr-2" onClick={this.goBack}>
                                                    <i className="fa fa-times mr-2 text-info"></i> Cancel</a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </AvForm>
                                        <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                            </Col>
                          </Row>
                        </Card>
                      </CardBody>
                    </TabPanel>

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col>
                              <Row className="ml-1">
                                <h2 className="font-bold m-2 text-lg">Address</h2>
                              </Row>
                            </Col>
                            <Col>
                              <Tooltip title="Edit">
                                <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.editAddress}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          {this.state.count == 0 && <div><Row>
                            <Col>
                              <Row className="ml-1">
                                <p className="ml-2 text-base font-bold">City</p>
                              </Row>
                            </Col>
                            <Col>
                              <Row className="ml-1">
                                <p className="text-base">{this.state.city}</p>
                              </Row>
                            </Col>
                          </Row>
                            <Row>
                              <Col>
                                <Row className="ml-1">
                                  <p className="ml-2 text-base font-bold">State</p>
                                </Row>
                              </Col>
                              <Col>
                                <Row className="ml-1">
                                  <p className="text-base">{this.state.state}</p>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row className="ml-1">
                                  <p className="ml-2 text-base font-bold">Zip Code</p>
                                </Row>
                              </Col>
                              <Col>
                                <Row className="ml-1">
                                  <p className="text-base">{this.state.zip_code}</p>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row className="ml-1">
                                  <p className="ml-2 text-base font-bold">Country</p>
                                </Row>
                              </Col>
                              <Col>
                                <Row className="ml-1">
                                  <p className="text-base">{this.state.country}</p>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row className="ml-1">
                                  <p className="ml-2 text-base font-bold">Address 1</p>
                                </Row>
                              </Col>
                              <Col>
                                <Row className="ml-1">
                                  <p className="text-base">{this.state.address1}</p>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row className="ml-1">
                                  <p className="ml-2 text-base font-bold">Address 2</p>
                                </Row>
                              </Col>
                              <Col>
                                <Row className="ml-1">
                                  <p className="text-base">{this.state.address2}</p>
                                </Row>
                              </Col>
                            </Row></div>}
                          {this.state.count == 1 &&
                            <div>
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-8 m-auto">

                                    <AvForm className='group'>
                                      <AvField name="city" label="City" type="city" onChange={this.onChange} value={this.state.city} className='form-input' />
                                      <AvField name="zip_code" label="ZipCode" type="zipcode" onChange={this.onChange} value={this.state.zip_code} className='form-input' />
                                      <AvField name="state" label="State" type="state" onChange={this.onChange} value={this.state.state} className='form-input' />
                                      <AvField type="select" name="country" label="Country" onChange={this.onChange} value={this.state.country}>
                                        <option></option>
                                        <option >Afghan</option>
                                        <option >Albanian</option>
                                        <option >Algerian</option>
                                        <option >American</option>
                                        <option >Angolan</option>
                                        <option >Antigua</option>
                                        <option >Argentinian</option>
                                        <option >Australian</option>
                                        <option >Austrian</option>
                                        <option >Azerbaijani</option>
                                        <option >Bahraini</option>
                                        <option >Bangladeshi</option>
                                        <option >Barbadian</option>
                                        <option >Barbuda</option>
                                        <option >Belarusian</option>
                                        <option >Belgian</option>
                                        <option >Belizian</option>
                                        <option >Bosnian</option>
                                        <option >Botswanan</option>
                                        <option >Brazilian</option>
                                        <option >British</option>
                                        <option >Bruneian</option>
                                        <option >Bulgarian</option>
                                        <option >Burmese</option>
                                        <option >Burundian</option>
                                        <option >Cambodian</option>
                                        <option >Cameroonian</option>
                                        <option >Canadian</option>
                                        <option >Chadian</option>
                                        <option >Chechnya</option>
                                        <option >Chilean</option>
                                        <option >Chinese</option>
                                        <option >Colombian</option>
                                        <option >Congolese</option>
                                        <option >Costa Rican</option>
                                        <option >Croat or Croatian</option>
                                        <option >Cuban</option>
                                        <option >Cypriot</option>
                                        <option >Czech</option>
                                        <option >Danish</option>
                                        <option >Democratic Republic of the Congo</option>
                                        <option >Djiboutian</option>
                                        <option >Dominican</option>
                                        <option >Duch</option>
                                        <option >Ecuadorian</option>
                                        <option >Egyptian</option>
                                        <option >Emirati</option>
                                        <option >Eritrean</option>
                                        <option >Estonian</option>
                                        <option >Ethiopian</option>
                                        <option >Fijian</option>
                                        <option >Filipino</option>
                                        <option >Finnish</option>
                                        <option >French</option>
                                        <option >Gambian</option>
                                        <option >Georgian</option>
                                        <option >German</option>
                                        <option >Ghanaian</option>
                                        <option >Gibraltarian</option>
                                        <option >Greek</option>
                                        <option >Guinean</option>
                                        <option >Haitian</option>
                                        <option >Hong Kong</option>
                                        <option >Hungarian</option>
                                        <option >Icelandic</option>
                                        <option >Indian</option>
                                        <option >Indonesian</option>
                                        <option >Iranian</option>
                                        <option >Iraqi</option>
                                        <option >Irish</option>
                                        <option >Italian</option>
                                        <option >Jamaican</option>
                                        <option >Japanese</option>
                                        <option >Jordanian</option>
                                        <option >Kazakh</option>
                                        <option >Kenyan</option>
                                        <option >Kosovo</option>
                                        <option >Kuwaiti</option>
                                        <option >Kyrgyzstan</option>
                                        <option >Lao Peoples Democratic Republic</option>
                                        <option >Latvian</option>
                                        <option >Lebanese</option>
                                        <option >Liberian</option>
                                        <option >Libyan</option>
                                        <option >Luxembourgers</option>
                                        <option >Madagascan</option>
                                        <option >Malawian</option>
                                        <option >Malaysian</option>
                                        <option >Maldivian</option>
                                        <option >Malian</option>
                                        <option >Maltese</option>
                                        <option >Mauritanian</option>
                                        <option >Mauritian</option>
                                        <option >Mexican</option>
                                        <option >Moroccan</option>
                                        <option >Mozambican</option>
                                        <option >Nepalese</option>
                                        <option >New Zealand</option>
                                        <option >Niger</option>
                                        <option >Nigerian</option>
                                        <option >Norwegian</option>
                                        <option >Omani</option>
                                        <option >Pakistan</option>
                                        <option >Palestinian</option>
                                        <option >Papua New Guinean</option>
                                        <option >Peruvian</option>
                                        <option >Polish</option>
                                        <option >Portuguese</option>
                                        <option >Puerto Rican</option>
                                        <option >Qatari</option>
                                        <option >Romanian</option>
                                        <option >Russian</option>
                                        <option >Rwandan</option>
                                        <option >Saudi</option>
                                        <option >Savlbardian</option>
                                        <option >Senegalese</option>
                                        <option >Serbian</option>
                                        <option >Sierra Leonian</option>
                                        <option >Singaporean</option>
                                        <option >Slovak</option>
                                        <option >Slovenian</option>
                                        <option >Solomon Islands</option>
                                        <option >Somali</option>
                                        <option >South African</option>
                                        <option >South Georgian</option>
                                        <option >South Korean</option>
                                        <option >South Sudanese</option>
                                        <option >Spanish</option>
                                        <option >Sri Lankan</option>
                                        <option >Sudanese</option>
                                        <option >Swedish</option>
                                        <option >Swiss</option>
                                        <option >Syrian</option>
                                        <option >Taiwanese</option>
                                        <option >Tajik or Tadjik</option>
                                        <option >Tanzanian</option>
                                        <option >Thai</option>
                                        <option >Timorese</option>
                                        <option >Tunisian</option>
                                        <option >Turkish</option>
                                        <option >Turkoman</option>
                                        <option >Ugandan</option>
                                        <option >Ukrainian</option>
                                        <option >Uzbek</option>
                                        <option >Venezuelan</option>
                                        <option >Vietnamese</option>
                                        <option >Yemeni</option>
                                        <option >Zimbabwean</option>
                                      </AvField>
                                      <AvField name="address1" label="Address 1" type="address" onChange={this.onChange} value={this.state.address1} className='form-input' />
                                      <AvField name="address2" label="Address 2" type="address" onChange={this.onChange} value={this.state.address2} className='form-input' />
                                      <div class="container">
                                        <div class="row">
                                          <div class="col text-center">
                                            <div className="mt-3">
                                              <a className="btn btn-light mr-1">
                                                <i className="fa fa-pencil-square-o mr-1 text-info" onClick={this.updateAddress}></i>Update{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                              <a className="btn btn-light mr-1" onClick={this.goBack}>
                                                <i className="fa fa-times mr-1 text-info"></i> Cancel</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </AvForm>
                                    <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </Card>
                      </CardBody>
                    </TabPanel>

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col>
                              <Row className="ml-2">
                                <h2 className="font-bold m-2 text-lg">License</h2>
                              </Row>
                            </Col>
                            <Col>
                              <Tooltip title="Edit">
                                <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.editAddress}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          {/* {this.state.count == 0 && <div><Row>
                            <Col>
                              <p className="m-3 text-base">Lincense Number</p>
                            </Col>
                            <Col>
                              <p className="m-3 text-base">{this.state.lic_number}</p>
                            </Col>
                          </Row>
                            <Row>
                              <Col>
                                <p className="m-3 text-base">Lincense Type</p>
                              </Col>
                              <Col>
                                <p className="m-3 text-base">{this.state.lic_type}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="m-3 text-base">Lincense Type Code</p>
                              </Col>
                              <Col>
                                <p className="m-3 text-base">{this.state.lic_type_code}</p>
                              </Col>
                            </Row>
                            </div>} */}
                          {this.state.count == 1 &&
                            <div>
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-8 m-auto">

                                    <AvForm className='group'>
                                      <AvField name="lic_number" label="Lic Number" type="number" onChange={this.onChange} value={this.state.lic_number} className='form-input' />
                                      <AvField name="lic_type" label="Lic Type" type="type" onChange={this.onChange} value={this.state.lic_type} className='form-input' />
                                      <AvField name="lic_type_code" label="Lic Type Code" type="type_code" onChange={this.onChange} value={this.state.lic_type_code} className='form-input' />
                                      <div class="container">
                                        <div class="row">
                                          <div class="col text-center">
                                            <div className="mt-3">
                                              <a className="btn btn-light mr-1" onClick={this.updateLic}>
                                                <i className="fa fa-pencil-square-o mr-1 text-info" ></i>Update{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                              <a className="btn btn-light mr-1" onClick={this.goBack}>
                                                <i className="fa fa-times mr-1 text-info"></i> Cancel</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </AvForm>
                                    <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </Card>
                      </CardBody>
                    </TabPanel>

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col>
                              <Row className="ml-2">
                                <h2 className="font-bold m-2 text-lg">Change Password</h2>
                              </Row>
                            </Col>
                          </Row>
                          <div>
                            <div class="container">
                              <div class="row">
                                <div class="col-md-8 m-auto">

                                  <AvForm className='group'>
                                    <AvField name="old_password" label="Old Password" type="password" onChange={this.onChange} required className='form-input' />
                                    <AvField name="new_password" label="New Password" type="password" onChange={this.onChange} required className='form-input' />
                                    <AvField name="confirm_password" label="Confirm New Password" type="password" onChange={this.onChange} required className='form-input' />

                                    <div class="container">
                                      <div class="row">
                                        <div class="col text-center">
                                          <div className="mt-3">
                                            <a className="btn btn-light mr-1" onClick={this.UpdatePassword}>
                                              <i className="fa fa-pencil-square-o mr-1 text-info"></i>Update Password{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </AvForm>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                        </Card>
                      </CardBody>
                    </TabPanel>
                  </Tabs>
                </Cell>
              </Grid>
            </CardBody>
          </Card>
        </div>
    )
  }
}
export default UpdateCompany;