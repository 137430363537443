import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Spinner } from "reactstrap";
import CardBody from "components/Card/CardBody";
import { Row, Col } from "reactstrap";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import history from "../history";
import {
  customerSignup,
  employSignup,
  registerCompany,
} from "../services/admin/Index";
import DatePicker from "react-datepicker";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";
import { auth, firestore } from "../firebase/firebase";
import "react-datepicker/dist/react-datepicker.css";
import { result } from "lodash";
import "./style.css";

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issueDate: new Date(),
      expiryDate: new Date(),
      hidden: false,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.registerCompany = this.registerCompany.bind(this);
    this.signupCustomer = this.signupCustomer.bind(this);
    this.signupEmploy = this.signupEmploy.bind(this);
  }

  async componentDidMount() {
    await auth.signOut;
  }

  handleValidSubmit(event, values) {
    this.setState({ email: values.email, hidden: true });
  }

  issueDate = (date) => {
    this.setState({
      issueDate: date,
    });
  };

  expiryDate = (date) => {
    this.setState({
      expiryDate: date,
    });
  };

  async registerCompany(event, model) {
    if (model.password.length > 7) {
      if (model.password == model.confirmpassword) {
        const { expiryDate, issueDate } = this.state;

        const body = {
          email: model.email,
          password: model.password,
          address1: model.address,
          address2: model.address2,
          name: model.companyName,
          city: model.city,
          state: model.state,
          zip_code: model.zipCode,
          country: model.country,
          phone: model.phone,
          sufix: model.suffix,
          expiry_date: moment(expiryDate).format("YYYY-MM-DD"),
          issue_date: moment(issueDate).format("YYYY-MM-DD"),
          agency_code: "",
        };
        const response = await registerCompany(body);
        if (response.status >= 400) {
          NotificationManager.error("Something went wrong");
        } else {
          NotificationManager.success("Signup Succesfuly");
          await auth
            .createUserWithEmailAndPassword(model.email, model.password)
            .then(async (result) => {
              await firestore.collection("users").add({
                company_id: response.data.id,
                name: model.companyName,
                id: result.user.uid,
                email: model.email,
                password: model.password,
                img_url: "",
                user_type: "company",
                isonline: false,
                blocklist: [],
                isverify: false,
              });
            })
            .catch((e) => {
              alert(e);
            });
          await auth.signOut();
          this.setState({ hidden: false });
          history.push("/login");
        }
      } else {
        NotificationManager.success("Password Mismatched");
      }
    } else {
      NotificationManager.error(
        "Password should consists of atleast 8 characters"
      );
    }
  }

  async signupCustomer(event, model) {
    if (model.password.length > 7) {
      if (model.password == model.confirmpassword) {
        this.setState({ hidden: true });
        const body = {
          email: model.email,
          password: model.password,
          first_name: model.firstName,
          last_name: model.lastName,
          city: model.city,
          state: model.state,
          zip_code: model.zipCode,
          country: model.country,
          address: model.address,
          phone: model.phone,
          device_type: "web",
          user_name: model.userName,
          fcm_token: "null",
        };
        const response = await customerSignup(body);
        console.log("signup", response);
        if (response.status >= 400) {
          NotificationManager.error("Something went wrong");
        } else {
          NotificationManager.success("Signup Succesfuly");
          await auth
            .createUserWithEmailAndPassword(model.email, model.password)
            .then(async (result) => {
              await firestore.collection("users").add({
                user_id: response.data.id,
                first_name: model.firstName,
                last_name: model.lastName,
                id: result.user.uid,
                email: model.email,
                password: model.password,
                img_url: "",
                user_type: "customer",
                isonline: false,
                blocklist: [],
                isverify: false,
              });
            })
            .catch((e) => {
              alert(e);
            });
          await auth.signOut();
          history.push("/login");
        }
        this.setState({ hidden: false });
      } else {
        NotificationManager.success("Password Mismatched");
      }
    } else {
      NotificationManager.error(
        "Password should consists of atleast 8 characters"
      );
    }
  }

  async signupEmploy(event, model) {
    if (model.password.length > 7) {
      if (model.password == model.confirmpassword) {
        this.setState({ hidden: true });
        const body = {
          email: model.email,
          password: model.password,
          first_name: model.firstName,
          last_name: model.lastName,
          city: model.city,
          state: model.state,
          zip_code: model.zipCode,
          country: model.country,
          address: model.address,
          phone: model.phone,
          device_type: "web",
          user_name: model.userName,
          fcm_token: "null",
        };

        const response = await employSignup(body);
        if (response.status >= 400) {
          NotificationManager.error("Something went wrong");
        } else {
          NotificationManager.success("Signup Succesfuly");
          await auth
            .createUserWithEmailAndPassword(model.email, model.password)
            .then(async (result) => {
              await firestore.collection("users").add({
                employ_id: response.data.id,
                first_name: model.firstName,
                last_name: model.lastName,
                id: result.user.uid,
                email: model.email,
                password: model.password,
                img_url: "",
                user_type: "employ",
                isonline: false,
                blocklist: [],
                isverify: false,
              });
            })
            .catch((e) => {
              alert(e);
            });
          await auth.signOut();
          history.push("/login");
        }
        this.setState({ hidden: false });
      } else {
        NotificationManager.success("Password Mismatched");
      }
    } else {
      NotificationManager.error(
        "Password should consists of atleast 8 characters"
      );
    }
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ email: values.email, error: true });
  }

  closeModal() {
    this.setState({ email: false, error: false });
  }
  login() {
    history.push("/login");
  }

  render() {
    const modalError = this.state.error ? "not" : ""; // This is just for the modal
    const { hidden } = this.state;
    return (
      <body>
        <div id="landing-signup">
          <div class="landing-inner text-light">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  {/* <h1 class="display-10 mb-2 text-primary font-weight-bold">GpsRecruiter</h1> */}
                  <h1 class="display-5 mb-4">Sign Up</h1>
                </div>
              </div>
            </div>
            <div>
              <Row className="mx-0">
                <Col lg="6" md="8" sm="12" xs="12" className="m-auto">
                  <Card>
                    <NotificationContainer />
                    <Tabs>
                      <TabList className="justify-between border border-primary bg-primary text-white font-weight-bold px-3">
                        <Tab >
                          Customer
                        </Tab>
                        <Tab >
                          Employees
                        </Tab>
                        <Tab >
                          Company
                        </Tab>
                      </TabList>
                      <TabPanel>
                        <CardBody className="">
                          <AvForm
                            onValidSubmit={this.signupCustomer}
                            onInvalidSubmit={this.handleInvalidSubmit}
                            className="group"
                          >
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      User Name
                                    </label>
                                    <AvField
                                      name="userName"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      First Name
                                    </label>
                                    <AvField
                                      name="firstName"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Last Name
                                    </label>
                                    <AvField
                                      name="lastName"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Email Address
                                    </label>
                                    <AvField
                                      name="email"
                                      type="email"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Password
                                    </label>
                                    <AvField
                                      name="password"
                                      type="password"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Confirm Password
                                    </label>
                                    <AvField
                                      name="confirmpassword"
                                      type="password"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">City</label>
                                    <AvField
                                      name="city"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">State</label>
                                    <AvField
                                      name="state"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Zip Code
                                    </label>
                                    <AvField
                                      name="zipCode"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">Country</label>
                                    <AvField
                                      name="country"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">Address</label>
                                    <AvField
                                      name="address"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">Phone</label>
                                    <AvField
                                      name="phone"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            {/* <div className='d-flex'>
                                      <Col lg='6' md='8' sm='12' xs='12'>

                                        <AvField type="select" name="deviceType" label="Device Type">
                                          <option>Please Select</option>
                                          <option>Android</option>
                                          <option>Iphone</option>
                                          <option>Web</option>
                                        </AvField>
                                      </Col>

                                    </div> */}
                            <Button color="primary w-100">
                              Sign Up{" "}
                              {hidden && (
                                <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>
                              )}
                            </Button>
                            <div className="pull-left">
                              <p>
                                If you already have an account?{" "}
                                <span
                                  type="button"
                                  className="text-primary"
                                  onClick={this.login}
                                >
                                  Login here
                                </span>
                              </p>
                            </div>
                          </AvForm>
                        </CardBody>
                      </TabPanel>

                      <TabPanel>
                        <CardBody className="">
                          <AvForm
                            onValidSubmit={this.signupEmploy}
                            onInvalidSubmit={this.handleInvalidSubmit}
                            className="group"
                          >
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      User Name
                                    </label>
                                    <AvField
                                      name="userName"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      First Name
                                    </label>
                                    <AvField
                                      name="firstName"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Last Name
                                    </label>
                                    <AvField
                                      name="lastName"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Email Address
                                    </label>
                                    <AvField
                                      name="email"
                                      type="email"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Password
                                    </label>
                                    <AvField
                                      name="password"
                                      type="password"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Confirm Password
                                    </label>
                                    <AvField
                                      name="confirmpassword"
                                      type="password"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">City</label>
                                    <AvField
                                      name="city"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">State</label>
                                    <AvField
                                      name="state"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Zip Code
                                    </label>
                                    <AvField
                                      name="zipCode"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">Country</label>
                                    <AvField
                                      name="country"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">Address</label>
                                    <AvField
                                      name="address"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">Phone</label>
                                    <AvField
                                      name="phone"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <Button color="primary w-100">
                              Sign Up{" "}
                              {hidden && (
                                <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>
                              )}
                            </Button>
                            <div className="pull-left">
                              <p>
                                If you already have an account?{" "}
                                <span
                                  type="button"
                                  className="text-primary"
                                  onClick={this.login}
                                >
                                  Login here
                                </span>
                              </p>
                            </div>
                          </AvForm>
                        </CardBody>
                      </TabPanel>

                      <TabPanel>
                        <CardBody className="">
                          <AvForm
                            onValidSubmit={this.registerCompany}
                            onInvalidSubmit={this.handleInvalidSubmit}
                            className="group"
                          >
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Company Name
                                    </label>
                                    <AvField
                                      name="companyName"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">Suffix</label>
                                    <AvField
                                      name="suffix"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Email Address
                                    </label>
                                    <AvField
                                      name="email"
                                      type="email"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">Phone</label>
                                    <AvField
                                      name="phone"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Password
                                    </label>
                                    <AvField
                                      name="password"
                                      type="password"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Confirm Password
                                    </label>
                                    <AvField
                                      name="confirmpassword"
                                      type="password"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">City</label>
                                    <AvField
                                      name="city"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">State</label>
                                    <AvField
                                      name="state"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Zip Code
                                    </label>
                                    <AvField
                                      name="zipCode"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">Country</label>
                                    <AvField
                                      name="country"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    <label className="pull-left">Address</label>
                                    <AvField
                                      name="address"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <label className="pull-left">
                                      Address 2
                                    </label>
                                    <AvField
                                      name="address2"
                                      type="text"
                                      required
                                      className="form-input"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <div className="d-flex">
                              <Col lg="12" sm="12" md="12" xl="12">
                                <Row>
                                  <Col lg="6">
                                    {/* <p>License Issue Date</p> */}
                                    <label className="pull-left">
                                      License Issue Date
                                    </label>
                                    <AvField
                                      name="issueDate"
                                      type="date"
                                      selected={this.state.issueDate}
                                      onChange={this.issueDate}
                                      required
                                      className="form-input"
                                    />
                                    {/* <DatePicker
                                          selected={this.state.issueDate}
                                          onChange={this.issueDate}
                                        /> */}
                                  </Col>
                                  <Col lg="6">
                                    {/* <p>License Expiry Date</p> */}
                                    <label className="pull-left">
                                      License Expiry Date
                                    </label>
                                    <AvField
                                      name="expiryDate"
                                      type="date"
                                      startDate={Date()}
                                      selected={this.state.expiryDate}
                                      onChange={this.expiryDate}
                                      required
                                      className="form-input"
                                    />
                                    {/* <DatePicker
                                          startDate={Date()}
                                          selected={this.state.expiryDate}
                                          onChange={this.expiryDate}
                                        /> */}
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                            <Button color="primary w-100">
                              Sign Up{" "}
                              {hidden && (
                                <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>
                              )}
                            </Button>
                            <div className="pull-left">
                              <p>
                                If you already have an account?{" "}
                                <span
                                  type="button"
                                  className="text-primary"
                                  onClick={this.login}
                                >
                                  Login here
                                </span>
                              </p>
                            </div>
                          </AvForm>
                        </CardBody>
                      </TabPanel>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
