import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { updatePassword } from '../../services/admin/Index';

import CardHeader from "components/Card/CardHeader.js";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import _ from "lodash";
import { NotificationContainer, NotificationManager } from 'react-notifications';

import {
  Card, CardBody,
  CardTitle, CardSubtitle, Button, Col, Row, Spinner
} from 'reactstrap';
import ImageUploader from 'react-images-upload';
import profile from "../../assets/img/profile.png";
import { Grid, Cell } from 'react-mdl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tooltip from '@material-ui/core/Tooltip';

class UpdateAdmin extends React.Component {
    constructor(props) {
      super(props);
      this.goBack = this.goBack.bind(this);
      this.changePassword = this.changePassword.bind(this);
      this.UpdatePassword = this.UpdatePassword.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onDrop = this.onDrop.bind(this);
      this.addSummary = this.addSummary.bind(this);

      this.state = {
        count: 0,
        upload: 0,
        email: '',
        new_password: '',
        old_password: '',
        confirm_password: '',
        img: '',
        img_url: '',
        first_name: '',
        last_name: '',
        token: '',
        picture:[],
        hidden:false
      }
    }
  
    componentWillMount() {
      const userInfo = JSON.parse(localStorage.getItem('userData'));
      console.log(userInfo)
      const user = userInfo.data
      const token = userInfo.token
      if(user.img_url==null || user.img_url==''){
        this.setState({
          email: user.email,
          img_url: user.img_url,
          first_name: user.first_name,
          last_name: user.last_name,
          token: token,
          upload : 0,
          hidden:false
        })
      }else{
        this.setState({
          email: user.email,
          img_url: user.img_url,
          first_name: user.first_name,
          last_name: user.last_name,
          token: token,
          upload : 1,
          hidden:false
        })
      }
    }
  
    async onDrop(e) {
      await this.setState({
        img : e[0],
        upload: 3
      });
    }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  addSummary() {
    this.setState({ count: 3 })
  }

  editpersonal() {
    this.setState({ count: 4 })
  }

  goBack() {
    this.setState({
      count: 0
    })
  }

  changePassword() {
    this.setState({
      count: 4
    })
  }

  async UpdatePassword() {
    try {
      this.setState({ hidden: true })
      if (this.state.new_password == this.state.confirm_password) {
        const body = {
          email: this.state.email,
          password: this.state.new_password,
          token: this.state.token
        }
        const response = await updatePassword(body);
        if (response.status == 200) {
          this.setState({ count: 0, hidden: false })
          NotificationManager.success('Updated Succesfuly');
        } else {
          NotificationManager.error('Something went wrong');
        }
      } else {
        NotificationManager.success('Password Mismatch');
      }
    } catch (e) {
      throw (e);
    }
  }

  render() {
    return (
      <div>
        <NotificationContainer />
        
          <Card>
            <CardHeader color="primary">
              <Row className="ml-2"><h4 >Profile</h4></Row>
            </CardHeader>
            <CardBody>
              <Grid>
                <Cell >
                  <CardBody >
                    <Card className="flex flex-initial bg-grey shadow-md">
                      <Row>
                        <Col>
                          <Row className="ml-1">
                            <h2 className="font-bold m-2 text-lg">Personal Info</h2>
                          </Row>
                        </Col>
                        {/* <Col>
                          <Tooltip title="Edit">
                            <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} onClick={this.editpersonal}></Button>
                          </Tooltip>
                        </Col> */}
                      </Row>
                      {this.state.count == 0 && <div>
                        <Row>
                          {/* <Col xs={12}>
                            
                            <div className="card-profile-image m-2 ml-4">
                              <a>
                                <img
                                  alt={profile}
                                  className="rounded-circle"
                                  src={this.state.img_url}
                                  style={{width: 160, height: 160}}
                                />
                              </a>
                            </div>
                          </Col> */}
                          <Col xs={12}>
                            <CardBody>
                              <CardTitle><Row className="ml-1"><strong>{this.state.first_name + " " + this.state.last_name}</strong></Row></CardTitle>
                              <CardTitle><Row className="ml-1"><strong>{this.state.email}</strong></Row></CardTitle>
                            </CardBody>
                          </Col>
                        </Row></div>}
                      {this.state.count == 4 &&
                        <div>
                          <div class="container">
                            <div class="row">
                              <div class="col-md-8 m-auto">
                                <AvForm className='group'>
                                  <label className="pull-left">Profile Image</label>
                                  <ImageUploader
                                    className='img_url'
                                    withIcon={true}
                                    buttonText='Choose images'
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    singleImage={true}
                                  />
                                  <AvField name="user_name" label="User Name" type="user_name" onChange={this.onChange} value={this.state.user_name} required className='form-input' />
                                  <AvField name="first_name" label="First Name" type="first_name" onChange={this.onChange} value={this.state.first_name} required className='form-input' />
                                  <AvField name="last_name" label="Last Name" type="last_name" onChange={this.onChange} value={this.state.last_name} required className='form-input' />
                                  <AvField type="select" name="gender" label="Gender" onChange={this.onChange} value={this.state.gender}>
                                    <option>Please Select</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Other</option>
                                  </AvField>
                                  <div class="container">
                                    <div class="row">
                                      <div class="col text-center">
                                        <div className="mt-3">
                                          <a className="btn btn-light mr-2" onClick={this.updateAdminProfile}>
                                            <i className="fa fa-pencil-square-o mr-2 text-info" ></i>Update{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                          <a className="btn btn-light mr-2" onClick={this.goBack}>
                                            <i className="fa fa-times mr-2 text-info"></i> Cancel</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </AvForm>
                                <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                              </div>
                            </div>
                          </div>
                        </div>}
                    </Card>
                  </CardBody>
                </Cell>
              </Grid>
              <Grid>
                <Cell >
                  <Tabs>
                    <TabList xs={12} className='row justify-content-around'>
                      {/* <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Intro</Tab> */}
                      <Tab className="font-bold text-teal-400" style={{ cursor: "pointer" }} onClick={this.goBack}>Password</Tab>
                    </TabList>
                    {/* <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col>
                              <h2 className="font-bold m-2 text-lg">Summary</h2>
                            </Col>
                            <Col>
                              <Tooltip title="Edit">
                                <Button className="fa fa-pencil-square-o pull-right m-2" color="transparent" style={{ textAlign: 'center', borderRadius: 60 }} aria-hidden="true" onClick={this.addSummary}></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {this.state.count == 0 && <p className="m-3 text-base">No Summary</p>}
                              {this.state.count == 3 &&
                                <div>
                                  <div class="container">
                                    <div class="row">
                                      <div class="col-md-8 m-auto">

                                        <AvForm className='group'>
                                          <AvField name="summary" label="Summary" type="textarea" onChange={this.onChange} value={this.state.summary} required className='form-input' />
                                          <div class="container">
                                            <div class="row">
                                              <div class="col text-center">
                                                <div className="mt-3">
                                                  <a className="btn btn-light mr-2" onClick={this.updateSummary}>
                                                    <i className="fa fa-floppy-o mr-2 text-info" ></i>Save{this.state.hidden && <Spinner size="sm" role="status"></Spinner>}</a>
                                                  <a className="btn btn-light mr-1" onClick={this.goBack}>
                                                    <i className="fa fa-times mr-1 text-info"></i> Cancel</a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </AvForm>
                                        <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                            </Col>
                          </Row>
                        </Card>
                      </CardBody>
                    </TabPanel> */}

                    <TabPanel>
                      <CardBody className=''>
                        <Card className="flex bg-grey shadow-md" size="md">
                          <Row>
                            <Col>
                              <Row className="ml-1">
                                <h2 className="font-bold m-2 text-lg">Change Password</h2>
                              </Row>
                            </Col>
                          </Row>
                          <div>
                            <div class="container">
                              <div class="row">
                                <div class="col-md-8 m-auto">

                                  <AvForm className='group'>
                                    <AvField name="old_password" label="Old Password" type="password" onChange={this.onChange} required className='form-input' />
                                    <AvField name="new_password" label="New Password" type="password" onChange={this.onChange} required className='form-input' />
                                    <AvField name="confirm_password" label="Confirm New Password" type="password" onChange={this.onChange} required className='form-input' />

                                    <div class="container">
                                      <div class="row">
                                        <div class="col text-center">
                                          <div className="mt-3">
                                            <a className="btn btn-light mr-1" onClick={this.UpdatePassword}>
                                              <i className="fa fa-pencil-square-o mr-1 text-info"></i>Update Password{this.state.hidden && <Spinner size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>}</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </AvForm>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr style={{ borderTop: '3px solid #FFFFFF' }} />
                        </Card>
                      </CardBody>
                    </TabPanel>
                  </Tabs>
                </Cell>
              </Grid>
            </CardBody>
          </Card>
      </div>
    )
  }
}
export default UpdateAdmin;