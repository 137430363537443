import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import history from "../../history/index";
import { employSignup } from "../../services/admin/Index";
import { auth, firestore } from "../../firebase/firebase";
import Navbar from "../../components/Navbar/Navbar";

const PersonalInformation = ({
  state,
  handleChange,
  graduated,
  eligible,
  ifEligible,
  ifNotEligible,
  handleGraduatedChange,
  handleGraduatedRemove,
}) => {
  return (
    <div>
      <h3 className="text-2xl font-bold text-purple-600">
        Personal Information:
      </h3>
      <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
        <div>
          <div class="mt-4">
            <label
              for="first_name"
              class="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text"
                name="first_name"
                required
                value={state.first_name}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
            >
              Country
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text"
                name="country"
                required
                value={state.country}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label for="city" class="block text-sm font-medium text-gray-700">
              City
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text"
                name="city"
                required
                value={state.city}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label
              for="zip_code"
              class="block text-sm font-medium text-gray-700"
            >
              Zipcode
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text"
                name="zip_code"
                required
                value={state.zip_code}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div class="flex flex-col items-start">
              <input
                type="email"
                name="email"
                required
                value={state.email}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 undefined"
            >
              Password
            </label>
            <div className="flex flex-col items-start">
              <input
                type="password"
                name="password"
                value={state.password}
                onChange={handleChange}
                required
                className="block h-12 p-2 w-full mt-1 border-gray-300 border rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="mt-4">
            <label
              for="last_name"
              class="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text"
                name="last_name"
                required
                value={state.last_name}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label
              for="address"
              class="block text-sm font-medium text-gray-700"
            >
              Address
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text-area"
                name="address"
                required
                value={state.address}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label for="state" class="block text-sm font-medium text-gray-700">
              State
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text"
                name="state"
                required
                value={state.state}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label for="phone" class="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <div class="flex flex-col items-start">
              <input
                type="number"
                name="phone"
                required
                value={state.phone}
                onChange={handleChange}
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label
              for="start_date"
              class=" block text-sm font-medium text-gray-700"
            >
              Date to Start
            </label>
            <div class="flex flex-col items-start">
              <input
                type="date"
                name="start_date"
                required
                value={state.start_date}
                onChange={handleChange}
                class="block w-full h-12 py-2 px-3 mt-1 border  border-gray-300  rounded-md  focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700 undefined"
            >
              Confirm Password
            </label>
            <div className="flex flex-col items-start">
              <input
                type="password"
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={handleChange}
                required
                className="block h-12 w-full p-2 mt-1 border-gray-300 border rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <label class="block text-sm font-medium text-gray-700 mb-2">
          Are you legally eligible to work in this country?
        </label>
        <div class="flex items-center ">
          <input
            id="yes"
            type="radio"
            name="eligible"
            onClick={ifNotEligible}
            required
            value={state.eligible}
            onChange={handleChange}
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border  border-gray-300 "
          />
          <label for="yes" class="m-1 block text-sm text-gray-900">
            Yes
          </label>
        </div>
        <div class="flex items-center">
          <input
            id="no"
            type="radio"
            name="eligible"
            required
            value={state.eligible}
            onChange={handleChange}
            onClick={ifEligible}
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border  border-gray-300 "
          />
          <label for="no" class="m-1 block text-sm text-gray-900">
            No
          </label>
        </div>
        {eligible && (
          <div class="mt-2">
            <label
              for="explanation"
              class="block text-sm font-medium text-gray-700 mb-2"
            >
              If no, please explain:
            </label>
            <textarea
              id="explanation"
              name="explanation"
              rows="3"
              class=" focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border  border-gray-300  rounded-md"
            ></textarea>
          </div>
        )}
      </div>
      <h3 className="text-2xl font-bold text-purple-600">Education:</h3>
      <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
        <div>
          <div class="mt-4">
            <label
              for="institute"
              class="block text-sm font-medium text-gray-700"
            >
              High School Name
            </label>
            <div class="flex flex-col items-start">
              <input
                type="text"
                required
                value={state.qualifications.institute}
                onChange={handleChange}
                name="institute"
                class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div class="mt-4">
            <label class="block text-sm font-medium text-gray-700 mb-2">
              Graduated
            </label>
            <div class="flex items-center ">
              <input
                id="yes"
                type="radio"
                name="HighGraduated"
                value="yes"
                onClick={handleGraduatedChange}
                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border  border-gray-300 "
              />
              <label for="yes" class="m-1 block text-sm text-gray-900">
                Yes
              </label>
            </div>
            <div class="flex items-center">
              <input
                id="no"
                type="radio"
                name="HighGraduated"
                value="no"
                onClick={handleGraduatedRemove}
                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border  border-gray-300 "
              />
              <label for="no" class="m-1 block text-sm text-gray-900">
                No
              </label>
            </div>
          </div>
        </div>
        <div>
          {graduated && (
            <div>
              <div class="mt-4">
                <label
                  for="institute"
                  class="block text-sm font-medium text-gray-700"
                >
                  College/University Name
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    name="institute"
                    required
                    value={state.qualifications.institute}
                    onChange={handleChange}
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700 mb-2">
                  Graduated
                </label>
                <div class="flex items-center">
                  <input
                    id="yes"
                    type="radio"
                    name="UniversityGraduated"
                    value="yes"
                    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border  border-gray-300 "
                  />
                  <label for="yes" class="m-1 block text-sm text-gray-900">
                    Yes
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                    id="no"
                    type="radio"
                    name="UniversityGraduated"
                    value="no"
                    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border  border-gray-300 "
                  />
                  <label for="no" class="m-1 block text-sm text-gray-900">
                    No
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
        <div class="mt-4">
          <label for="title" class="block text-sm font-medium text-gray-700">
            Degree
          </label>
          <div class="flex flex-col items-start">
            <input
              type="text"
              name="title"
              required
              value={state?.qualifications?.title}
              onChange={handleChange}
              class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
        <div class="mt-4">
          <label for="subject" class="block text-sm font-medium text-gray-700">
            Major
          </label>
          <div class="flex flex-col items-start">
            <input
              type="text"
              required
              value={state.qualifications?.subject}
              onChange={handleChange}
              name="subject"
              class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const EmploymentHistory = ({
  state,
  handleAddMoreClick,
  handleChange,
  showButton,
  showDiv3,
  showDiv2,
}) => {
  return (
    <div>
      <h3 className="text-2xl font-bold text-purple-600">
        Employment History:
      </h3>
      <div className="container">
        <div className="div1">
          <h3 className="text-lg font-bold text-gray-700">
            Please list your three employers,starting with the most recent.
          </h3>
          <h3 className="text-sm font-bold text-purple-600">Company 1:</h3>

          <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
            <div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Company Name
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].company}
                    onChange={(event) => handleChange(event, 0)}
                    name="company"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].address}
                    onChange={(event) => handleChange(event, 0)}
                    name="address"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  State
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].state}
                    onChange={(event) => handleChange(event, 0)}
                    name="state"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Job Title
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].title}
                    onChange={(event) => handleChange(event, 0)}
                    name="title"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Employed Date
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="date"
                    required
                    value={state.experience[0].start_date}
                    onChange={(event) => handleChange(event, 0)}
                    name="start_date"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
            </div>
            <div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  {" "}
                  Phone No
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="number"
                    required
                    value={state.experience[0].phone}
                    onChange={(event) => handleChange(event, 0)}
                    name="phone"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  City
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].city}
                    onChange={(event) => handleChange(event, 0)}
                    name="city"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Zip Code
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].zip_code}
                    onChange={(event) => handleChange(event, 0)}
                    name="zip_code"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Supervisor
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].supervisor}
                    onChange={(event) => handleChange(event, 0)}
                    name="supervisor"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Leaving Reason
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.experience[0].leave_reason}
                    onChange={(event) => handleChange(event, 0)}
                    name="leave_reason"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDiv2 && (
          <div className="div2">
            <h3 className="text-sm font-bold text-purple-600">Company 2:</h3>
            <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Company Name
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].company}
                      onChange={(event) => handleChange(event, 1)}
                      name="company"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].address}
                      onChange={(event) => handleChange(event, 1)}
                      name="address"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].state}
                      onChange={(event) => handleChange(event, 1)}
                      name="state"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Job Title
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].title}
                      onChange={(event) => handleChange(event, 1)}
                      name="title"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Employed Date
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="date"
                      required
                      value={state.experience[1].start_date}
                      onChange={(event) => handleChange(event, 1)}
                      name="start_date"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    {" "}
                    Phone No
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="number"
                      required
                      value={state.experience[1].phone}
                      onChange={(event) => handleChange(event, 1)}
                      name="phone"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].city}
                      onChange={(event) => handleChange(event, 1)}
                      name="city"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Zip Code
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].zip_code}
                      onChange={(event) => handleChange(event, 1)}
                      name="zip_code"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Supervisor
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].supervisor}
                      onChange={(event) => handleChange(event, 1)}
                      name="supervisor"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Leaving Reason
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[1].leave_reason}
                      onChange={(event) => handleChange(event, 1)}
                      name="leave_reason"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDiv3 && (
          <div className="div3">
            <h3 className="text-sm font-bold text-purple-600">Company 3:</h3>
            <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Company Name
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].company}
                      onChange={(event) => handleChange(event, 2)}
                      name="company"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].address}
                      onChange={(event) => handleChange(event, 2)}
                      name="address"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].state}
                      onChange={(event) => handleChange(event, 2)}
                      name="state"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Job Title
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].title}
                      onChange={(event) => handleChange(event, 2)}
                      name="title"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Employed Date
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="date"
                      required
                      value={state.experience[2].start_date}
                      onChange={(event) => handleChange(event, 2)}
                      name="start_date"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    {" "}
                    Phone No
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="number"
                      required
                      value={state.experience[2].phone}
                      onChange={(event) => handleChange(event, 2)}
                      name="phone"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].city}
                      onChange={(event) => handleChange(event, 2)}
                      name="city"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Zip Code
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].zip_code}
                      onChange={(event) => handleChange(event, 2)}
                      name="zip_code"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Supervisor
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].supervisor}
                      onChange={(event) => handleChange(event, 2)}
                      name="supervisor"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Leaving Reason
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.experience[2].leave_reason}
                      onChange={(event) => handleChange(event, 2)}
                      name="leave_reason"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showButton && (
          <div className="mt-3">
            {" "}
            <button
              onClick={handleAddMoreClick}
              className="bg-purple-700 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-r"
            >
              Add More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const ProfessionalReferences = ({
  state,
  canvasRef,
  showRefButton,
  handleChange,
  handleAddMore,
  handleClear,
  showRefDiv3,
  showRefDiv2,
  handleChanged,
}) => {
  return (
    <div>
      <h3 className="text-2xl font-bold text-purple-600">References:</h3>
      <h3 className="text-lg font-bold text-gray-700">
        Please list three professional references.
      </h3>
      <div className="container">
        <div className="div1">
          <h3 className="text-sm font-bold text-purple-600">References 1:</h3>
          <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
            <div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.reference[0].name}
                    onChange={(event) => handleChange(event, 0)}
                    name="name"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Relationship
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="text"
                    required
                    value={state.reference[0].relationship}
                    onChange={(event) => handleChange(event, 0)}
                    name="relationship"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
            </div>

            <div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Phone No
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="number"
                    required
                    value={state.reference[0].phone}
                    onChange={(event) => handleChange(event, 0)}
                    name="phone"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label class="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div class="flex flex-col items-start">
                  <input
                    type="email"
                    required
                    value={state.reference[0].email}
                    onChange={(event) => handleChange(event, 0)}
                    name="email"
                    class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showRefDiv2 && (
          <div className="div2">
            <h3 className="text-sm font-bold text-purple-600">Reference 2:</h3>
            <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.reference[1].name}
                      onChange={(event) => handleChange(event, 1)}
                      name="name"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Relationship
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.reference[1].relationship}
                      onChange={(event) => handleChange(event, 1)}
                      name="relationship"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Phone No
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="number"
                      required
                      value={state.reference[1].phone}
                      onChange={(event) => handleChange(event, 1)}
                      name="phone"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="email"
                      required
                      value={state.reference[1].email}
                      onChange={(event) => handleChange(event, 1)}
                      name="email"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showRefDiv3 && (
          <div className="div3">
            <h3 className="text-sm font-bold text-purple-600">Reference 3:</h3>
            <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.reference[2].name}
                      onChange={(event) => handleChange(event, 2)}
                      name="name"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Relationship
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="text"
                      required
                      value={state.reference[2].relationship}
                      onChange={(event) => handleChange(event, 2)}
                      name="relationship"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Phone No
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="number"
                      required
                      value={state.reference[2].phone}
                      onChange={(event) => handleChange(event, 2)}
                      name="phone"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div class="flex flex-col items-start">
                    <input
                      type="email"
                      required
                      value={state.reference[2].email}
                      onChange={(event) => handleChange(event, 2)}
                      name="email"
                      class="block h-12 w-full p-2 mt-1 border  border-gray-300  rounded-md  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showRefButton && (
          <div className="mt-3">
            <button
              onClick={handleAddMore}
              className="bg-purple-700 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-r"
            >
              Add More
            </button>
          </div>
        )}
      </div>
      <div className="mt-4">
        <input
          type="checkbox"
          id="myCheckbox"
          name="myCheckbox"
          value="true"
          className="mr-2"
        />
        <label for="myCheckbox">
          <p>
            I certify that the information contained in this application is true
            and complete to the best of my knowledge. I understand that false
            statements or omissions may result in disqualification from
            employment or termination if already employed.
          </p>
        </label>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
        <div class="mt-4">
          <label
            for="signature"
            class="block text-sm font-medium text-gray-700"
          >
            Signature
          </label>
          <div>
            <SignatureCanvas
              ref={canvasRef}
              canvasProps={{
                width: "100%",
                height: 80,
                style: { border: "1px solid purple" },
              }}
            />
            <button
              onClick={handleClear}
              className="bg-red-600 mt-2 text-sm text-white px-4 py-1"
            >
              Clear
            </button>
          </div>
        </div>
        <div class="mt-4">
          <label
            for="created_at"
            class="block text-sm font-medium text-gray-700"
          >
            Date
          </label>
          <div class="flex flex-col items-start">
            <input
              type="date"
              name="created_at"
              value={state.created_at}
              onChange={handleChanged}
              class="block w-full h-12 py-2 px-3 mt-1 border  border-gray-300  rounded-md  focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
      </div>
      <a href="#" className="text-sm text-purple-600 hover:underline">
        Forget Password?
      </a>
      <div className="flex items-center mt-4">
        <button
          type="submit"
          className="w-full h-12 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
        >
          Register
        </button>
      </div>
    </div>
  );
};

const EmployeeRegistration = () => {
  const [hidden, setHidden] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    country: "",
    city: "",
    zip_code: "",
    email: "",
    last_name: "",
    address: "",
    state: "",
    phone: "",
    date_to_start: "",
    eligible: true,
    qualifications: {
      title: "",
      institute: "",
      subject: "",
    },
    experience: [
      {
        company: "",
        address: "",
        state: "",
        title: "",
        start_date: "",
        phone: "",
        city: "",
        zip_code: "",
        supervisor: "",
        leave_reason: "",
      },
      {
        company: "",
        address: "",
        state: "",
        title: "",
        start_date: "",
        phone: "",
        city: "",
        zip_code: "",
        supervisor: "",
        leave_reason: "",
      },
      {
        company: "",
        address: "",
        state: "",
        title: "",
        start_date: "",
        phone: "",
        city: "",
        zip_code: "",
        supervisor: "",
        leave_reason: "",
      },
    ],
    reference: [
      {
        name: "",
        relationship: "",
        phone: "",
        email: "",
      },
      {
        name: "",
        relationship: "",
        phone: "",
        email: "",
      },
      {
        name: "",
        relationship: "",
        phone: "",
        email: "",
      },
    ],
    certify: true,
    signature: "",
    created_at: "",
    device_type: "web",
    password: "",
    user_name: "",
  });
  console.log("state", state);

  const handleChangeEmployeeArray = (event, index) => {
    const { name, value } = event.target;
    setState((prevState) => {
      const experience = [...prevState.experience];
      experience[index][name] = value;
      return {
        ...prevState,
        experience,
      };
    });
  };
  const handleChangeReferenceArray = (event, index) => {
    const { name, value } = event.target;
    setState((prevState) => {
      const reference = [...prevState.reference];
      reference[index][name] = value;
      return {
        ...prevState,
        reference,
      };
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("name", name);
    console.log("value", name);
    setState((prevState) => ({
      ...prevState,
      qualifications: {
        ...prevState.qualifications,
        [name]: value,
      },
      [name]: value,
    }));
  };

  const handleInvalidSubmit = (event, errors, values) => {
    setEmail(values.email);
    setError(true);
  };

  const login = () => {
    history.push("/login");
  };

  const registeredEmployee = async (event) => {
    event.preventDefault();
    console.log("state", state);
    console.log("event", event.target);
    try {
      const response = await employSignup(state);
      console.log("response", response);
      if (response.status >= 400) {
        NotificationManager.error("Something went wrong");
      } else {
        NotificationManager.success("Signup Succesfully");
        await auth
          .createUserWithEmailAndPassword(state.email)
          .then(async (result) => {
            await firestore.collection("user ns").add({
              employ_id: response.data.id,
              first_name: state.first_name,
              last_name: state.last_name,
              id: result.user.uid,
              email: state.email,
              img_url: "",
              user_type: "employ",
              isonline: false,
              blocklist: [],
              isverify: false,
            });
          })
          .catch((e) => {
            alert(e);
          });
        await auth.signOut();
        setHidden(false);
        history.push("/login");
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Something went wrong");
    }
  };

  const [showDiv2, setShowDiv2] = useState(false);
  const [showDiv3, setShowDiv3] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showRefDiv2, setShowRefDiv2] = useState(false);
  const [showRefDiv3, setShowRefDiv3] = useState(false);
  const [showRefButton, setShowRefButton] = useState(true);

  const handleAddMore = () => {
    if (!showRefDiv2) {
      setShowRefDiv2(true);
    } else if (!showDiv3) {
      setShowRefDiv3(true);
      setShowRefButton(false);
    }
  };
  const [activeComponent, setActiveComponent] = useState(1);
  const handleNext = () => {
    if (activeComponent === 1) {
      setActiveComponent(2);
    } else if (activeComponent === 2) {
      setActiveComponent(3);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleAddMoreClick = () => {
    if (!showDiv2) {
      setShowDiv2(true);
    } else if (!showDiv3) {
      setShowDiv3(true);
      setShowButton(false);
    }
  };

  const handleBack = () => {
    if (activeComponent === 3) {
      setActiveComponent(2);
    } else if (activeComponent === 2) {
      setActiveComponent(1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [graduated, setGraduated] = useState(false);
  const [eligible, setEligible] = useState(false);
  const canvasRef = React.useRef(null);

  const handleClear = () => {
    canvasRef.current.clear();
  };

  const ifEligible = () => {
    setEligible(true);
  };
  const ifNotEligible = () => {
    setEligible(false);
  };
  const handleGraduatedChange = () => {
    setGraduated(true);
  };
  const handleGraduatedRemove = () => {
    setGraduated(false);
  };

  const stepClasses = "text-gray-500 font-medium";

  return (
    <>
      <div className="bg-white">
        <Navbar />
      </div>
      <div>
        <div className="flex flex-col items-center min-h-screen pt-6 pb-6 sm:justify-center sm:pt-0 bg-gray-50 ">
          <div className="mt-24">
            <a href="/">
              <h3 className="text-4xl font-bold text-purple-600">
                Employee Sign Up
              </h3>
            </a>
          </div>
          <div className="flex items-center mb-4 justify-between w-full px-6 py-4 mt-2 overflow-hidden bg-white   sm:max-w-4xl sm:rounded-lg">
            <div
              className={`${stepClasses} ${
                activeComponent >= 1 ? "text-purple-700" : ""
              }`}
            >
              Personal Information
            </div>
            <div className="flex-1 h-px bg-gray-300"></div>
            <div
              className={`${stepClasses} ${
                activeComponent >= 2 ? "text-purple-700" : ""
              }`}
            >
              Employment History
            </div>
            <div className="flex-1 h-px bg-gray-300"></div>
            <div
              className={`${stepClasses} ${
                activeComponent >= 3 ? "text-purple-700" : ""
              }`}
            >
              Professional References
            </div>
          </div>

          <div className="w-full px-6 py-4 mt-2 overflow-hidden bg-white   sm:max-w-4xl sm:rounded-lg">
            <form
              onSubmit={registeredEmployee}
              // onInvalidSubmit={handleInvalidSubmit}
            >
              {activeComponent === 1 && (
                <PersonalInformation
                  state={state}
                  handleChange={handleChange}
                  graduated={graduated}
                  eligible={eligible}
                  ifEligible={ifEligible}
                  ifNotEligible={ifNotEligible}
                  handleGraduatedChange={handleGraduatedChange}
                  handleGraduatedRemove={handleGraduatedRemove}
                />
              )}
              {activeComponent === 2 && (
                <EmploymentHistory
                  state={state}
                  handleAddMoreClick={handleAddMoreClick}
                  handleChange={handleChangeEmployeeArray}
                  showButton={showButton}
                  showDiv2={showDiv2}
                  showDiv3={showDiv3}
                />
              )}
              {activeComponent === 3 && (
                <ProfessionalReferences
                  state={state}
                  canvasRef={canvasRef}
                  showRefButton={showRefButton}
                  handleChange={handleChangeReferenceArray}
                  handleAddMore={handleAddMore}
                  handleClear={handleClear}
                  showRefDiv3={showRefDiv3}
                  showRefDiv2={showRefDiv2}
                  handleChanged={handleChange}
                />
              )}

              <div className="text-right mt-4">
                {activeComponent !== 1 && (
                  <button
                    onClick={handleBack}
                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-l"
                  >
                    Back
                  </button>
                )}
                {activeComponent !== 3 && (
                  <button
                    onClick={handleNext}
                    className="bg-purple-700 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-r"
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
            <div className="mt-4 text-grey-600">
              Already have an account?
              <span>
                <Link
                  to="/employee-login"
                  className="text-purple-600 hover:underline"
                >
                  <b> Log In</b>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeRegistration;
