import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Table, Button , Row, Col } from 'reactstrap';
import './chat.css'
import Tooltip from '@material-ui/core/Tooltip';
import { auth,firestore } from "../../firebase/firebase";
import profile from "../../assets/img/profile.png"
import ChatRoom from "./ChatRoom/ChatRoom.js"

class Chat extends React.Component {

  constructor(props) {
    super(props);
    this.messages = this.messages.bind(this);
    this.messageslist = this.messageslist.bind(this);
    this.state = {
      user_msg_list: 0,
      user_msgs: 1,
      user_email: "",
      hidden: false,
      chats: [],
      searchValue: "",
      index : "",
      currentChat : ""
    };
  }

  componentDidMount = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const email = userInfo.data.email
    const user_type = userInfo.data.user_type
    await this.setState({user_email: email,user_type: user_type})
    await this.getAllUsersData();
    await this.getInfo();
  };

  async messages(index) {
    const currentChat = this.state.chats[index]
    await this.setState({user_msgs:0,user_msg_list:1, index:index, currentChat: currentChat, searchValue:''})
  };

  messageslist() {
    this.getInfo();
    this.setState({ user_msgs:1,user_msg_list:0 });
  };

  getAllUsersData = async () => {
    await firestore.collection("users").onSnapshot(async (snapshot) => {
      var dt = snapshot.docs.map((docs) => docs.data());
      var user_data = []
      var temp_type = ""
      if(this.state.user_type=="customer"){
        temp_type = "company"
      }else if(this.state.user_type=="company"){
        temp_type = "customer"
      }

      if(dt.length != 0){
        const dt_len = dt.length
        for(var i=0;i<dt_len;i++){
          if(dt[i].user_type == temp_type){
            user_data.push(dt[i])
          }
        }
      }
      await this.setState({ allUserData: user_data });
    });
  };

  getInfo = async (emails) => {
    await firestore
      .collection("chats")
      .where("users", "array-contains", this.state.user_email)
      .onSnapshot(async (res) => {
        var chats = res.docs.map((docs) => docs.data());
        chats.sort((a, b) => {
          if (a.time < b.time) {
            return 1;
          } else if (a.time > b.time) {
            return -1;
          } else {
            return 0;
          }
        });

        var chatList = [];

        if (emails) {
          chatList = await chats.filter((chats) => {
            var getEmail =
              chats.users[0] !== this.state.user_email ? chats.users[0] : chats.users[1];

            if (emails.includes(getEmail)) {
              return chats;
            }
          });
        }
        if (emails) {
          chats = chatList;
        }
        var index = this.state.index;
        if (this.state.user_msg_list==1){
          index = await chats.findIndex(obj => obj.docid === this.state.currentChat.docid);
        };
        await this.setState({chats: chats , index: index});
      });
  }

  searchChat = async (search) => {
    const { user_email } = this.state;
    const emails = await this.fetchSearchEmail(search, user_email);
    await this.getInfo(emails);
  };

  fetchSearchEmail = async (search, email) => {
    const emails = [];
    const { allUserData } = this.state;

    await allUserData.map((list) => {
      var name = ""
      if(this.state.user_type=="customer"){
        name = list.name
      }else if(this.state.user_type=="company"){
        name = list.firstname+" "+list.last_name
      }
      if (name.toLowerCase().search(search) !== -1 && list.email !== email) {
        emails.push(list.email);
      }
    });

    return emails;
  };

  renderChats(chats){
    return chats && this.state.chats.map((chat, index) => {
      {
      return <div>
        <div class="chat_list" >
          <Row>
            <div class="chat_people m-2" 
                onClick={this.messages.bind(this,index)}
                key={index}
                >
              <div class="chat_img" style={{ "min-width" : "50px" }}>
                <img className="rounded-circle" src={this.state.allUserData
                  .map((list) => {
                    if (
                      list.email === (chat.users[0] !== this.state.user_email ? chat.users[0] : chat.users[1])
                    ) {
                      return list.img_url;
                    } else {
                      return "";
                    }
                  }).join("").trim("") || profile}
                  style={{ borderRadius: 60, "max-height": "50px", "min-height" : "50px"
                                  ,"max-width": "50px", "min-width" : "50px" }}
                   /> 
              </div>
              <div class="chat_ib" style={{ "min-width" : "100px" }}>
                <h5 style={{"font-weight":"bold"}}>{this.state.allUserData.map((list) => {
                  if (
                    list.email === (chat.users[0] !== this.state.user_email ? chat.users[0] : chat.users[1])
                    ) {
                      if(list.user_type=="company"){
                        return list.name;
                      }else if(list.user_type=="customer"){
                        return list.first_name+" "+list.last_name;
                      }
                    }})
                  } 
                    {/* <span class="chat_date" style={{"font-weight":"normal"}}>
                      {chat.messages.length > 0 ? chat.messages[chat.messages.length - 1] .time.slice(0,8) : ""}
                    </span> */}
                </h5>
                <p >{chat.messages[chat.messages.length - 1].type === "text" ? (
                                  <span className="mr-1">
                                    {chat.messages.length > 0
                                      ? chat.messages[
                                          chat.messages.length - 1
                                        ].message.substring(0, 10)
                                      : ""}
                                  </span>
                                ) : (
                                  <span className="mr-1">Image</span>
                                )}
                </p>
              </div>
              <div className="pull-right" style={{ "font-size": "12px" }}>
                <h5 >
                  <span class="chat_date">
                      {chat.messages.length > 0 ? chat.messages[chat.messages.length - 1] .time.slice(0,8) : ""}
                  </span>
                </h5>
              </div>
            </div>
          </Row>
        </div>
      </div>
    }});
  };

  render() {
    return (
      <Card style={{"min-width":"290px"}}> 
        <CardHeader color="primary">
          <Row className="m-2">
            <h4>Chats</h4>
          </Row>
        </CardHeader>
        <CardBody>
          <div class="container">
            <div class="messaging">
              <div class="inbox_msg">
                {this.state.user_msg_list==0 && 
                <div class="inbox_people">
                  <div class="headind_srch">
                    <Row>
                      <Col >
                        <div class="srch_bar" style={{"min-width":"137px"}}>
                          <div class="stylish-input-group">
                            <input type="text" 
                              class="search-bar" 
                              placeholder="Search Current Chats"
                              onChange={async (e) => {
                                await this.setState({ searchValue: e.target.value });
                                await this.searchChat(this.state.searchValue)}}/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div class="inbox_chat">
                  {this.state.chats.length > 0 ? this.renderChats(this.state.chats) : (
                    <div className="text-center">
                      <h3>Sorry No Chats.</h3>
                    </div>
                  )}
                  </div>
                </div>}
                {this .state.user_msgs==0 &&
                  <div class="mesgs">
                    <Row style={{ "max-height": "45px", "min-height" : "45px", "min-width" : "300px"}}>
                      <Col xs={2} style={{ "min-width" : "50px" , "max-width" : "50px" }}>
                        <div>
                          <Tooltip title="Back">
                            <Button className="fa fa-arrow-circle-o-left pull-left" 
                              color="primary" 
                              style={{ textAlign: 'center', borderRadius: 60 }} onClick={this.messageslist}></Button>
                          </Tooltip>
                        </div>
                      </Col>
                      <Col xs={10}>
                        <Row style={{ "min-width" : "300px" }}>
                          <Col xs={2} style={{ "max-width": "50px"}}>
                            <div class="chat_img" style={{ "min-width" : "50px" }}>
                              <img src={this.state.allUserData
                                .map((list) => {
                                  if (
                                    list.email === (this.state.currentChat.users[0] !== this.state.user_email ? this.state.currentChat.users[0] : this.state.currentChat.users[1])
                                  ) {
                                    return list.img_url;
                                  } else {
                                    return "";
                                  }
                                }).join("").trim("") || profile}
                                style={{ borderRadius: 60, "max-height": "50px", "min-height" : "50px"
                                  ,"max-width": "50px", "min-width" : "50px" }}
                                /> 
                            </div>
                          </Col>
                          <Col xs={10}>
                            <div class="chat_ib" style={{ "min-width" : "150px" }}>
                              <h5 style={{"font-weight":"bold"}}>{this.state.allUserData.map((list) => {
                                if (
                                  list.email === (this.state.currentChat.users[0] !== this.state.user_email ? this.state.currentChat.users[0] : this.state.currentChat.users[1])
                                  ) {
                                    if(list.user_type=="company"){
                                      return list.name;
                                    }else if(list.user_type=="customer"){
                                      return list.first_name+" "+list.last_name;
                                    }
                                  }})
                                }
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr style={{ width:"100%", "text-align":"left" }}></hr>
                    <Row>
                      <ChatRoom 
                        chatData={this.state.chats[this.state.index]}
                        allUserData={this.state.allUserData}
                        />
                    </Row>
                    </div>
                 }
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

}
export default Chat;