import React from 'react'
import {Button, Row, Col, Form } from 'react-bootstrap';

import Switch from '@material-ui/core/Switch';

class EmployeesForm extends React.Component {

    constructor() {
        super()
        this.state = {
            checkedA: true,
            checkedB: true,
            checkedC: true,
            checkedD: true,
        }
    }
    handleChange = (event) => {
        this.setState({ checkedA: !this.state.checkedA, checkedB: !this.state.checkedB })
    };
    handleChange2 = (event) => {
        this.setState({ checkedC: !this.state.checkedC, checkedD: !this.state.checkedD })
    };
    render() {
        return (

            <div>
                <Row className='m-0'>
                <Col lg='2' md='6' sm='12' xs='12' >

<Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
    <Form.Control type="text" placeholder="Gender" className='input-bg' />
</Form.Group>

</Col>
                    <Col lg='6' md='6' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Name" className='input-bg' />
                        </Form.Group>

                    </Col>
                </Row>
                <Row className='m-0'>
                    <Col lg='4' md='4' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Qualification" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='4' md='4' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Qualification" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='4' md='4' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Qualification" className='input-bg' />
                        </Form.Group>
                    </Col>

                </Row>
                <Row className='m-0'>
                    <Col lg='4' md='4' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Experience" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='4' md='4' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Experience" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='4' md='4' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Experience" className='input-bg' />
                        </Form.Group>
                    </Col>

                </Row>
                <Row className='m-0'>
                    <Col lg='4' md='4' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Available Hours" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='4' md='4' sm='12' xs='12' >

                    <span>Available for jobs</span>
                        <Switch
                            checked={this.state.checkedB}
                            onChange={this.handleChange}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Col>
                </Row>

                <Row className='m-0'>
                    <Col lg='3' md='6' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Country" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='3' md='6' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="City" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='3' md='6' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="State" className='input-bg' />
                        </Form.Group>
                    </Col>
                    <Col lg='3' md='6' sm='12' xs='12' >

                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <Form.Control type="text" placeholder="Location" className='input-bg' />
                        </Form.Group>
                    </Col>

                </Row>
                <Row className='m-0'>
                    
                    <Col lg='3' md='4' sm='12' xs='12' >
                        <span>Licence</span>
                        <Switch
                            checked={this.state.checkedC}
                            onChange={this.handleChange2}
                            color="primary"
                            name="checkedC"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Col>
                    <Col lg='3' md='4' sm='12' xs='12' >
                        <Form.Group controlId="exampleForm.ControlSelect1" className='d-flex'>
                            <div className='mr-5'>
                                <Form.Control as="select" className='form-control-3'>
                                    <option>Vehicles</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </Form.Control>
                            </div>

                        </Form.Group>
                    </Col>

                </Row>
                <Row className='m-0 justify-content-end'>

                <Button color="primary ">Submit</Button>
                </Row>

            </div>
        )
    }
}

export default EmployeesForm
